/* eslint-disable @typescript-eslint/no-misused-promises */
import { Dispatch, MiddlewareAPI } from 'redux';
import { RootState } from 'redux/store';
import { CallHistoryMethodAction } from 'connected-react-router';
import { History } from 'history';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { ThunkMiddleware } from 'redux-thunk';
import { ServiceBusActionType, ServiceBusActions } from './actions';
import { ServiceBusClient, ServiceBusSessionReceiver } from '@azure/service-bus';
import { initiliseBusClient } from './thunk';

type ServiceBusAPI = MiddlewareAPI<Dispatch<Action<ServiceBusActionType> | CallHistoryMethodAction<[string, History]>> & ThunkDispatch<RootState, undefined, ServiceBusActions>, RootState>;
type CoreType = {
  client?: ServiceBusClient;
  sessions: ServiceBusSessionReceiver[];
};
type CoreKeys = keyof CoreType;

export const serviceBusMiddleware = (): ThunkMiddleware<RootState, Action<ServiceBusActionType> | CallHistoryMethodAction<[string, History?]>, undefined> => {
  const core: CoreType = {
    sessions: [],
  };

  const setCore =
    <T extends CoreKeys>(key: T) =>
    (value: CoreType[T]) =>
      (core[key] = value);

  const addSession = (session: ServiceBusSessionReceiver) => {
    core.sessions.push(session);
  };

  const startServiceBus = (api: ServiceBusAPI) =>
    api
      .dispatch(initiliseBusClient())
      .unwrap()
      .then(setCore('client'))
      .catch((error: Error) => console.log(error));

  return (api: ServiceBusAPI) => {
    return next => (action: ServiceBusActions) => {
      switch (action.type) {
        case 'SB_START':
          startServiceBus(api).catch((error: Error) => console.log(error));
          break;
        case 'SB_START_SESSION':
          if (!core.client) return;
          console.log(core);
          core.client
            .acceptSession(...action.payload)
            .then(session => {
              addSession(session);
            })
            .catch((error: Error) => console.log(error));
          return;

        default:
          return next(action);
      }
    };
  };
};

export default serviceBusMiddleware();
