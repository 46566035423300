import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Item, PaperItem } from 'components/styled/Grid';
import { theme } from 'style/theme';
import { InputLabel, Typography, FormControl, Select, MenuItem, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useMySQL } from 'hooks/useMySQL';
import { FTRTable } from './FTRTable';
import { useLoadingButton } from 'hooks/useLoadingButton';
import { StyledContainer } from 'components/styled';

const useStyles = makeStyles({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 'max-content',
    textAlign: 'left',
    alignSelf: 'center',
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    width: '-webkit-fill-available',
    textAlign: 'center',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '-webkit-fill-available',
    fontWeight: 'bolder',
  },
  content: {
    padding: theme.spacing(2),
    minBlockSize: 'max-content',
    overflowY: 'auto',
  },
  formControlLabel: {
    minWidth: 'max-content',
  },
  radioControl: {
    flexGrow: 0,
  },
});

export function Calculator(): JSX.Element {
  const classes = useStyles();

  const [header, data, setQuery] = useMySQL('bold-hook');
  const [trigger, triggerUpdate] = useState(false);
  const [source, setSource] = useState('');
  const [aggregationPeriod, setAggregationPeriod] = useState('');
  const [calculationPeriod, setCalculationPeriod] = useState('');
  const [isObligation, setIsObligation] = useState(false);

  const setState = {
    source: setSource,
    aggregationPeriod: setAggregationPeriod,
    calculationPeriod: setCalculationPeriod,
  };

  const sourceOptions = ['INV0331', 'BEN2201', 'ISL0331', 'KIK0111', 'HAY0331', 'WKM2201', 'OTA2201', 'RDF0331'];
  const calculationPeriodOptions = ['current month', '1 week', '1 month', '3 months', '6 months', '1 year', '5 year', '10 year'];

  const aggregationPeriodOptions = ['TP', 'Day', 'Month'];

  useEffect(() => {
    console.log({ source, sourceOptions, aggregationPeriod, calculationPeriod, isObligation });
    setQuery({ source, sourceOptions, aggregationPeriod, calculationPeriod, isObligation });
  }, [setQuery, trigger]);
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    event.target.id === 'obl' && checked && setIsObligation(true);
    event.target.id === 'opt' && checked && setIsObligation(false);
  };

  const handleChange =
    (type: 'source' | 'aggregationPeriod' | 'calculationPeriod') =>
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ): void => {
      setState[type](event.target.value as string);
    };

  const fetchData = (): void => triggerUpdate(state => !state);
  const [LoadingButton, setLoading] = useLoadingButton();

  useEffect(() => setLoading(false), [data, setLoading]);

  return (
    <StyledContainer columns={'repeat(4, 1fr)'} rows={'2.5rem auto min-content'}>
      <Item colSpan={4} rowSpan={1}>
        <Typography variant={'h6'} color={'primary'} className={classes.title}>
          FTR Calculator
        </Typography>
      </Item>
      <Item flex colSpan={4} rowSpan={1} className={classes.content}>
        {header && data && <FTRTable title={`FTRs - Reference node ${source}`} headerRow={header} contentRows={data} highlightColumn={source} highlightRow={data.length} style={{ flexGrow: 1 }} />}
      </Item>
      <PaperItem flex colSpan={4} style={{ flexWrap: 'wrap' }}>
        <RadioGroup row className={classes.formControl} style={{ flexWrap: 'nowrap', flexGrow: 0 }} aria-label='contract' name='contract' defaultValue='OPT'>
          <FormControl variant='outlined' className={classes.radioControl}>
            <FormControlLabel value='opt' control={<Radio id={'opt'} color='secondary' checked={!isObligation} onChange={handleRadioChange} />} label='OPT' labelPlacement='top' />
          </FormControl>
          <FormControl variant='outlined' className={classes.radioControl}>
            <FormControlLabel value='obl' control={<Radio id={'obl'} color='secondary' checked={isObligation} onChange={handleRadioChange} />} label='OBL' labelPlacement='top' />
          </FormControl>
        </RadioGroup>
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='source-select-label'>Source</InputLabel>
          <Select labelId='source-select-label' id='company-select' value={source} onChange={handleChange('source')} label={'Source'}>
            {sourceOptions.map((source: string) => (
              <MenuItem key={source} value={source}>
                {source}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='aggregation-select-label' className={classes.formControlLabel}>
            Aggregation Period
          </InputLabel>
          <Select labelId='aggregation-select-label' id='aggregation-select' value={aggregationPeriod} onChange={handleChange('aggregationPeriod')} label={'Aggregation Period'}>
            {aggregationPeriodOptions.map((aggregation: string) => (
              <MenuItem key={aggregation} value={aggregation}>
                {aggregation}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='period-select-label'>Calculation Period</InputLabel>
          <Select labelId='period-select-label' id='period-select' value={calculationPeriod} onChange={handleChange('calculationPeriod')} label={'Calculation Period'}>
            {calculationPeriodOptions.sort().map((period: string) => (
              <MenuItem key={period} value={period}>
                {period}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LoadingButton clickAction={fetchData} label={'CALCULATE'} disabled={[source, aggregationPeriod, calculationPeriod].includes('')} />
      </PaperItem>
    </StyledContainer>
  );
}
