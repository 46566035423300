import React from 'react';

import { DivItem } from 'components/styled/Grid';
import { Button, makeStyles } from '@material-ui/core';
import { UseFormGetValues } from 'react-hook-form';
import { TicketInterfaces } from 'types/Ticket.types';
import moment from 'moment';
import { useAppDispatch } from 'redux/hooks';
import { RPC, StatusCode } from 'types/pantheon/PantheonSocket';
import { goBack } from 'connected-react-router';
import { appActions } from 'redux/slice/app/app';
import { wsAsyncActions } from 'redux/middleware/socket';
import { isPantheonResponse } from 'lib/guards';

const useStyles = makeStyles({
  buttonRoot: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'space-evenly',
  },
  button: {
    display: 'flex',
  },
});

function TicketFooter<TType extends TicketInterfaces>({ getValues }: { getValues: UseFormGetValues<TType> }): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  // send data
  const submitTicket = ({ withClose = false, deleteTicket = false }: { withClose?: boolean; deleteTicket?: boolean }) => {
    const parameters = getValues();

    parameters.TradeTime = moment(parameters.TradeTime).format('YYYY-MM-DD HH:mm:ss');
    if ('isOption' in parameters) delete parameters.isOption;
    dispatch<RPC.Response>(
      wsAsyncActions.WS_RPC({
        procedure: 'submitTicket',
        ID: parameters.ID,
        method: deleteTicket ? 'delete' : parameters.ID > 0 ? 'patch' : 'post',
        parameters,
      }),
    )
      .then(result => {
        if (withClose && isPantheonResponse(result) && result.code === StatusCode.OK) {
          dispatch(goBack());
        }
      })
      .catch((e: Error) => void dispatch(appActions.ERROR(e.message)));
  };

  // button click handler
  const handleClick = (button: 'save' | 'clear' | 'delete' | 'show' | 'saveAndClose') => {
    switch (button) {
      case 'save':
        return submitTicket({});
      case 'show':
        return console.log(getValues());
      case 'saveAndClose':
        return submitTicket({ withClose: true });
      case 'delete':
        return dispatch(appActions.CONFIRM({ message: `Confirm delete of Ticket #${getValues().ID}`, onConfirm: () => submitTicket({ withClose: true, deleteTicket: true }) }));
      default:
        return;
    }
  };

  return (
    <DivItem colSpan={5} rowSpan={1} className={classes.buttonRoot}>
      <Button className={classes.button} variant={'contained'} color={'primary'} onClick={() => handleClick('save')}>
        Save ticket
      </Button>
      <Button className={classes.button} variant={'contained'} color={'primary'} onClick={() => handleClick('saveAndClose')}>
        Save and close ticket
      </Button>
      <Button className={classes.button} variant={'contained'} color={'secondary'} onClick={() => handleClick('delete')}>
        Delete ticket
      </Button>
      <Button className={classes.button} variant={'contained'} color={'secondary'} onClick={() => handleClick('show')}>
        Debug Ticket
      </Button>
    </DivItem>
  );
}

export { TicketFooter };
