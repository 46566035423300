import { MenuItem } from '@material-ui/core';
import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { RootState } from 'redux/store';
import { PortalKey, PortalSchema, PortalStore, PortalTableFilter } from 'types/sql/ae.portal.types';

// base selectors
const portalDataByField = (state: RootState, field: keyof PortalStore) => state.metadata.ticketMeta[field];
const portalDataFiltered = (state: RootState, field: keyof PortalStore, filter?: PortalTableFilter<PortalKey>) => filter;

type NamedProps = keyof PortalSchema[PortalKey] extends `${PortalKey}Name` | `${PortalKey}ID` ? PortalSchema[PortalKey] : never;

const makeSelectItem = (key: string, item: NamedProps, selected?: boolean) => (
  <MenuItem key={`${key}-${item[`${key}ID` as keyof NamedProps]}`} value={item[`${key}ID` as keyof NamedProps]} selected={selected}>
    {item[`${key}Name` as keyof NamedProps]}
  </MenuItem>
);

const hasNameAndID = <K extends PortalKey>(key: K, obj: unknown): obj is NamedProps => typeof obj === 'object' && obj !== null && `${key}Name` in obj && `${key}ID` in obj;

const itemsToElements = <K extends PortalKey>(key: K, items: PortalStore[K]): JSX.Element[] => {
  const validItems = items.map(item => (hasNameAndID<K>(key, item) ? item : null)).filter(item => item !== null) as NamedProps[];

  if (validItems.length === 1) {
    return [makeSelectItem(key, validItems[0], true)];
  }

  return [
    <MenuItem key={`${key}-0`} value={0}>
      <em>{`Choose ${key}`}</em>
    </MenuItem>,
    ...validItems.map(item => makeSelectItem(key, item)),
  ] as JSX.Element[];
};

// gets a particular table (field) items from the portal schema. can be filtered using filter object
export const getPortalFieldItems = <K extends PortalKey>(state: RootState, key: K, filter?: PortalTableFilter<K>): JSX.Element[] =>
  createSelector([portalDataByField, portalDataFiltered], (data, filter) => {
    if (!filter) return itemsToElements<K>(key, data as PortalStore[K]);
    const filterEntries = Object.entries(filter);
    return itemsToElements<K>(
      key,
      (data as PortalStore[K])
        .map(item => {
          const itemEntries = Object.entries(item);
          for (const [key, value] of filterEntries) {
            if (
              itemEntries.find(([k, v]) => {
                if (k !== key || value === null) return false;
                return Array.isArray(v) ? v.some(subItem => subItem === value) : v === value;
              })
            ) {
              return item;
            }
          }
          return undefined;
        })
        .filter(item => item !== undefined) as PortalStore[K],
    );
  })(state, key, filter);
