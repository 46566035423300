import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Divider, Grid, Theme } from '@material-ui/core';
import GavelIcon from '@material-ui/icons/Gavel';
import { UberIconButton } from 'components/UberIconButton';
import { GridRowData, GridRowId, useGridSlotComponentProps } from '@material-ui/x-grid';
import { useAppDispatch, useSocketDispatch } from 'redux/hooks';
import { actionWithSelectionHOC } from '../common';
import { useSocketEmit } from 'modules/websocket/hooks';
import { appActions } from 'redux/slice';
import { wsAsyncActions } from 'redux/middleware/socket';
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbarRoot: {
      width: '-webkit-fill-available',
      borderWidth: `0px 0px 1px 0px`,
      borderStyle: `solid`,
      borderColor: theme.palette.divider,
      borderRadius: `0px`,
    },
  }),
);

const ConfirmToolbar = (admin: boolean, closeModal: () => void, setGenerated: (data: number[]) => void) => (): JSX.Element => {
  const classes = useStyles();

  const { apiRef } = useGridSlotComponentProps();
  const dispatch = useAppDispatch();
  const actionWithSelection = actionWithSelectionHOC(apiRef, dispatch);
  const [emit] = useSocketEmit('pantheon.api');
  const socketDispatch = useSocketDispatch();

  const generate = () => {
    const rows = Array.from(apiRef.current.getSelectedRows());
    // make array of ticket ids that we're confirming
    setGenerated(rows.reduce<number[]>((prev: number[], curr: [GridRowId, GridRowData]) => [...prev, curr[0] as number], []));
  };

  const onConfirm = () => {
    const rows = apiRef.current.getSelectedRows();
    emit(
      {
        type: 'API_REQUEST',
        method: 'patch',
        schema: 'ae',
        table: 'transaction',
        id: Array.from(rows)
          .map<number[]>(trade => [trade[1]['hide_Sell ID'], trade[1]['hide_Buy ID']] as number[])
          .flat(1),
        data: { isConfirmed: true },
      },
      () => closeModal(),
    );
  };

  const amendFlag = (setting: boolean) => () => {
    const rows = apiRef.current.getSelectedRows();
    socketDispatch(
      wsAsyncActions.WS_API({
        type: 'API_REQUEST',
        method: 'patch',
        schema: 'ae',
        table: 'transaction',
        id: Array.from(rows)
          .map<number[]>(trade => [trade[1]['hide_Sell ID'], trade[1]['hide_Buy ID']] as number[])
          .flat(1),
        data: { isConfirmed: setting },
      }),
    )
      .then(() => closeModal())
      .catch(e => console.error(e));
  };
  return (
    <Grid container alignItems='center' className={classes.toolbarRoot}>
      <UberIconButton onClick={actionWithSelection(generate)} tooltip={'Send confirmation?'} Icon={GavelIcon} />
      <Divider orientation='vertical' flexItem />
      {admin && (
        <>
          <UberIconButton onClick={actionWithSelection(() => dispatch(appActions.CONFIRM({ message: 'Force confirmation re-send?', onConfirm })))} tooltip={'Force resend of confirmation'} Icon={GavelIcon} />
          <UberIconButton
            baseColour={green}
            onClick={actionWithSelection(() => dispatch(appActions.CONFIRM({ message: 'Mark selected trades as confirmed?', onConfirm: amendFlag(true) })))}
            tooltip={'Force marked confirmation as sent'}
            Icon={GavelIcon}
          />
          <UberIconButton
            baseColour={red}
            onClick={actionWithSelection(() => dispatch(appActions.CONFIRM({ message: 'Mark selected trades as unconfirmed?', onConfirm: amendFlag(false) })))}
            tooltip={'Force marked confirmation as unsent'}
            Icon={GavelIcon}
          />
        </>
      )}
    </Grid>
  );
};

export { ConfirmToolbar };
