import React from 'react';

import { PaperItem } from 'components/styled/Grid';
import { Typography, Divider, InputLabel, Select, CircularProgress, FormControlLabel, Switch, TextField } from '@material-ui/core';
import { IElectricityOTCTicket } from 'types/Ticket.types';
import { Control, Controller, useWatch } from 'react-hook-form';
import { TicketFormControl, useTicketStyles } from '../styles';
import { useAppSelector } from 'redux/hooks';
import { getPortalFieldItems } from 'pages/ae/broking/ticket/selectElement';
import { DateBlockInput } from '../components/DateBlockInput';
import { ProductInput } from '../components/ProductInput';
import { TicketRow } from '../components/TicketRow';
import { VolumeInput } from '../components/VolumeInput';
import clsx from 'clsx';

function ElectricityOTCTicketDetails({ control }: { control: Control<IElectricityOTCTicket> }): JSX.Element {
  const classes = useTicketStyles();
  const isOption = useWatch({
    control,
    name: 'isOption',
  });
  const instruments = useAppSelector(state => getPortalFieldItems<'reference_instrument'>(state, 'reference_instrument', { productID: 4 }));
  const schedules = useAppSelector(state => getPortalFieldItems<'electricity_schedule'>(state, 'electricity_schedule'));
  const option_type = useAppSelector(state => getPortalFieldItems<'option_type'>(state, 'option_type'));
  const option_exercise_type = useAppSelector(state => getPortalFieldItems<'option_exercise_type'>(state, 'option_exercise_type'));
  const { loaded } = useAppSelector(state => state.metadata.status);

  return (
    <PaperItem flex colSpan={5} rowSpan={1} className={classes.paper}>
      <div className={classes.headerRoot}>
        <Typography className={classes.header} variant={'h6'}>
          Ticket Details
        </Typography>
      </div>
      <Divider className={classes.divider} />

      {!loaded ? (
        <CircularProgress className={classes.formRoot} />
      ) : (
        <div className={classes.formRoot}>
          <ProductInput productID={4} controllerClassName={classes.formControl} lockProduct lockVenue />
          <TicketRow>
            <DateBlockInput<IElectricityOTCTicket> control={control} hours />
            <Controller
              name='reference_instrumentID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='reference_instrument-label'>Reference Instrument</InputLabel>
                  <Select labelId='reference_instrument-label' id='reference_instrument' label='Reference Instrument' {...field}>
                    {instruments}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller
              name='electricity_scheduleID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='electricity_schedule-label'>Electricity Schedule</InputLabel>
                  <Select labelId='electricity_schedule-label' id='electricity_schedule' label='Electricity Schedule' {...field}>
                    {schedules}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller name='isOption' control={control} render={({ field }) => <FormControlLabel className={classes.formControlLabel} control={<Switch checked={field.value} color='primary' {...field} />} label='Futures Option' />} />
          </TicketRow>
          <div
            className={clsx(classes.row, {
              [classes.hiddenRow]: !isOption,
            })}
          >
            <Controller
              name='OptionExerciseTypeID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='future-option-exercise-label'>Exercise Type</InputLabel>
                  <Select labelId='future-option-exercise-label' id='future-option-exercise' {...field} label='Futures Exercise Type'>
                    {option_exercise_type}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller
              name='OptionTypeID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='future-option-type-label'>Option Type</InputLabel>
                  <Select labelId='future-option-type-label' id='future-option-type' {...field} label='Futures Option Type'>
                    {option_type}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller
              name='OptionStrike'
              control={control}
              render={({ field }) => <TextField size={'small'} id={`strike`} label='Strike Price' type={'number'} InputProps={{ type: 'decimal' }} variant='outlined' className={classes.formControl} {...field} />}
            />
            <Controller
              name='OptionIV'
              control={control}
              render={({ field }) => <TextField size={'small'} id={`impliedVol`} label='Implied Volatility' type={'number'} InputProps={{ type: 'decimal' }} variant='outlined' className={classes.formControl} {...field} />}
            />
            <Controller
              name='OptionUnderlyingPrice'
              control={control}
              render={({ field }) => <TextField size={'small'} id={`underlyingPrice`} label='Underlying Price' type={'number'} InputProps={{ type: 'decimal' }} variant='outlined' className={classes.formControl} {...field} />}
            />
          </div>
          <VolumeInput productID={4} controllerClassName={classes.formControl} showTotalVolume showNotional />
        </div>
      )}
    </PaperItem>
  );
}

export { ElectricityOTCTicketDetails };
