import React from 'react';

import { PaperItem } from 'components/styled/Grid';
import { makeStyles } from '@material-ui/core';
import { theme } from 'style/theme';
import { Header } from 'components/Header';
import { Dealpad } from './dealpad';
import { StyledContainer } from 'components/styled';
import { useSocketDispatch } from 'redux/hooks';
import { fetchField } from 'redux/thunk';
// import { useSocketDispatch } from 'redux/hooks';
// import { fetchField } from 'redux/thunk';

const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    width: '-webkit-fill-available',
    textAlign: 'left',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
  },
});

export type DealpadType = 'gas' | 'carbon' | 'electricity_futures' | 'electricity_otc';

export type DealpadLocationState = { dealpad: DealpadType } | undefined;

function Home(): JSX.Element {
  const classes = useStyles();

  const dispatch = useSocketDispatch();
  React.useEffect(() => {
    dispatch(fetchField()).catch(e => console.error(e));
  }, []);

  // const dealPadArray: { [k in  DealpadType]: () => JSX.Element } = { electricity_futures: Futures, GAS: Gas, carbon: Carbon };
  // const DealpadComponent = state ? dealPadArray[state.dealpad] : dealPadArray[AEProduct.ELECTRICITY];
  return (
    <StyledContainer columns={'repeat(4, 1fr)'} rows={'2.5rem repeat(4, 1fr)'}>
      <Header text={'Aotearoa Energy Broking Interface'} max />
      <PaperItem colSpan={4} rowSpan={4} className={classes.paper}>
        <Dealpad />
      </PaperItem>
    </StyledContainer>
  );
}

export { Home };
