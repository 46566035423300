import React, { useState, useEffect } from 'react';
import { Route, RouteProps, useLocation } from 'react-router-dom';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../modules/msal/authConfig';
import { InteractionType } from '@azure/msal-browser';
import { MsalError } from 'router/MsalError';
import { MsalLoading } from 'router/MsalLoading';
import { MessagePage } from './MessagePage';

interface RouteWithRoles extends RouteProps {
  roles?: string[];
  component: React.ComponentType<any>;
}

export const RouteGuard = (allProps: RouteWithRoles) => {
  const { instance } = useMsal();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const location = useLocation();

  const { component: Component, roles, ...props } = allProps;
  const onLoad = () => {
    const currentAccount = instance.getActiveAccount();
    if (!roles) setIsAuthorized(true);

    if (currentAccount?.idTokenClaims && currentAccount.idTokenClaims['roles'] && roles) {
      const intersection = roles.filter(role => currentAccount?.idTokenClaims?.['roles']?.includes(role));

      if (intersection.length > 0) {
        setIsAuthorized(true);
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, [instance]);

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Silent} authenticationRequest={loginRequest} errorComponent={MsalError} loadingComponent={MsalLoading}>
      {isAuthorized ? <Route {...props} render={routeProps => <Component {...routeProps} />} /> : <MessagePage message={`You are not authorized to view this page ${location.pathname}`} showCloud />}
    </MsalAuthenticationTemplate>
  );
};
