import React from 'react';
import { NodeData } from '../eod.types';
import { useAppSelector } from 'redux/hooks';
import { BidOfferVolume } from './BidOfferVolume';
import { twoDP, makeChangeClass } from '../util';

export const EODRow = ({ code, bid, ask, last, volume, lastSettleDelta, dayChange, prev, settle }: NodeData) => {
  const { matchMode, simpleView } = useAppSelector(state => state.broking);

  return (
    <>
      {(!matchMode || (matchMode && !simpleView)) && (
        <>
          <td className='bid lhbb rhbb'>{twoDP(bid)}</td>
          <td className='ask rhbb'>{twoDP(ask)}</td>
          <td className='grey'>{twoDP(last)}</td>
        </>
      )}
      {!matchMode && <td className='grey'>{volume}</td>}
      {(!matchMode || (matchMode && !simpleView)) && (
        <>
          <td className={makeChangeClass(lastSettleDelta)}> {twoDP(lastSettleDelta)}</td>
          <td className={makeChangeClass(dayChange)}>{twoDP(dayChange)}</td>
        </>
      )}
      {!matchMode && <td className='settle rhhb lhhb'>{twoDP(prev)}</td>}
      {matchMode && <BidOfferVolume contractCode={code} bid />}
      <td className='settle rhbb'>{twoDP(settle)}</td>
      {matchMode && <BidOfferVolume contractCode={code} ask />}
    </>
  );
};
