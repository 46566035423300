import { makeStyles, createStyles, Theme, styled } from '@material-ui/core';
import { FormControlPCP } from 'components/styled';

export const TicketFormControl = styled(FormControlPCP)(({ theme }) => ({
  margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
  minWidth: 200,
  width: '50%',
  textAlign: 'left',
}));

export const useTicketStyles = makeStyles((theme: Theme) =>
  createStyles({
    formRoot: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      padding: theme.spacing(1),
      border: 0,
      borderRadius: 0,
    },
    headerRoot: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0px 8px 0px 8px',
    },
    header: {
      alignSelf: 'center',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      height: '100%',
      transition: 'height 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      overflow: 'hidden',
      placeItems: 'center',
    },
    hiddenRow: {
      height: 0,
    },
    divider: {
      marginTop: 3,
      marginBottom: 7,
      height: '1px',
      width: '-webkit-fill-available',
    },
    paper: {
      padding: theme.spacing(2),
      width: '-webkit-fill-available',
      textAlign: 'left',
      color: theme.palette.text.primary,
      whiteSpace: 'nowrap',
      flexDirection: 'column',
      transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    formControl: {
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
      minWidth: 200,
      width: '50%',
      textAlign: 'left',
    },
    formControlLabel: {
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    checkBox: {
      display: 'flex',
    },
  }),
);
