/* eslint-disable @typescript-eslint/no-unused-vars */
import { Socket } from 'types/pantheon/specific.types';
import { useSocketDispatch } from 'redux/hooks';
import { wsAsyncActions } from 'redux/middleware/socket/actions';
import { Pantheon } from 'types/pantheon/PantheonSocket';

export interface SocketEmitOptions {
  noError?: boolean;
  noSuccess?: boolean;
}

export const useSocketEmit = (
  event: string,
  options?: SocketEmitOptions,
  socketInstance?: Socket,
): [<TRequest extends Pantheon.Request<Pantheon.PacketType>, TResponse extends Pantheon.Response<unknown>>(message: TRequest, callback?: (response: TResponse | undefined) => void) => void] => {
  const dispatch = useSocketDispatch();

  // use socket specified in HOC, otherwise use hook socket instance

  // const { sendSnack } = useSnackSend();

  // const { noError, noSuccess } = options || {};

  const emit = <TRequest extends Pantheon.Request<Pantheon.PacketType>, TResponse extends Pantheon.Response<unknown>>(message: TRequest, callback?: (response: TResponse | undefined) => void) => {
    void dispatch<TResponse>(wsAsyncActions.WS_EMIT({ event, message }))
      .then(response => callback && callback(response))
      .catch(e => console.error(e));
    // emit message
    // socket.emit(channel, { ...query, queryID: sockID.current });

    // response
    // setImmediate(() =>
    // socket.once(sockID.current, (response: TResponse | undefined) => {
    //   // auto-handler of all request type errors using snackbar (default on)
    //   if (!noError && response && response.code !== 200) {
    //     sendSnack(parseResponse(response.message), 'error');
    //   }

    //   // auto-handler of non-get success using snackbar (default on)
    //   if (!noSuccess && response && response.code === 200 && query.method !== 'get') {
    //     sendSnack(parseResponse(response.message), 'success');
    //   }

    //   callback && callback(response);
    // });
    // );
  };

  return [emit];
};
