import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Divider, Grid, Theme } from '@material-ui/core';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import { UberIconButton } from 'components/UberIconButton';
import { GridRowData, GridRowId, useGridSlotComponentProps } from '@material-ui/x-grid';
import { useSocketDispatch } from 'redux/hooks';
import { wsAsyncActions } from 'redux/middleware/socket/actions';
import { appActions } from 'redux/slice/app/app';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbarRoot: {
      width: '-webkit-fill-available',
      borderWidth: `0px 0px 1px 0px`,
      borderStyle: `solid`,
      borderColor: theme.palette.divider,
      borderRadius: `0px`,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
    },
  }),
);

const ExecuteToolbar = (closeModal: () => void, admin: boolean) => (): JSX.Element => {
  const classes = useStyles();
  const { apiRef } = useGridSlotComponentProps();
  const socketDispatch = useSocketDispatch();

  // action wrapper to check selection. uses action for rows, or alerts if none are selected
  const actionWithSelection = (action: () => void) => () => {
    const selected = apiRef.current.getSelectedRows();
    selected.size === 0 ? socketDispatch(appActions.WARNING('You must have selected some rows for this action')) : action();
  };

  const onConfirm = () => {
    const rows = apiRef.current.getSelectedRows();
    socketDispatch(wsAsyncActions.WS_RPC({ method: 'execute', procedure: 'manualFill', parameters: { ticketId: Array.from(rows).map((trade: [GridRowId, GridRowData]) => +trade[0]) } })).catch(console.error);
    closeModal();
  };

  const amendFlag = (setting: boolean) => () => {
    const rows = apiRef.current.getSelectedRows();
    socketDispatch(
      wsAsyncActions.WS_API({
        type: 'API_REQUEST',
        method: 'patch',
        schema: 'ae',
        table: 'ticket',
        id: Array.from(rows).map((trade: [GridRowId, GridRowData]) => +trade[0]),
        data: { isExecuted: setting },
      }),
    )
      .then(() => closeModal())
      .catch(e => console.error(e));
  };

  return (
    <Grid container alignItems='center' classes={{ root: classes.toolbarRoot }}>
      <UberIconButton
        onClick={actionWithSelection(() => socketDispatch(appActions.CONFIRM({ message: 'Mark trades as execute and upload fill?', onConfirm })))}
        tooltip={'Mark selected trades as executed and upload manual fill to TT'}
        Icon={HourglassFullIcon}
      />
      <Divider orientation='vertical' flexItem />
      {admin && (
        <>
          <UberIconButton
            onClick={actionWithSelection(() => socketDispatch(appActions.CONFIRM({ message: 'Forcibly Mark selected trades as executed?', onConfirm: amendFlag(true) })))}
            tooltip={'Forcibly Mark selected trades as executed'}
            Icon={HourglassFullIcon}
          />
          <UberIconButton
            onClick={actionWithSelection(() => socketDispatch(appActions.CONFIRM({ message: 'Forcibly Mark selected trades as not executed?', onConfirm: amendFlag(false) })))}
            tooltip={'Forcibly Mark selected trades as not executed'}
            Icon={HourglassFullIcon}
          />
          <Divider orientation='vertical' flexItem />
        </>
      )}
    </Grid>
  );
};

export { ExecuteToolbar };
