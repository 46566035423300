import { VariantType } from 'notistack';
import { SocketRequest } from './socket.types';

//! --------------------------- FRONTEND
export type CustomSnackOptions = {
  closeButton?: boolean;
};

export type PantheonNotification = {
  message: string;
  variant: VariantType;
  options?: CustomSnackOptions;
};

export type StoredNotification = PantheonNotification & {
  ts: number;
};

export interface MakeTimeSeriesSP {
  dataTable: string;
  xAxisColumn: string;
  title: string;
  seriesNames: string[];
  seriesColumns: string[];
  range: [string, string];
}

export interface SocketUploadRequest extends SocketRequest {
  purpose: 'match';
  data: string;
}
//! --------------------------- PCP DRAWER

export interface DrawerItem {
  icon: string;
  text: string;
  path: string;
}

//! --------------------------- MYSQL DATA TYPING
export type DrawerDefinition = Record<string, DrawerItem[]>;

export enum MySQLTypes {
  DECIMAL = 0x00, // aka DECIMAL (http://dev.mysql.com/doc/refman/5.0/en/precision-math-decimal-changes.html)
  TINY = 0x01, // aka TINYINT, 1 byte
  SHORT = 0x02, // aka SMALLINT, 2 bytes
  LONG = 0x03, // aka INT, 4 bytes
  FLOAT = 0x04, // aka FLOAT, 4-8 bytes
  DOUBLE = 0x05, // aka DOUBLE, 8 bytes
  NULL = 0x06, // NULL (used for prepared statements, I think)
  TIMESTAMP = 0x07, // aka TIMESTAMP
  LONGLONG = 0x08, // aka BIGINT, 8 bytes
  INT24 = 0x09, // aka MEDIUMINT, 3 bytes
  DATE = 0x0a, // aka DATE
  TIME = 0x0b, // aka TIME
  DATETIME = 0x0c, // aka DATETIME
  YEAR = 0x0d, // aka YEAR, 1 byte (don't ask)
  NEWDATE = 0x0e, // aka ?
  VARCHAR = 0x0f, // aka VARCHAR (?)
  BIT = 0x10, // aka BIT, 1-8 byte
  TIMESTAMP2 = 0x11, // aka TIMESTAMP with fractional seconds
  DATETIME2 = 0x12, // aka DATETIME with fractional seconds
  TIME2 = 0x13, // aka TIME with fractional seconds
  JSON = 0xf5, // aka JSON
  NEWDECIMAL = 0xf6, // aka DECIMAL
  ENUM = 0xf7, // aka ENUM
  SET = 0xf8, // aka SET
  TINY_BLOB = 0xf9, // aka TINYBLOB, TINYTEXT
  MEDIUM_BLOB = 0xfa, // aka MEDIUMBLOB, MEDIUMTEXT
  LONG_BLOB = 0xfb, // aka LONGBLOG, LONGTEXT
  BLOB = 0xfc, // aka BLOB, TEXT
  VAR_STRING = 0xfd, // aka VARCHAR, VARBINARY
  STRING = 0xfe, // aka CHAR, BINARY
  GEOMETRY = 0xff, // aka GEOMETRY
}

export enum MySQLCustomTypes {
  BOOLEAN,
}

export enum DataGridTypeMap {
  DECIMAL = 'number', // aka DECIMAL (http://dev.mysql.com/doc/refman/5.0/en/precision-math-decimal-changes.html)
  TINY = 'number', // aka TINYINT, 1 byte
  SHORT = 'number', // aka SMALLINT, 2 bytes
  LONG = 'number', // aka INT, 4 bytes
  FLOAT = 'number', // aka FLOAT, 4-8 bytes
  DOUBLE = 'number', // aka DOUBLE, 8 bytes
  NULL = 'string', // NULL (used for prepared statements, I think)
  TIMESTAMP = 'number', // aka TIMESTAMP
  LONGLONG = 'number', // aka BIGINT, 8 bytes
  INT24 = 'number', // aka MEDIUMINT, 3 bytes
  DATE = 'date', // aka DATE
  TIME = 'dateTime', // aka TIME
  DATETIME = 'dateTime', // aka DATETIME
  YEAR = 'number', // aka YEAR, 1 byte (don't ask)
  NEWDATE = 'date', // aka ?
  JSON = 'string',
  VARCHAR = 'number', // aka VARCHAR (?)
  BIT = 'boolean', // aka BIT, 1-8 byte
  TIMESTAMP2 = 'number', // aka TIMESTAMP with fractional seconds
  DATETIME2 = 'dateTime', // aka DATETIME with fractional seconds
  TIME2 = 'dateTime', // aka TIME with fractional seconds
  NEWDECIMAL = 'number', // aka DECIMAL
  ENUM = 'string', // aka ENUM
  SET = 'string', // aka SET
  TINY_BLOB = 'string', // aka TINYBLOB, TINYTEXT
  MEDIUM_BLOB = 'string', // aka MEDIUMBLOB, MEDIUMTEXT
  LONG_BLOB = 'string', // aka LONGBLOG, LONGTEXT
  BLOB = 'string', // aka BLOB, TEXT
  VAR_STRING = 'string', // aka VARCHAR, VARBINARY
  STRING = 'string', // aka CHAR, BINARY
  GEOMETRY = 'string', // aka GEOMETRY
  BOOLEAN = 'boolean',
}

export type MySQLAllTypes = (MySQLCustomTypes | MySQLTypes) & DataGridTypeMap;

export interface MySQLFieldInfo {
  catalog: string;
  db: string;
  table: string;
  orgTable: string;
  name: string;
  orgName: string;
  charsetNr: number;
  length: number;
  flags: number;
  decimals: number;
  default?: string;
  zeroFill: boolean;
  protocol41: boolean;
  type: string;
}
