/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Action, AnyAction, createAction, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { PantheonNotification } from 'types/pantheon/pantheon.types';
import { RootState } from '../../store';
import { API, RPC, SP } from 'types/pantheon/PantheonSocket';
import { TokenClaims } from '@azure/msal-common';

// define actions here
export enum SocketAction {
  WS_CONNECTED,
  WS_DISCONNECTED,
  WS_READY,
  WS_JOIN,
  WS_LEAVE,
  WS_LISTEN,
  WS_ON,
  WS_NOTIFY,
  WS_LOGOUT,
  WS_PURGE_INCOMING,
  WS_RECONNECT,
}

export enum SocketThunkAction {
  WS_CONNECT,
  WS_DISCONNECT,
}

export enum SocketAsyncAction {
  WS_EMIT,
  WS_API,
  WS_SP,
  WS_RPC,
}

export type ConnectPayload = Required<Pick<TokenClaims, 'oid' | 'roles'>>;
export type EmitPayload = {
  event: string;
  message?: unknown;
};

export type ListenPayload = {
  channel: string;
  toState: 'on' | 'off';
};
//! API BLOCK

export type ReceivedPayload = {
  action: PayloadAction<ListenPayload, 'WS_LISTEN'>;
  payload: unknown;
};

// add pantheon api stuff here?
// rpc? sp?

export type SocketActionType = keyof typeof SocketAction;
// eslint-disable-next-line @typescript-eslint/ban-types

export type SocketActions =
  | Action<'WS_UPLOAD' | 'WS_CONNECTED' | 'WS_DISCONNECTED' | 'WS_LOGOUT' | 'WS_DISCONNECT' | 'WS_PURGE_INCOMING' | 'WS_RECONNECT' | 'WS_READY' | 'WS_CONNECT'>
  | PayloadAction<string, 'WS_JOIN' | 'WS_LEAVE'>
  | PayloadAction<ReceivedPayload, 'WS_ON'>
  | PayloadAction<ListenPayload, 'WS_LISTEN'>
  | PayloadAction<PantheonNotification, 'WS_NOTIFY'>;

export type SocketAsyncActions = { type: 'WS_API'; payload: API.Request } | { type: 'WS_EMIT'; payload: EmitPayload } | { type: 'WS_SP'; payload: SP.Request } | { type: 'WS_RPC'; payload: Omit<RPC.Request, 'type'> };

export const wsActions = {
  WS_ON: createAction<ReceivedPayload>('WS_ON'),
  WS_CONNECTED: createAction('WS_CONNECTED'),
  WS_READY: createAction('WS_READY'),
  WS_LOGOUT: createAction('WS_LOGOUT'),
  WS_DISCONNECTED: createAction('WS_DISCONNECTED'),
  WS_JOIN: createAction<string, 'WS_JOIN'>('WS_JOIN'),
  WS_LEAVE: createAction<string, 'WS_LEAVE'>('WS_LEAVE'),
  WS_LISTEN: createAction<ListenPayload, 'WS_LISTEN'>('WS_LISTEN'),
  WS_NOTIFY: createAction<PantheonNotification, 'WS_NOTIFY'>('WS_NOTIFY'),
  WS_DISCONNECT: createAction('WS_DISCONNECT'),
  WS_PURGE_INCOMING: createAction('WS_PURGE_INCOMING'),
  WS_RECONNECT: createAction('WS_RECONNECT'),
  WS_CONNECT: createAction('WS_CONNECT'),
};

export const wsAsyncActions: {
  [P in keyof typeof SocketAsyncAction]: (payload: P extends 'WS_API' ? API.Request : P extends 'WS_SP' ? SP.Request : P extends 'WS_RPC' ? Omit<RPC.Request, 'type'> : EmitPayload) => SocketAsyncActions;
} = {
  WS_API: (payload: API.Request) => ({ type: 'WS_API', payload }),
  WS_EMIT: (payload: EmitPayload) => ({ type: 'WS_EMIT', payload }),
  WS_SP: (payload: SP.Request) => ({ type: 'WS_SP', payload }),
  WS_RPC: (payload: Omit<RPC.Request, 'type'>) => ({ type: 'WS_RPC', payload }),
};

export type SocketThunkAPIConfig = {
  state: RootState;
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>;
};
