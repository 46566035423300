import { createStyles, Divider, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { TableProps } from '@material-ui/core/Table/Table';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
    },
    divider: {
      marginTop: 3,
      marginBottom: 7,
      height: '1px',
    },
    rowHeader: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      padding: '0px 0px 0px 16px',
      fontWeight: 'bold',
    },
    cell: {
      padding: '0px 0px 0px 16px',
    },
    evenRow: {
      backgroundColor: theme.palette.background.paper,
    },
    oddRow: {
      backgroundColor: theme.palette.grey[300],
    },
    highlightCell: {
      color: theme.palette.primary.dark,
      padding: '0px 0px 0px 16px',
    },
    highlightRow: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      padding: '0px 0px 0px 16px',
      fontWeight: 'bold',
    },
    highlightCellRow: {
      padding: '0px 0px 0px 16px',
      fontWeight: 'bold',
      color: theme.palette.primary.contrastText,
    },
    table: {
      border: '1px',
      borderColor: theme.palette.primary.light,
      borderStyle: 'solid',
    },
  }),
);

export type TableContentCell = string | number;

export type TableContentRow = TableContentCell[];

export interface GenericTableProps extends TableProps {
  headerRow: string[];
  contentRows: TableContentRow[];
  title?: string;
  noHR?: boolean;
  highlightColumn?: string;
  highlightRow?: number;
}

export function FTRTable(props: GenericTableProps): JSX.Element {
  const { headerRow, contentRows, title, noHR, highlightColumn, highlightRow, ...otherProps } = props;
  const classes = useStyles();

  // applies class to row, depending if it's an even, odd, or a highlight row
  const makeRowClass = (row: number): string => (highlightRow && highlightRow - 1 === row ? classes.highlightRow : row % 2 === 0 ? classes.evenRow : classes.oddRow);
  const makeCellClass = (cell: number): string => (highlightColumn && headerRow.indexOf(highlightColumn) === cell ? classes.highlightCell : classes.cell);

  return (
    <TableContainer>
      {title && (
        <Typography variant={'h5'} style={{ textAlign: 'center' }}>
          {title}
        </Typography>
      )}
      {title && !noHR && <Divider className={classes.divider} />}
      <Table className={classes.table} {...otherProps} size='small' aria-label='a dense table' padding='none' stickyHeader>
        <TableHead>
          <TableRow>
            {headerRow.map((cell: string, key: number) => (
              <TableCell className={classes.rowHeader} key={key} align='left'>
                {cell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {contentRows.map((row: TableContentRow, rowKey: number) => (
            <TableRow key={rowKey} className={makeRowClass(rowKey)}>
              {row.map((cell: TableContentCell, key: number) => (
                <TableCell className={highlightRow && highlightRow - 1 === rowKey ? classes.highlightCellRow : makeCellClass(key)} key={key} align='left'>
                  {headerRow[key] === 'Period' || headerRow[key] === 'TP' ? cell : typeof cell === 'number' ? cell.toFixed(3) : '$' + Number.parseFloat(cell).toFixed(2)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
