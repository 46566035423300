import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { leadingZeroPad } from './helper';
import moment from 'moment';
import { timerParse } from './helper';
import { DigitalClockProps } from './DigitalClock.types';

//
// TODO precision parameter?
// TODO frequency parameter?
// TODO stopwatch/timer option?

export const DigitalClock = (props: DigitalClockProps): JSX.Element => {
  const { type, timerStart, timerEnd, onEnd, frequency, noPrefix, className } = props;
  const precision = frequency ? 1000 / frequency : 10;

  const [time, setTime] = useState<string>('Syncing...');
  const [crossed, setCrossed] = useState<'error' | 'warning' | 'primary'>('primary');

  useEffect(() => {
    const interval = setInterval(
      () => {
        // normal clock
        if (!type || type === 'time') {
          const now = new Date();
          const time = `${leadingZeroPad(now.getHours())}:${leadingZeroPad(now.getMinutes())}:${leadingZeroPad(now.getSeconds())}.${Math.floor(now.getMilliseconds() / precision)}`;
          setTime(time);
          return;
        }

        // time elapsed
        if (type === 'elapsed' && timerStart) setTime(timerParse(timerStart, precision, noPrefix));
        // time remaining
        if (type === 'countdown' && timerEnd) setTime(timerParse(timerEnd, precision, noPrefix));

        if (timerEnd) {
          // trigger crossed state if end time has passed, and call the onEnd callback if passed
          if (moment().unix() > timerEnd / 1000) {
            setCrossed('error');
            if (onEnd) onEnd();
          }

          // trigger warning state if within two minutes of end, then within 30 seconds
          if (moment().add(2, 'minutes').unix() > timerEnd / 1000)
            if (moment().add(30, 'seconds').unix() > timerEnd / 1000) setCrossed('error');
            else setCrossed('warning');
        }
      },
      frequency ? 1000 / frequency : 10,
    );
    return (): void => clearInterval(interval);
  }, [setTime, timerStart, timerEnd, setCrossed, onEnd]);

  return (
    <Box className={className} color={`${crossed}.main`}>
      {time}
    </Box>
  );
};
