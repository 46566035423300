import React from 'react';

import { PaperItem } from 'components/styled/Grid';
import { Link, makeStyles, Typography } from '@material-ui/core';
import { theme } from 'style/theme';
import { AlertTiming } from './bold';
import clsx from 'clsx';
import { MonthlyBrokerageChart } from './ae/dashboard/charts';
import { StyledContainer } from 'components/styled';
import { useAccount } from '@azure/msal-react';
import { DSPUploader } from './DSPUploader';

const useStyles = makeStyles({
  titleMaxHeight: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '-webkit-fill-available',
    fontWeight: 'bolder',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: 'bolder',
  },
  paper: {
    padding: theme.spacing(2),
    width: '-webkit-fill-available',
    textAlign: 'left',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
  },
  control: {
    display: 'flex',
    flexDirection: 'column',
  },
});
const Header = ({ text }: { text: string }) => {
  const classes = useStyles();
  return (
    <PaperItem colSpan={4} rowSpan={1}>
      <Typography variant={'h6'} color={'primary'} className={classes.titleMaxHeight}>
        {text}
      </Typography>
    </PaperItem>
  );
};

const Bold = () => {
  const classes = useStyles();

  return (
    <>
      <Header text={'Bold Trading Homepage'} />

      <PaperItem colSpan={4} rowSpan={1} className={classes.paper}>
        <p>Welcome to the new Aotearoa Energy portal.</p>
        <p>{`Currently there isn't much to display here, but over time New Zealand Energy market information will be displayed here`}</p>
        <p>
          {`If you have any suggestions, requests, or feedback, please email us at `}
          <Link href={`mailto:dev@aotearoa.energy`}>dev@aotearoa.energy</Link>
        </p>
      </PaperItem>
      <PaperItem colSpan={2} rowSpan={1} className={clsx(classes.paper)}>
        <Typography variant={'h6'} color={'primary'} className={classes.title}>
          {`Aussie High impact & Transmission outage report settings`}
        </Typography>
        <AlertTiming />
      </PaperItem>
    </>
  );
};

const Standard = () => {
  const classes = useStyles();

  return (
    <>
      <Header text={'Homepage'} />

      <PaperItem colSpan={4} rowSpan={1} className={classes.paper}>
        <p>Welcome to the new Aotearoa Energy portal.</p>
        <p>{`Currently there isn't much to display here, but over time New Zealand Energy market information will be displayed here`}</p>
        <p>
          {`If you have any suggestions, requests, or feedback, please email us at `}
          <Link href={`mailto:dev@aotearoa.energy`}>dev@aotearoa.energy</Link>
        </p>
      </PaperItem>
    </>
  );
};
const AE = () => {
  const classes = useStyles();

  return (
    <>
      <Header text={'Aotearoa Energy Homepage'} />
      <PaperItem colSpan={4} rowSpan={1} className={classes.paper} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DSPUploader />
      </PaperItem>

      <PaperItem colSpan={2} rowSpan={3}>
        <MonthlyBrokerageChart table={`chart_monthly_brokerage_product`} />
      </PaperItem>
      <PaperItem colSpan={2} rowSpan={3}>
        <MonthlyBrokerageChart table={`chart_monthly_brokerage`} />
      </PaperItem>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const components: { [key: string]: () => JSX.Element } = {
  AE,
  Bold,
  Standard,
  Default: () => <>Home component configuration missing</>,
};

function Home(): JSX.Element {
  const account = useAccount();
  if (!account || !account.idTokenClaims) return <> </>;
  const homeURLarray = account?.idTokenClaims?.['extn.homeComponent'];
  const component = Array.isArray(homeURLarray) && typeof homeURLarray[0] === 'string' ? components[homeURLarray[0]] : components.Default;

  const HomeComponent = React.useMemo(() => component, []);

  return (
    <StyledContainer columns={'repeat(4, 1fr)'} rows={'2.5rem 1fr 7.5rem repeat(2, 1fr)'}>
      <HomeComponent />
    </StyledContainer>
  );
}

export { Home };
