import React, { Dispatch, SetStateAction } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { grey, indigo } from '@material-ui/core/colors';
import { Paper } from '@material-ui/core';
import { DataTableSource } from './hooks/useDataTable';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { DownloadCSV } from 'modules/DownloadCSV';
import { DataSetDefinition } from 'redux/slice/metadata';
import { useAppSelector } from 'redux/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    base: {
      flexGrow: 1,
    },
    view: {
      backgroundColor: grey[200],
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: grey[200],
      },
    },
    item: {
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        backgroundColor: indigo[100],
        cursor: 'pointer',
      },
    },
    selectedItem: {
      marginBottom: theme.spacing(1),
      backgroundColor: indigo[100],
    },
  }),
);

const DataList = ({ setTableQuery }: { setTableQuery: Dispatch<SetStateAction<DataTableSource>> }): JSX.Element => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string>();

  const datasets = useAppSelector(state => state.metadata.datasets);

  const handleClick = (i: number, item: string) => () => {
    const { schema, table } = datasets[i];
    setTableQuery({ schema, table });
    setSelected(item);
  };

  return (
    <div className={classes.base}>
      <List disablePadding>
        {datasets.map((dataset: DataSetDefinition, i: number) => (
          <ListItem key={dataset.ID} component={Paper} className={selected === dataset.ID ? classes.selectedItem : classes.item} onClick={handleClick(i, dataset.ID)}>
            <ListItemText primary={dataset.name} secondary={dataset.description} />
            <ListItemSecondaryAction>
              <DownloadCSV module={'dataset'} id={dataset.ID}>
                <CloudDownloadIcon />
              </DownloadCSV>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export { DataList };
