import fetch from 'node-fetch';
import { StackRequestBody, StackData } from './stackReducer';

export const fetchStackData = async (params: StackRequestBody) => {
  const fetchParams = new URLSearchParams(params as unknown as Record<string, string>);
  return fetch(`https://ae-serverless.azurewebsites.net/api/elec_stacks?${fetchParams.toString()}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  }).then(async res => {
    if (!res.ok) {
      throw new Error('Failed to fetch stack data');
    }
    return (await res.json()) as Promise<StackData>;
  });
};
