import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { theme } from 'style/theme';
import { useTheme } from '@material-ui/core';
import { StackSeries } from './stackReducer';
import moment from 'moment';

// alias for ref of the Highcharts wrapper
export interface HighchartChartRef {
  chart: Highcharts.Chart;
  container: React.RefObject<HTMLDivElement>;
}
interface NexusMiniInterface extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  header: string;
  seriesData: StackSeries;
  grouping: string;
  date: string[];
  style?: React.CSSProperties;
  extraOptions?: Partial<Highcharts.ChartOptions>;
}

const axisLabelStyle: Highcharts.CSSObject = {
  color: '#fff',
  fill: '#fff',
};

const yAxis: Highcharts.YAxisOptions[] = [
  {
    labels: {
      align: 'center',
      style: {
        ...axisLabelStyle,
      },
    },
    title: {
      style: {
        ...axisLabelStyle,
      },
      text: 'MW',
    },
    min: 0,
  },
];

const plotOptions: Highcharts.PlotOptions = {
  area: {
    stacking: 'normal',
    lineWidth: 0,
    marker: {
      enabled: false,
    },
  },
};

const createChartOptions = (grouping: string, seriesData: StackSeries, date: string[], sharedToolTip: boolean, extraOptions?: Partial<Highcharts.ChartOptions>): Highcharts.Options => {
  const series = Object.values(seriesData).sort((a, b) => {
    if (!a.name || !b.name) return 0;
    return Number.parseFloat(a.name) > Number.parseFloat(b.name) ? -1 : 1;
  });

  const xAxis: Highcharts.XAxisOptions = {
    crosshair: true,
    type: 'datetime',
    labels: {
      align: 'center',
      style: {
        ...axisLabelStyle,
      },
    },

    title: {
      style: {
        ...axisLabelStyle,
      },
      text: 'TP start',
    },
  };

  // const bands = seriesData && categories && Array.from(new Set(categories.flatMap((company: string) => seriesData[company].map((band: [number, number]) => band[0])))).sort((a: number, b: number) => b - a);

  const tooltipFormatter: Highcharts.TooltipFormatterCallbackFunction = function (): string {
    const data = this.points || [this];

    if (data === undefined) return '<div>No Data</div>';
    const colGroupWidth = 2;
    const columns = Math.ceil(data.length / 20) * colGroupWidth;
    const rowMax = Math.min(20, data.length);

    const tooltipContainerStyle = `style="display: grid; grid-template-rows: repeat(${rowMax}, 1fr); grid-template-columns: repeat(${columns / 2}, 1fr 2fr); grid-column-gap: '5px 5px'; opacity: '100%'"`;
    const tooltipItemStyle = `style="grid-column-start: #col#; grid-column-end: 'span 1'; grid-row-start: #row#; grid-row-end: 'span 1';"`;
    const tooltipHeaderStyle = `style="grid-column-start: 1; grid-column-end: 'span ${columns}'; font-size:10px;"`;

    const output =
      `<div ${tooltipContainerStyle}>` +
      data.reduce(function (s: string, point: Highcharts.TooltipFormatterContextObject, i: number) {
        const cumulative = series.reduce<number>((prev: number, curr: typeof series[0], idx: number) => (idx >= point.series.index ? prev + (curr?.data ? (curr.data[point?.x] as number) : 0) : prev), 0);

        const row = (i % rowMax) + 2; // starts from second row
        const colNumber = Math.floor(i / rowMax) + 1; // add one as it starts at 0
        const col = (colNumber * 2 + 1) * colGroupWidth; // starts at 1, then adds 3 for each new 3-span column set

        const tooltipItemParsed = tooltipItemStyle.replace('#row#', row.toString());
        return (
          s +
          `<div ${tooltipItemParsed.replace('#col#', col.toString())}><span style="color: ${point.color}">$${Number.parseFloat(point.series.name).toFixed(2)}:  </span></div><div ${tooltipItemParsed.replace(
            '#col#',
            (col + 1).toString(),
          )}> <span style="padding-left: 5px; padding-right: 5px;"
            >${Math.round(point.y).toLocaleString()}MW (${cumulative}MW)</span></div>`
        );
      }, `<div ${tooltipHeaderStyle}><b>${moment(this.x).format('DD/MM/YYYY HH:mm')}</b></div>`) +
      '</div>';
    return output;
  };

  return {
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    } as Highcharts.NavigationOptions,
    chart: {
      type: 'area',
      backgroundColor: theme.palette.background.paper,
      ...extraOptions,
    } as Highcharts.ChartOptions,
    title: {
      style: {
        color: '#fff',
        fontSize: '16px',
        textAlign: 'center',
        padding: '10px',
      },
      text: `${grouping} offer stacks for  ${date[0] !== date[1] ? date[0] + ' through ' + date[date.length - 1] : date[0]}`,
    } as Highcharts.TitleOptions,
    tooltip: {
      backgroundColor: '#ffeaea',
      valueSuffix: ' MW',
      formatter: tooltipFormatter,
      shared: sharedToolTip,
      outside: true,
      useHTML: true,
    } as Highcharts.TooltipOptions,
    yAxis,
    xAxis,
    plotOptions,
    series,
  } as Highcharts.Options;
};

export const ChartBasic = (props: NexusMiniInterface): JSX.Element => {
  const [sharedToolTip] = React.useState<boolean>(false);
  const theme = useTheme();

  const { grouping, seriesData, date, extraOptions, ...otherProps } = props;
  const directRef = React.useRef<HighchartChartRef>(null as unknown as HighchartChartRef);

  const [options, setOptions] = React.useState<Highcharts.Options>({
    title: {
      text: 'Offer stacks',
    },

    chart: {
      backgroundColor: theme.palette.background.paper,
      ...extraOptions,
    },
  });

  const chartLoaded: Highcharts.ChartCallbackFunction = chart => {
    chart.reflow();
  };

  React.useEffect(() => {
    setOptions(createChartOptions(grouping, seriesData, date, sharedToolTip, extraOptions));
  }, [grouping, seriesData, date, sharedToolTip, extraOptions]);

  return (
    // <div {...otherProps}>
    <HighchartsReact highcharts={Highcharts} constructorType={'chart'} options={options} ref={directRef} callback={chartLoaded} containerProps={{ style: { ...(otherProps.style || {}), borderRadius: theme.shape.borderRadius } }} />
  );
};

const EOSChart = ChartBasic;

export { EOSChart };
