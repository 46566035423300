import { RPC } from 'types/pantheon/PantheonSocket';
import { useSocketEmit } from './useSocketEmit';

type RPCEmitter = <TParameters, TResponse = string>(query: RPC.Request<TParameters>, callback?: (response: RPC.Response<TResponse> | undefined) => void) => void;

export const useRPC = (): RPCEmitter => {
  const [emit] = useSocketEmit('pantheon.rpc');
  const rpcEmit = <RequestParameters, TResponse = string>(query: RPC.Request<RequestParameters>, callback?: (response: RPC.Response<TResponse> | undefined) => void) =>
    emit<RPC.Request<RequestParameters>, RPC.Response<TResponse>>(query, callback);

  return rpcEmit;
};
