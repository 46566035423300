import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { VariantType } from 'notistack';
import { PantheonNotification } from 'types/pantheon/pantheon.types';
import { SocketMessage } from 'types/pantheon/socket.types';
import { EmitPayload, ListenPayload } from './actions';
import { API } from 'types/pantheon/PantheonSocket';

// EmitPayload type guard

export const isEmitPayload = (obj: unknown): obj is EmitPayload => typeof obj === 'object' && obj !== null && typeof (obj as EmitPayload).event === 'string';
// API.Request type guard

export const isAPIRequest = (obj: unknown): obj is API.Request => typeof obj === 'object' && obj !== null && 'type' in obj && (obj as API.Request).type === 'API_REQUEST' && 'schema' in obj && 'table' in obj;

export const isAPIResponse = (obj: unknown): obj is API.Response => typeof obj === 'object' && obj !== null && 'queryID' in obj && 'code' in obj && 'message' in obj;

export const isListenAction = (obj: AnyAction): obj is PayloadAction<ListenPayload, 'WS_LISTEN'> => obj.type === 'WS_LISTEN' && typeof obj.payload === 'object' && obj.payload !== null && 'channel' in obj.payload && 'toState' in obj.payload;

export const isVariantType = (obj: unknown): obj is VariantType => typeof obj === 'string' && (obj === 'info' || obj === 'success' || obj === 'warning' || obj === 'error');

export const isSocketMessage = (obj: unknown): obj is SocketMessage<unknown> => typeof obj === 'object' && obj !== null && 'code' in obj && typeof (obj as SocketMessage<unknown>).code === 'number' && 'message' in obj;

export const isPantheonNotification = (obj: unknown): obj is PantheonNotification =>
  typeof obj === 'object' &&
  obj !== null &&
  typeof (obj as PantheonNotification).message === 'string' &&
  (typeof (obj as PantheonNotification).variant === undefined || ('variant' in obj && isVariantType((obj as PantheonNotification).variant)));

export const isNotificationMessage = (obj: unknown): obj is SocketMessage<PantheonNotification> => isSocketMessage(obj) && isPantheonNotification(obj.message);
