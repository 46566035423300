import { API } from 'types/pantheon/PantheonSocket';
import { useSocketEmit } from './useSocketEmit';

type APIEmitter = <TResponse>(query: API.Request, callback?: ((response: API.GetResponse<TResponse> | undefined) => void) | undefined) => void;

export const useAPI = (): APIEmitter => {
  const [emit] = useSocketEmit('pantheon.api');

  const apiEmit = <TResponse,>(query: API.Request, callback?: (response: API.GetResponse<TResponse> | undefined) => void) => emit<API.Request, API.GetResponse<TResponse>>(query, callback);

  return apiEmit;
};
