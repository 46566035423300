import { futures, actions as futuresActions } from './futures';
import { match, actions as matchActions } from './match';
import { metadata, actions as metadataActions } from './metadata';
import { app, actions as appActions } from './app';
import { broking, actions as brokingActions } from './broking';
import { Actions } from 'redux/store';

export const reducers = { futures, match, metadata, app, broking };
const allActions = { ...futuresActions, ...matchActions, ...metadataActions, ...appActions, ...brokingActions };

export type AllSliceActions = Actions<typeof allActions>;

export { futuresActions, matchActions, metadataActions, appActions, brokingActions };
