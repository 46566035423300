import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Pagination } from '@material-ui/lab';
import { useGridSlotComponentProps } from '@material-ui/x-grid';

const useStyles = makeStyles({
  footerRoot: {
    padding: 8,
    display: 'flex',
    width: '100%',
    // height: '4rem',
  },
  paginationRoot: {
    display: 'flex',
    padding: '8px 32px',
    flexShrink: 1,
    marginLeft: 'auto',
  },
});

const BlotterFooter = (): JSX.Element => {
  const classes = useStyles();
  const { state, apiRef } = useGridSlotComponentProps();

  return (
    <div className={classes.footerRoot}>
      <Pagination className={classes.paginationRoot} color='primary' count={state.pagination.pageCount} page={state.pagination.page + 1} onChange={(event, value) => apiRef.current.setPage(value - 1)} />
    </div>
  );
};

export { BlotterFooter };
