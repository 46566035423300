import { AnyAction } from '@reduxjs/toolkit';
import React from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { store, RootState, SocketDispatch } from './store';

export const useAppDispatch = (): SocketDispatch => useDispatch<SocketDispatch>();
export const useSocketDispatch = (): SocketDispatch => useDispatch<SocketDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useActionSubscribe = (action: string): AnyAction | null => {
  const [state, setState] = React.useState<AnyAction | null>(null);

  React.useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const latest = store.getState().socket.incoming;
      latest?.type === action && setState(latest);
    });
    return () => unsubscribe();
  }, []);

  return state;
};
export const useListenerActionSubscribe = (channel: string): AnyAction | null => {
  const [state, setState] = React.useState<AnyAction | null>(null);

  React.useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const latest = store.getState().socket.incoming;
      // matches the channel with the received messages channel (WS_ON adds the original listen action to the payload)
      latest && latest.type === 'WS_ON' && latest.payload.action.payload.channel === channel && setState(latest);
    });
    return () => unsubscribe();
  }, []);

  return state;
};
