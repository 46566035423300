import React from 'react';
import { VariantType } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { CustomSnackOptions } from 'types/pantheon/pantheon.types';
import { useAppDispatch } from 'redux/hooks';
import { actions as appActions } from 'redux/slice/app/app';

interface SnackSendTools {
  sendSnack: (message: string, variant: VariantType, options?: CustomSnackOptions) => void;
  snackConfirmation: (message: string, onConfirm: () => void, onCancel?: () => void) => void;
}

export const useSnackSend = (): SnackSendTools => {
  const dispatch = useAppDispatch();

  // standard dismiss icon
  const snackDismiss = (key: string) => (
    <IconButton aria-label='confirm' onClick={() => dispatch(appActions.closeSnackbar({ key }))} size={'medium'}>
      <CloseIcon />
    </IconButton>
  );

  // wrapper to close snack and execute options function
  const withClose = (key: string, action?: () => void) => () => {
    dispatch(appActions.closeSnackbar({ key }));
    action && action();
  };

  // snack with confirm/cancel
  const snackConfirmation = React.useCallback(
    (message: string, onConfirm: () => void, onCancel?: () => void) =>
      dispatch(
        appActions.enqueueSnack({
          message,
          dismissed: false,
          options: {
            variant: 'info',
            persist: true,
            action: (key: string) => (
              <div>
                <IconButton aria-label='confirm' onClick={withClose(key, onConfirm)} size={'medium'}>
                  <CheckIcon />
                </IconButton>
                <IconButton aria-label='close' onClick={withClose(key, onCancel)} size={'medium'}>
                  <CloseIcon />
                </IconButton>
              </div>
            ),
          },
        }),
      ),
    [],
  );

  // basic snack message
  const sendSnack = React.useCallback((message: string, variant: VariantType, options?: CustomSnackOptions) => {
    const action = options?.closeButton ? snackDismiss : undefined;
    dispatch(appActions.enqueueSnack({ message, dismissed: false, options: { ...options, variant, action } }));
  }, []);

  return { sendSnack, snackConfirmation };
};
