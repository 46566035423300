/* eslint-disable @typescript-eslint/ban-types */
import { IconButton, createStyles, makeStyles, SvgIconTypeMap, Theme } from '@material-ui/core';

import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';
import { StyledTooltip } from './styled/StyledTooltip';

const useStyles = makeStyles<Theme, { baseColour?: Record<string | number, string> }>((theme: Theme) =>
  createStyles({
    button: {
      '& svg': {
        margin: theme.spacing(1.5),
      },
      width: '2.4rem',
      height: '2.4rem',
      color: props => (props.baseColour ? props.baseColour[400] : undefined),
      borderRadius: '2px 2px 0px 0px',
      '&:hover': {
        transition: '0.5s ease',
        color: props => (props.baseColour ? props.baseColour[100] : undefined),
        backgroundColor: '#dadada',
      },
    },
  }),
);
interface StyledIconButtonProps {
  tooltip: string;
  baseColour?: Record<string | number, string>;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  autoMargin?: 'left' | 'right';
  classOverride?: string;
}

const UberIconButton = (props: StyledIconButtonProps): JSX.Element => {
  const { onClick, tooltip, Icon, autoMargin, classOverride } = props;
  const classes = useStyles({ baseColour: props.baseColour });

  const marginProperty = autoMargin
    ? {
        [autoMargin === 'left' ? 'marginLeft' : 'marginRight']: 'auto',
      }
    : {};

  return (
    <StyledTooltip title={tooltip}>
      <IconButton className={classOverride || classes.button} style={marginProperty} onClick={onClick}>
        <Icon />
      </IconButton>
    </StyledTooltip>
  );
};

export { UberIconButton };
