import React from 'react';

import { StyledContainer } from 'components/styled/StyledContainer';
import { GridPaper } from 'components/styled/Grid';
import { MonthlyBrokerageChart } from './charts';

function Revenue(): JSX.Element {
  return (
    <StyledContainer columns={'repeat(2, 1fr)'} rows={'repeat(2, 1fr)'}>
      <GridPaper style={{ padding: 16 }}>
        <MonthlyBrokerageChart table={`chart_monthly_brokerage_product`} />
      </GridPaper>
      <GridPaper style={{ padding: 16 }}>
        <MonthlyBrokerageChart table={`chart_monthly_brokerage`} />
      </GridPaper>
      <GridPaper></GridPaper>
    </StyledContainer>
  );
}

export { Revenue };
