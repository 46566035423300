import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core';
import { XGrid, GridColDef, GridRowData, GridRowParams, GridValueFormatterParams, useGridApiRef, GridSortDirection } from '@material-ui/x-grid';
import { gridStyles } from '../datagrid.style';
import { DataGridTypeMap } from 'types/pantheon/pantheon.types';
import { BlotterFooter } from './BlotterFooter';
import { useLocation } from 'react-router-dom';
import { BlotterToolbar } from './BlotterToolbar';
import { colors } from '@material-ui/core';
import { useSocketRoom } from 'modules/websocket/hooks/useSocketRoom';
import { useSocketListener } from 'modules/websocket/hooks/useSocketListener';
import { API, StatusCode } from 'types/pantheon/PantheonSocket';
import moment from 'moment';
import { useSocketDispatch } from 'redux/hooks';
import { DealpadLocationState } from 'types/Ticket.types';
import { push } from 'connected-react-router';
import { appActions } from 'redux/slice';
import { SocketDispatch } from 'redux/store';

const useStyles = makeStyles({
  grid: {
    height: '100%',
    width: '100%',
  },

  ticketCompleteEven: {
    backgroundColor: `${colors.green[50]} !important`,
  },
  ticketCompleteOdd: {
    backgroundColor: `${colors.green[100]} !important`,
  },
});

const makeErrorHandler = (dispatch: SocketDispatch) => (error: unknown) => {
  if (error instanceof Error) {
    dispatch(appActions.ERROR(error.message));
  }
};

function BrokerBlotter(): JSX.Element {
  const theme = useTheme();
  const gridClasses = gridStyles(theme);
  const classes = useStyles();
  const { state } = useLocation<DealpadLocationState>();

  const [columns, setColumns] = React.useState<GridColDef[]>([]);
  const [rows, setRows] = React.useState<GridRowData[]>([]);
  const [pageNumber, setPage] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [pagesize, setPagesize] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const dispatch = useSocketDispatch();
  const apiRef = useGridApiRef();

  const handleError = makeErrorHandler(dispatch);
  useSocketRoom(`ae.portal.dealpad.${state?.dealpad || 'gas'}`);

  useSocketListener('db.update', (data: { type: 'INSERT' | 'UPDATE'; rows: Record<string, unknown>[] }) => {
    apiRef.current.updateRows(data.rows.map((row: Record<string, unknown>) => ({ id: row.ID, ...row })));
  });

  React.useEffect(() => {
    dispatch<API.GetResponse<{ rowCount: number }>>({
      type: 'WS_API',
      payload: {
        type: 'API_REQUEST',
        method: 'count',
        schema: 'ae',
        table: `portal.dealpad.${state?.dealpad || 'gas'}`,
      },
    })
      .then(({ code, message }) => {
        if (!message) return;

        if (code === StatusCode.OK) {
          if (message.length !== 1) handleError(`Bad message count returned, expected 1, got ${message.length}`);
          else setRowCount(message[0].rowCount);
        } else handleError(message.toString());
      })
      .catch(handleError);
    apiRef.current.setSortModel([]);
    apiRef.current.setPage(0);
    setColumns([]);
    setRows([]);
    setPagesize(0);
  }, [state?.dealpad]);

  React.useEffect(() => {
    let active = true;

    void (async () => {
      setLoading(true);
      const rowData = await dispatch<API.GetResponse<Record<string, unknown>>>({
        type: 'WS_API',
        payload: {
          type: 'API_REQUEST',
          method: 'get',
          schema: 'ae',
          table: `portal.dealpad.${state?.dealpad || 'gas'}`,
          page: {
            pageNumber: pageNumber + 1,
            perPage: pagesize,
          },
          info: {
            fieldInfo: true,
          },
        },
      })
        .then(data => {
          if (data.code !== StatusCode.OK || !data.message) return handleError(data.message);
          return data;
        })
        .catch(handleError);

      if (!active) {
        return;
      }

      if (!rowData?.message || !rowData.tableFields) return setLoading(false);
      // valueFormatter for cells
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const valueFormatter = (fieldInfo: API.TableField) => (params: GridValueFormatterParams) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        switch (params.colDef.type) {
          case 'date':
            return moment(params.value?.toString()).format('DD-MM-YYYY');

          default:
            return params.value;
        }
        // }['Price', 'Gross', 'Sell Comm', 'Buy Comm'].includes(params.field) && typeof params.value === 'number' ? `$${params.value.toFixed(2)}` : params.value;
      };

      // if field (from SQL) contains '_' mark it as hidden
      const isHidden = (field: string) => field.indexOf('_') > -1;

      // parses field name (from SQL) and strips out text before '_'
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const parseName = (field: string) => (isHidden(field) ? field.substring(field.indexOf('_')) + 1 : field);

      // console.log(maxColumnLength);
      if (!columns || columns.length === 0)
        setColumns(
          rowData.tableFields.map((item: API.TableField) => {
            return {
              field: item.name,
              align: ['boolean'].includes(DataGridTypeMap[item.type]) ? 'center' : 'right',
              resizable: true,
              hide: isHidden(item.name),
              headerName: parseName(item.name),
              headerAlign: 'center',
              type: DataGridTypeMap[item.type],
              sortable: false,
              // width: item.name.length * 5,
              flex: 1,
              minWidth: 50,
              valueFormatter: valueFormatter(item),
            };
          }),
        );
      setLoading(false);
      setRows(rowData.message.map((row: Record<string, unknown>) => ({ id: row.ID, ...row })));
      apiRef.current.setSortModel([
        {
          field: 'ID',
          sort: 'desc' as GridSortDirection,
        },
      ]);
    })().catch(handleError);
    return () => {
      active = false;
    };
  }, [rowCount, pageNumber, pagesize]);

  const goto = (param: GridRowParams) => {
    dispatch(push('broking/ticket', { ticketId: param.id, dealpad: state?.dealpad || 'gas' }));
  };

  // applies custom class to rows that have been Proofed, lodged, executed, and confo'd
  const getRowClass = (params: GridRowParams) => {
    const { id, getValue } = params;

    // wrapper to check if prop is undefined, and if not, check if it's flagged as true
    const isComplete = (prop: 'Proofed' | 'Lodged' | 'Executed' | 'Confirmed') => getValue(id, prop) === undefined || getValue(id, prop) === true;

    // classnmame for odd/even rows
    const completeStyle = (id as number) % 2 === 0 ? classes.ticketCompleteEven : classes.ticketCompleteOdd;

    // check if row has all actions complete
    return isComplete('Proofed') && isComplete('Lodged') && isComplete('Executed') && isComplete('Confirmed') ? completeStyle : '';
  };

  return (
    <div className={classes.grid}>
      <XGrid
        loading={loading}
        columns={columns}
        apiRef={apiRef}
        hideFooterPagination={false}
        rows={rows}
        density={'compact'}
        disableColumnMenu
        autoPageSize
        onPageChange={setPage}
        onPageSizeChange={setPagesize}
        disableSelectionOnClick
        checkboxSelection={false}
        paginationMode={'server'}
        className={gridClasses.mainRoot}
        onRowDoubleClick={goto}
        getRowClassName={getRowClass}
        rowCount={rowCount}
        components={{
          Toolbar: BlotterToolbar,
          Footer: BlotterFooter,
        }}
      />
    </div>
  );
}
export { BrokerBlotter };
