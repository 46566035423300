import { broking } from 'pages/ae';
import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { RouteGuard } from './RouteGuard';
import { MessagePage } from './MessagePage';

export const BrokingRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <RouteGuard path={`${path}/ticket`} exact={true} component={broking.Ticket} />
      <RouteGuard path={`${path}`} exact={true} component={broking.Home} />
      <Route component={() => <MessagePage message={`Uh oh ... That page doesnt exist!`} showCloud />} />
    </Switch>
  );
};
