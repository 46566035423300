import { createAction, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { WritableDraft } from 'immer/dist/internal';
import { OptionsObject, SnackbarKey, SnackbarMessage, VariantType } from 'notistack';
import { DrawerItem } from 'types/pantheon/pantheon.types';
export type CustomSnackOptions = {
  closeButton?: boolean;
};

type SnackConfirm = { message: string; onConfirm: () => void; onCancel?: () => void };
type SnackSend = { message: string; variant: VariantType; options?: CustomSnackOptions };

export type SnackbarData = {
  key?: SnackbarKey;
  message: SnackbarMessage;
  dismissed: boolean;
  options?: WritableDraft<OptionsObject>;
  custom?: {
    closeButton?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
  };
};

export const appActions = {
  SEND: createAction<SnackSend, 'app/sendSnack'>('app/sendSnack'),
  CONFIRM: createAction<SnackConfirm, 'app/snackConfirm'>('app/snackConfirm'),
  INFO: createAction<string, 'app/snackInfo'>('app/snackInfo'),
  ERROR: createAction<string, 'app/snackError'>('app/snackError'),
  SUCCESS: createAction<string, 'app/snackSuccess'>('app/snackSuccess'),
  WARNING: createAction<string, 'app/snackWarning'>('app/snackWarning'),
  setDrawer: createAction<DrawerItem[], 'app/loadDrawer'>('app/loadDrawer'),
};

// Define a type for the slice state
export interface AppStore {
  version: string;
  notifications: SnackbarData[];
  drawer: DrawerItem[];
}

// Define the initial state using that type
const initialState: AppStore = { version: process.env.REACT_APP_VERSION || 'N/A', notifications: [], drawer: [] };

export const appSlice = createSlice({
  name: 'appSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    enqueueSnack: (state, action: PayloadAction<SnackbarData>) => {
      const { key, message, dismissed, options, custom } = action.payload;
      state.notifications = [...state.notifications, { key: key || new Date().getTime() + Math.random(), message, dismissed, options, custom }];
    },
    closeSnackbar: (state, action: PayloadAction<{ key: SnackbarKey }>) => {
      state.notifications = state.notifications.map(notification => (!action.payload.key || notification.key === action.payload.key ? { ...notification, dismissed: true } : { ...notification }));
    },
    removeSnackbar: (state, action: PayloadAction<{ key: SnackbarKey }>) => {
      state.notifications = state.notifications.filter(notification => notification.key !== action.payload.key);
    },
  },
  extraReducers: builder =>
    builder
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      // .addCase(actions.WS_CONNECT, () => {})
      .addCase(appActions.INFO, (state, action) => {
        const key = new Date().getTime() + Math.random();
        state.notifications = [...state.notifications, { key, message: action.payload, dismissed: false, options: { persist: false, variant: 'info' } }];
      })
      .addCase(appActions.setDrawer, (state, action) => {
        state.drawer = action.payload;
      })
      .addCase(appActions.SUCCESS, (state, action) => {
        const key = new Date().getTime() + Math.random();

        state.notifications = [
          ...state.notifications,
          {
            key,
            message: action.payload,
            dismissed: false,
            custom: {
              closeButton: true,
            },
            options: {
              persist: false,
              variant: 'success',
            },
          },
        ];
      })
      .addCase(appActions.ERROR, (state, action) => {
        const key = new Date().getTime() + Math.random();

        state.notifications = [
          ...state.notifications,
          {
            key,
            message: action.payload,
            dismissed: false,
            custom: {
              closeButton: true,
            },
            options: {
              persist: true,
              variant: 'error',
            },
          },
        ];
      })
      .addCase(appActions.WARNING, (state, action) => {
        const key = new Date().getTime() + Math.random();

        state.notifications = [
          ...state.notifications,
          {
            key,
            message: action.payload,
            dismissed: false,
            custom: {
              closeButton: true,
            },
            options: {
              persist: false,
              variant: 'warning',
            },
          },
        ];
      })
      .addCase(appActions.CONFIRM, (state, action) => {
        const key = new Date().getTime() + Math.random();

        state.notifications = [
          ...state.notifications,
          {
            key,
            message: action.payload.message,
            dismissed: false,
            custom: {
              onConfirm: action.payload.onConfirm,
            },
            options: {
              persist: true,
              variant: 'info',
            },
          },
        ];
      }),
});

// export type AppActions = PayloadAction<SnackbarData, 'enqueueSnack'> | PayloadAction<{ key: SnackbarKey }, 'closeSnackbar' | 'removeSnackbar'>;
export type AppActions = ReturnType<typeof appSlice.actions[keyof typeof appSlice.actions]>;
const { actions: baseActions } = appSlice;

export const actions = { ...baseActions, ...appActions };

export const app: Reducer<AppStore, AppActions> = appSlice.reducer;
