import { BrowserAuthError } from '@azure/msal-browser';
import { msalInstance } from 'index';

export const getAccessToken = async (scopes: string[]) => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const response = await msalInstance.acquireTokenSilent({
    account: account,
    scopes: scopes,
  });

  return response.accessToken;
};

export const getAuthToken = async (scopes: string[]) => {
  const checkAccount = msalInstance.getActiveAccount();

  if (!checkAccount) {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    } else {
      throw Error('No accounts found! Verify a user has been signed in and setActiveAccount has been called.');
    }
  }

  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error('No active account, even after having tried to set it!');
  }

  const response = await msalInstance
    .acquireTokenSilent({
      account: account,
      scopes: scopes,
    })
    .catch(async (error: BrowserAuthError) => {
      return await msalInstance
        .acquireTokenPopup({
          account,
          scopes: scopes,
        })
        .catch((error: BrowserAuthError) => {
          throw error;
        });

      return error;
    });

  return response;
};

export const getRoles = () => {
  if (!msalInstance.getActiveAccount()?.idTokenClaims) {
    if (msalInstance.getAllAccounts().length > 0) msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    else throw Error('No accounts found! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const account = msalInstance.getActiveAccount();
  if (!account) throw Error('No active account, even after having tried to set it!');
  if (!account.idTokenClaims) throw Error('No idTokenClaims');

  const { roles } = account.idTokenClaims;
  return roles;
};
