import { Button, ButtonProps, CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import React, { Dispatch, SetStateAction } from 'react';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

export interface LoadingButtonProps {
  clickAction: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: string;
  disabled?: boolean;
  buttonProps?: ButtonProps;
}
export const useLoadingButton = (): [(props: LoadingButtonProps) => JSX.Element, Dispatch<SetStateAction<boolean>>] => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const handleButtonClick =
    (action: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      if (!loading) {
        setLoading(true);
        action(event);
      }
    };

  return [
    (props: LoadingButtonProps): JSX.Element => (
      <Button classes={{ root: classes.button }} {...props.buttonProps} disabled={props.disabled || loading} onClick={handleButtonClick(props.clickAction)} variant={'contained'} color={'primary'}>
        {props.label}
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Button>
    ),
    setLoading,
  ];
};
