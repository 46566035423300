import React from 'react';
import { createStyles, IconButton, InputLabel, makeStyles, MenuItem, Select, TableContainer, Theme, Typography, Slider, Button, ButtonGroup, useTheme } from '@material-ui/core';
import { Page, Section } from 'components/Wrapper';
import { PriceTable, TableContentRow } from './PriceTable';
import { useSP } from 'modules/websocket/hooks';
import moment from 'moment';
import { FormControlPCP } from 'components/styled';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { green, red } from '@material-ui/core/colors';
import { WITSNode } from 'redux/slice/metadata';
import { useAppSelector } from 'redux/hooks';
import { LoadType } from 'types/electricity.types';
import { SP } from 'types/pantheon/PantheonSocket';
import { batch } from 'react-redux';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import FunctionsIcon from '@material-ui/icons/Functions';
import clsx from 'clsx';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      width: '-webkit-fill-available',
      height: '-webkit-fill-available',
      display: 'flex',
      flexDirection: 'column',
    },
    inputRow: {
      display: 'flex',
      flexDirection: 'row',
      padding: 4,
      gap: '8px',
    },
    inputColumn: {
      display: 'flex',
      placeContent: 'space-evenly',
      width: 200,
      flexDirection: 'column',
      padding: 4,
      gap: '8px',
    },
    formControl: {
      margin: theme.spacing(1),
      textAlign: 'left',
    },
    dateNavButton: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    singleButton: { width: 'fit-content' },
    calcOpen: {},
    calcClosed: {},
  }),
);

export interface ElecData {
  node: string;
  date: Date;
  tp: number;
  runType: string;
  priceLow: number;
  price: number;
  priceHigh: number;
  runningAvg: number;
  dayAvg: number;
}

export function Home(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { loading, electricity } = useAppSelector(state => ({ loading: !state.metadata.status.loaded, electricity: state.metadata.electricity }));

  const emit = useSP({ noSuccess: true });

  const [headerRow, setHeaders] = React.useState<string[]>(['Date', 'Day Avg', 'Type', 'Avg to date']);
  const [contentRows, setContent] = React.useState<Record<string, TableContentRow[]>>({});
  const [calcOpen, setCalcOpen] = React.useState(false);

  const [nodeOptions, setNodeOptions] = React.useState<WITSNode[]>([]);
  const [node, setNode] = React.useState<string>('');
  const [loadType, setLoadType] = React.useState<LoadType | 'OTHER'>('BASE');
  const [tps, setTps] = React.useState<number[]>([1, 48]);
  const [queryRange, setQueryRange] = React.useState<number[]>([1, 48]);
  const [selectedDate, setSelectedDate] = React.useState<MaterialUiPickersDate>(moment());

  const handleNodeChange = (event: React.ChangeEvent<{ value: unknown }>) => setNode(event.target.value as string);

  const changeLoadType = (value: LoadType) => () => {
    batch(() => {
      setLoadType(value);
      if (value === 'BASE') {
        setTps([1, 48]);
        setQueryRange([1, 48]);
      }
      if (value === 'PEAK') {
        setTps([15, 44]);
        setQueryRange([15, 44]);
      }
    });
  };

  React.useEffect(() => {
    if (!loading) {
      const options = electricity['witsNodes'];
      setNodeOptions([...options]);
      setNode('OTA2201');
    }
  }, [electricity, loading]);

  React.useEffect(() => {
    if (node.length === 0) return;
    const followingDays = 1;
    const leadingDays = 1;

    const sp = 'electricityBlendPrice';
    const params = [node, queryRange[0], queryRange[1], selectedDate?.format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'), followingDays, leadingDays];
    const req: SP.Request<(string | number)[]> = {
      type: 'SP_REQUEST',
      method: 'execute',
      procedure: {
        sp,
        params,
        database: 'datacore',
      },
    };
    emit<[{ columns: { label: string; precision?: number; hidden?: boolean }[] }[], ElecData[]], (string | number)[]>(req, response => {
      if (!response?.message || !Array.isArray(response.message)) return;
      const columns = response.message[0]?.[0]?.columns;
      const content = response.message[1];

      columns &&
        setHeaders(
          Object.values(columns)
            .map(header => (header.hidden ? undefined : header.label))
            .filter(header => header !== undefined) as string[],
        );

      if (Array.isArray(content)) {
        const dates = Array.from(new Set(content.map(row => moment(row.date).format('DD-MM-YYYY')))).reduce<Record<string, TableContentRow[]>>((prev, curr) => {
          return { ...prev, [curr]: [] };
        }, {});
        setContent(
          content.reduce<Record<string, TableContentRow[]>>(
            (prev, curr) => {
              const date = moment(curr.date).format('DD-MM-YYYY');
              return {
                ...prev,
                [date]: [
                  ...prev[date],
                  Object.values(curr)
                    .map((value, i) => {
                      const def = Object.values(columns)[i];
                      const precision = def?.precision;
                      if (def?.hidden) return undefined;
                      if (value instanceof Date) return moment(value).format('DD-MM-YYYY');
                      else return typeof precision !== 'number' ? (typeof value === 'number' ? value.toFixed(2) : value) : Number.parseInt(value).toFixed(precision);
                    })
                    .filter(val => val !== undefined),
                ],
              };
            },
            { ...dates },
          ),
        );
      }
    });
  }, [node, loadType, selectedDate, queryRange]);

  const contentComponent = React.useMemo(
    () =>
      Object.values(contentRows).map((content: TableContentRow[], i) => {
        const centerDate = Object.keys(contentRows)[i] === selectedDate?.format('DD-MM-YYYY');

        const colourOverride = centerDate ? { bgColour: theme.palette.secondary.main, color: theme.palette.getContrastText(theme.palette.secondary.main) } : {};
        return (
          <TableContainer key={i}>
            <PriceTable {...colourOverride} title={`${centerDate ? 'CENTER DATE - ' : ``}${Object.keys(contentRows)[i]}`} headerRow={headerRow} contentRows={content} />
          </TableContainer>
        );
      }),
    [contentRows],
  );
  const handleDateChange = (date: MaterialUiPickersDate | null) => setSelectedDate(date);
  const adjustDate = (offset: number) => () => {
    setSelectedDate(moment(selectedDate).add(offset, 'days'));
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTpsChange = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
    setTps(newValue as number[]);
  };

  function valuetext(value: number) {
    return `TP${value}`;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  const refreshTable = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
    batch(() => {
      setLoadType('OTHER');
      setQueryRange(newValue as number[]);
    });
  };

  const openCalculator = () => setCalcOpen(state => !state);

  const marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 15,
      label: '15',
    },
    {
      value: 44,
      label: '44',
    },
    {
      value: 48,
      label: '48',
    },
  ];

  return (
    <Page title={`Blended spot electricity price`} rows={'1fr '} columns={`repeat(1, 100%)`} columnCount={1}>
      <Section childClassName={classes.section}>
        <div className={classes.inputRow} style={{ justifyContent: 'space-evenly' }}>
          <div className={classes.inputColumn}>
            <ButtonGroup size={'small'} orientation='vertical' color='primary' variant='contained'>
              <Button variant={loadType === 'BASE' ? 'contained' : 'outlined'} onClick={changeLoadType('BASE')}>
                Base
              </Button>
              <Button variant={loadType === 'PEAK' ? 'contained' : 'outlined'} onClick={changeLoadType('PEAK')}>
                Peak
              </Button>
            </ButtonGroup>
          </div>
          <div className={classes.inputColumn}>
            <FormControlPCP fullWidth>
              <Typography id='range-slider' gutterBottom align={'center'}>
                Trading Periods
              </Typography>
              <Slider min={1} step={1} max={48} marks={marks} value={tps} onChange={handleTpsChange} onChangeCommitted={refreshTable} valueLabelDisplay='auto' aria-labelledby='range-slider' getAriaValueText={valuetext} />
            </FormControlPCP>
          </div>
          <div className={classes.inputColumn}>
            <FormControlPCP fullWidth>
              <InputLabel id={`node-label`}>Node</InputLabel>
              <Select labelId={`node-label`} id={`node`} value={node} label='Node' onChange={handleNodeChange}>
                {nodeOptions.map((option: WITSNode) => (
                  <MenuItem value={option.nodeFull} key={option.nodeFull}>
                    {option.nodeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControlPCP>
          </div>
          <div className={classes.inputRow} style={{ gap: 0 }}>
            <IconButton aria-label='prev-day' size='medium' className={classes.dateNavButton} color={'primary'} onClick={adjustDate(-1)}>
              <SkipPreviousIcon fontSize='inherit' />
            </IconButton>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                className={classes.formControl}
                autoOk
                size={'small'}
                inputVariant='outlined'
                variant='dialog'
                format={'DD-MM-YYYY'}
                label='Center date'
                value={selectedDate}
                onChange={handleDateChange}
                style={{ alignSelf: 'center' }}
              />
            </MuiPickersUtilsProvider>
            <IconButton aria-label='next-day' size='medium' className={classes.dateNavButton} color={'primary'} onClick={adjustDate(1)}>
              <SkipNextIcon fontSize='inherit' />
            </IconButton>
          </div>
          <div className={clsx(classes.inputColumn, classes.singleButton)}>
            <IconButton aria-label='calculate-netback' size='medium' className={clsx(calcOpen ? classes.calcOpen : classes.calcClosed, classes.dateNavButton)} onClick={openCalculator}>
              <FunctionsIcon fontSize='inherit' htmlColor={calcOpen ? red[500] : green[500]} />
            </IconButton>
          </div>
        </div>
        {calcOpen && (
          <div className={classes.inputRow} style={{ justifyContent: 'space-evenly' }}>
            this is the calc row
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>{contentComponent}</div>
      </Section>
    </Page>
  );
}
