import { OkPacket } from 'mysql';
import { MySQLAllTypes } from 'types/pantheon/pantheon.types';
import { Socket } from 'types/pantheon/specific.types';

export enum StatusCode {
  OK = 200,
  ERROR = 500,
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
}
//! --------------------------- PANTHEON GENERIC
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace Pantheon {
  export interface Request<RequestType extends PacketType> {
    type: RequestType;
    method: Pantheon.RequestType;
  }

  export type Response<TMessage = string> = { message: TMessage; code: StatusCode };

  export interface UniqueMessage {
    queryID: string;
  }

  export type RequestType = 'get' | 'execute' | 'patch' | 'post' | 'delete' | 'count';
  export type PacketType = 'API_REQUEST' | 'API_RESPONSE' | 'RPC_REQUEST' | 'SP_REQUEST' | 'UPLOAD' | 'EREBUS';
}

//! --------------------------- PANTHEON API
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace API {
  export type OrderByType = 'ASC' | 'DESC';

  export type PaginationOptions = {
    pageNumber: number;
    perPage: number;
  };

  export type LastOptions = {
    count: number;
    orderFields: string[];
    order?: OrderByType;
  };

  export type OrderOptions = [string, OrderByType][];

  type DateRangeOptions = {
    min?: string | number; // strin date, or unix ts
    max?: string | number; // strin date, or unix ts
  };

  type DateRange = (Pick<Required<DateRangeOptions>, 'min'> & Pick<DateRangeOptions, 'max'>) | (Pick<Required<DateRangeOptions>, 'max'> & Pick<DateRangeOptions, 'min'>);
  export type Criteria = [string, DataType | DataType[]][];

  export type DateOptions = DateRange & {
    field: string;
    sign?: '>' | '<' | '<=' | '>=' | '=';
  };

  export type KeyValue = number | string;
  export type FK = [string, KeyValue | KeyValue[]] | undefined;
  export type PK = number | string | number[] | string[] | undefined;
  export type DataType = string | number | boolean | Date;

  export interface PostOptions {
    updateDuplicate?: boolean; // when inserting a record, if duplicate key exists update the record with new values
  }

  export interface Response<TMessage = unknown> extends Pantheon.UniqueMessage, Pantheon.Response<TMessage> {}

  //TODO re-factor to make it narrower for each use (PUT/PATCH/GET)
  export interface Request extends Pantheon.Request<'API_REQUEST'> {
    schema: string;
    table: string;
    page?: API.PaginationOptions; // pagination options
    //todo deprecate and use a single property for all filterign (WHERE)
    id?: API.PK; // specific filter for one or more ID's
    date?: API.DateOptions;
    //TODO rename to criteria.
    fk?: API.FK; // fk to filter by
    criteria?: API.Criteria;
    data?: Record<string, API.DataType>; // data to insert/update
    fields?: string[]; // fields to return
    last?: API.LastOptions; // if set query will get rows from back of table, ordered by PK
    orderBy?: API.OrderOptions; // orders query. array of tuples supplied
    post?: API.PostOptions; // options specific to POST requests
    info?: {
      fieldInfo?: boolean; // return field definitions
      rowCount?: boolean; // return number of rows total
    };
  }

  export type TableField = { name: string; length: number; type: MySQLAllTypes; decimals: number };

  export type GetMeta = { tableFields?: TableField[] };
  export type GetResponse<TRow> = Response<TRow[] | null> & GetMeta;

  export type PutMeta<TMessage> = { message: TMessage; refreshLast?: boolean; okPacket?: OkPacket };
  export type PutResponse<T = string> = Response<PutMeta<T>>;

  export type PostMeta<TMessage> = { message: TMessage; refreshLast?: boolean; okPacket?: OkPacket };
  export type PostResponse<TMessage = string> = Response<PostMeta<TMessage>>;
}

//! --------------------------- PANTHEON RPC
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace RPC {
  export type Data<TPayload = Record<string, unknown>> = { socket: Socket; request: Request<TPayload> & Pantheon.UniqueMessage };

  export interface InternalResponse<TMessage = string> extends Pantheon.Response<TMessage> {
    data?: unknown;
  }

  export interface Response<TMessage = string> extends Pantheon.Response<TMessage> {
    rpc: string;
    data?: unknown;
  }

  export type FunctionWrapper<TResponse = string, TPayload = Record<string, unknown>> = (method: Pantheon.RequestType, parameters: TPayload, callback: (result: InternalResponse<TResponse>) => void, request?: Data<TPayload>) => void;

  export type RPCFunction<TMessage = string, TPayload = Record<string, unknown>> = (method: Pantheon.RequestType, parameters: TPayload, request?: Data<TPayload>) => Promise<InternalResponse<TMessage>>;

  export interface Request<TPayload = unknown> extends Pantheon.Request<'RPC_REQUEST'> {
    procedure: string;
    parameters: TPayload;
    ID?: number | string;
  }
}

//! --------------------------- PANTHEON MySQL SP
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace SP {
  //! --------------------------- PANTHEON SP
  export type Procedure<TParameters = string[] | Record<string, unknown>> = { sp: string; params?: TParameters; database: string };

  export interface Request<TParameters = string[] | Record<string, unknown>> extends Pantheon.Request<'SP_REQUEST'> {
    procedure: SP.Procedure<TParameters>;
  }

  export interface Response<TMessage extends Array<unknown>> extends Pantheon.Response<TMessage>, Pantheon.UniqueMessage {
    sp: string;
  }

  export type Emitter = <TResponse extends Array<unknown>, TParameters = string[]>(query: SP.Request<TParameters>, callback?: (response: SP.Response<TResponse> | undefined) => void) => void;
}
