export interface FuturesClient {
  ID: string;
  name: string;
}

export enum MatchPacketTypes {
  DEFS,
  PRICES,
  SETTLES,
  STATE,
  MATCH_STATE,
  INTEREST_UPDATE,
}

export type MatchPacketType = typeof MatchPacketTypes;
export type MatchPacketKeys = keyof MatchPacketType;

export type MatchVolumeState = 'small' | boolean;
export type MatchPriceState = PricePoint & { volume: number };

export enum MatchPacketActions {
  GET,
  UPDATE,
}
export enum StateType {
  PRICES,
  MATCH,
}
export type MatchPacketActionsType = typeof MatchPacketActions;
export type MatchPacketActionsKeys = keyof MatchPacketActionsType;

export type MatchContractPricesState = {
  stateType: StateType.PRICES;
  settle: PricePoint;
  bid?: MatchPriceState;
  ask?: MatchPriceState;
};

export type MatchContractVolumeState = {
  stateType: StateType.MATCH;
  settle: PricePoint;
  bid: MatchVolumeState;
  ask: MatchVolumeState;
};

export type MatchContractState = MatchContractVolumeState;

export type ContractStatePayload = { contractId: number; state: MatchContractState };

export type PricePoint = {
  price: number;
  isImplied: boolean;
};
export interface MatchContractDefinition<TDate = Date> {
  ID: number;
  displayName: string;
  code: string;
  node: string;
  expiry: TDate;
  settle: PricePoint;
  specification: FuturesSpecification;
}

export type PriceType = 'bid' | 'ask' | 'settle' | 'trade' | 'mark';
export type ElectricityLoadType = 'peak' | 'base';
export type FuturesDurationType = string;
export type FuturesNode = 'ben' | 'ota';

export type MatchGridCellState = boolean | 'smalls';

export type MatchGridNodeState = {
  live: MatchGridCellState;
  matched: MatchGridCellState;
};

export interface FuturesSpecification {
  code: string;
  name: string;
  node: string;
  load: ElectricityLoadType;
  duration: FuturesDurationType;
  hours: number;
  days: number;
  isSynthetic: boolean;
}

export type PriceDataBase = {
  ID: number;
  date: Date;
  time: string;
  clientID: string | null;

  priceType: PriceType;

  volume: number | null;
  isLive: boolean;
  isSynthetic: boolean;
  isGTC: boolean;
  isImplied: boolean;
  hiddenFromClient: string[] | null;
};

export type PriceDataJSON = {
  futuresContract: MatchContractDefinition;
  syntheticContractID: number | null;
  childPrices: PriceDataJSON[] | null;
  price: PricePoint;
} & PriceDataBase;

export type PriceDataSQL = {
  futuresContractID: number;
  syntheticContractID: number | null;
  childPriceID: string[] | string | null;
  price: number;
} & PriceDataBase;

export type ContractSettle = {
  futuresContractID: number;
  settle: PricePoint;
};

export interface Price {
  ts: number;
  clientID: string;
  futuresContractID: number;
  priceType: PriceType;
  volume: number;
  isSynthetic: boolean;
  isRefRequest: boolean;
}

export interface MatchGridState {
  ota: MatchGridNodeState;
  ben: MatchGridNodeState;
  basis: MatchGridNodeState;
}

export interface MatchBoard {
  ota: Price[];
  ben: Price[];
  basis: Price[];
}

export type MatchGridUpdate = Partial<MatchGridState>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DefinitionGet<T = any> = (searchValue: T, data: MatchContractDefinition<string>[]) => MatchContractDefinition<string>[];

export type MatchDefinitionsByDuration = {
  month: MatchContractDefinition<string>[];
  quarter: MatchContractDefinition<string>[];
  year: MatchContractDefinition<string>[];
  [key: string]: MatchContractDefinition<string>[];
};

export type MatchDefinitionsByNode = {
  ben: MatchDefinitionsByDuration;
  ota: MatchDefinitionsByDuration;
};

// type guard for MatchContractDefinition array
export function isMatchContractDefinitionArray(arr: unknown): arr is MatchContractDefinition[] {
  return Array.isArray(arr) && arr.every(item => isMatchContractDefinition(item));
}

export function isMatchContractDefinition(obj: unknown): obj is MatchContractDefinition {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof (obj as MatchContractDefinition).ID === 'number' &&
    typeof (obj as MatchContractDefinition).code === 'string' &&
    typeof (obj as MatchContractDefinition).node === 'string' &&
    (obj as MatchContractDefinition).expiry instanceof Date &&
    typeof (obj as MatchContractDefinition).settle === 'object' &&
    typeof (obj as MatchContractDefinition).settle.price === 'number' &&
    typeof (obj as MatchContractDefinition).settle.isImplied === 'boolean'
  );
}

export function isContractStatePayload(obj: unknown): obj is ContractStatePayload {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof (obj as ContractStatePayload).contractId === 'number' &&
    typeof (obj as ContractStatePayload).state === 'object' &&
    typeof (obj as ContractStatePayload).state.settle === 'object' &&
    typeof (obj as ContractStatePayload).state.settle.price === 'number' &&
    typeof (obj as ContractStatePayload).state.settle.isImplied === 'boolean'
  );
}
