import { Typography, Divider } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

import { Item, ContainerItemProps } from 'components/styled/Grid';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
    flexDirection: 'column',
  },
  divider: {
    marginTop: 4,
    marginBottom: 7,
    height: '1px',
  },
  headerBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {},
  subItem: {
    display: 'flex',
  },
  scrollStyle: {
    overflowY: 'auto',
  },
}));

interface ItemSectionProps extends PropsWithChildren<ContainerItemProps> {
  header?: string;
  childClassName?: string;
  scroll?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function ItemSection({ children, header, className, childClassName, scroll, ...props }: ItemSectionProps): JSX.Element {
  const wrapperClasses = useStyles();

  return (
    <Item className={clsx(wrapperClasses.paper, className, { [wrapperClasses.scrollStyle]: scroll })} flex>
      {header && (
        <div className={wrapperClasses.headerBox}>
          <Typography className={wrapperClasses.header} variant={'h5'}>
            {header}
          </Typography>
          <Divider className={wrapperClasses.divider} />
        </div>
      )}

      <div className={clsx(wrapperClasses.subItem, childClassName, { [wrapperClasses.scrollStyle]: scroll })}>{children}</div>
    </Item>
  );
}

export { ItemSection };
export type { ItemSectionProps };
