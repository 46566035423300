import { Socket } from 'socket.io-client';

export interface WebsocketContextInterface {
  socket: Socket;
}

const useSocketInstance = (): WebsocketContextInterface => {
  return { socket: null as unknown as Socket };
};

export { useSocketInstance };
