import React from 'react';

import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from 'modules/msal/authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { useSocketDispatch } from '../redux/hooks';
import { appActions } from 'redux/slice';
import { InteractionStatus } from '@azure/msal-browser';
import { push } from 'connected-react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      gridGap: theme.spacing(3),
      padding: theme.spacing(3),
      paddingTop: '10%',
      background: 'rgb(36, 76, 90)',
      border: 0,
      borderRadius: 0,
      height: '-webkit-fill-available',
      textAlign: 'center',
      justifyContent: 'center',
    },
    item: {
      overflow: 'clip',
      height: '50%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      '& svg': {
        margin: theme.spacing(1.5),
      },
      backgroundColor: 'rgb(233, 104, 82)',
      width: '9.6rem',
      height: '2.4rem',
      borderRadius: '6px',
      '&:hover': {
        transition: '0.5s ease',
        backgroundColor: lighten('rgb(233, 104, 82)', 0.4),
      },
    },
    main: { height: 'calc(100vh - 64px)', position: 'relative' },
    content: { height: '100%' },
  }),
);

export function Login() {
  const classes = useStyles();
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useSocketDispatch();

  React.useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      dispatch(push('/'));
    }
  }, [isAuthenticated, inProgress]);

  const handleLoginError = (error: unknown) => {
    if (error instanceof Error) dispatch(appActions.ERROR(error.message));
    else dispatch(appActions.ERROR(`Unknown error object - ${JSON.stringify(error)}`));
  };

  const login = () => {
    instance.ssoSilent(loginRequest).catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        instance.loginRedirect(loginRequest).catch(error => handleLoginError(error));
      } else {
        handleLoginError(error);
      }
    });
  };

  const signup = async () => {
    await instance.loginRedirect({ ...loginRequest, prompt: 'create' }).catch(error => handleLoginError(error));
  };

  return (
    <div className={classes.main}>
      <div className={classes.content}>
        <div className={classes.container}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%', alignSelf: 'center' }}>
            <div className={classes.item} style={{ display: 'flex' }}>
              <img src={'/AE_Cloud3.png'} style={{ maxHeight: '100%' }} />
            </div>
            <div className={classes.item}>
              <Button className={classes.button} variant={'outlined'} onClick={() => void login()}>
                Login
              </Button>
              <Button className={classes.button} variant={'outlined'} onClick={() => void signup()}>
                Sign-up
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
