import { createAsyncThunk } from '@reduxjs/toolkit';
import { DrawerItem } from 'types/pantheon/pantheon.types';
import { API } from 'types/pantheon/PantheonSocket';
import { PortalKey } from 'types/sql/ae.portal.types';
import { wsAsyncActions } from '../middleware/socket';
import { metadataSlice } from '../slice/metadata/metadata';
import { RootState, SocketDispatch } from '../store';
import { appActions } from '../slice/app/app';
import { msalInstance } from '../../index';

type ThunkAPIConfig = {
  state: RootState;
  dispatch: SocketDispatch;
};

// First, create the thunk
export const fetchField = createAsyncThunk<void, void, ThunkAPIConfig>('metadata/fetchField', async (_, thunkAPI) => {
  const store = thunkAPI.getState().metadata.ticketMeta;
  for (const key in store) {
    const item = store[key as PortalKey];

    await thunkAPI.dispatch<API.GetResponse<typeof item>>(wsAsyncActions.WS_API({ type: 'API_REQUEST', method: 'get', schema: 'ae', table: `portal.${key}` })).then(response => {
      response.message && thunkAPI.dispatch(metadataSlice.actions.setTicketMeta({ [key]: response.message }));
    });
  }
  return;
});

// First, create the thunk
export const fetchDrawer = createAsyncThunk<void, void, ThunkAPIConfig>('app/fetchDrawer', async (_, thunkAPI) => {
  if (!msalInstance.getActiveAccount()?.idTokenClaims) {
    if (msalInstance.getAllAccounts().length > 0) msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    else throw Error('No accounts found! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const account = msalInstance.getActiveAccount();
  if (!account) throw Error('No active account, even after having tried to set it!');
  if (!account.idTokenClaims) throw Error('No idTokenClaims');

  const { roles } = account.idTokenClaims;

  if (!roles) throw Error('No roles');

  await thunkAPI
    .dispatch<API.GetResponse<{ appRole: string; sidebar: DrawerItem[] }>>(
      wsAsyncActions.WS_API({
        type: 'API_REQUEST',
        method: 'get',
        schema: 'pcp',
        table: `drawer_items`,
        criteria: [['appRole', roles]],
      }),
    )
    .then(res => {
      Array.isArray(res.message) && thunkAPI.dispatch(appActions.setDrawer(res.message.flatMap(item => item.sidebar)));
    });

  return;
});
