// recursively traverse a DOM tree and gathers distinct class names
export const recursiveReduceClasses = (root: Element, classes: Set<string>): void => {
  root.classList.forEach((value: string) => {
    classes.add(value);
  });
  for (let i = 0; i < root.children.length; i++) {
    const node = root.children.item(i);
    if (node) {
      node.classList.forEach((value: string) => {
        classes.add(value);
      });
      if (node.childNodes.length > 0) {
        recursiveReduceClasses(node, classes);
      }
    }
  }
};

// retrives all classes from current document, and return a new stylesheet with the specified classes (taken from the original)
export const makeStyleHTML = (sourceClasses: Set<string>): HTMLStyleElement => {
  const source = document.getElementsByTagName('style');
  const target = document.createElement('style');
  const classList = Array.from(sourceClasses);

  target.appendChild(document.createTextNode(''));
  for (let i = 0; i < source.length; i++) {
    const element = source.item(i);
    const rules = element?.sheet?.rules;
    if (rules) {
      for (let j = 0; j < rules.length; j++) {
        const rule = rules[j];
        if ((rule as CSSStyleRule).selectorText) {
          const needRule = classList.reduce<boolean>((prev: boolean, curr: string) => prev || (rule as CSSStyleRule).selectorText.indexOf(curr) > -1, false);
          if (needRule) {
            target.innerHTML = target.innerHTML + rule.cssText;
          }
        }
      }
    }
  }
  return target;
};

export const makeHTML = (node: Element): string => {
  const tableCSS = new Set<string>();
  recursiveReduceClasses(node, tableCSS);
  const styleEl = makeStyleHTML(tableCSS);
  const newDoc = document.implementation.createHTMLDocument();
  newDoc.body.appendChild(node.cloneNode(true));
  newDoc.head.appendChild(styleEl);
  return newDoc.documentElement.outerHTML;
};
