import React from 'react';
import { Typography } from '@material-ui/core';
import { FileDropZone } from 'lab/FileDropZone';
import { DropEvent } from 'react-dropzone';
import moment from 'moment';
import { useRPC } from 'modules/websocket/hooks';

export const DSPUploader = () => {
  const emit = useRPC();
  const handleFile = (files: File[], event: DropEvent) => {
    files.forEach(file => {
      const fileTimestamp = file.name.replace('DSPSUMMARY_', '').replace('.csv', '');
      const date = moment(fileTimestamp.substring(0, 8), 'YYYYMMDD');
      void file.text().then(text => {
        emit({ procedure: 'uploadFile', parameters: { purpose: 'dsp', data: text, meta: { date } }, type: 'RPC_REQUEST', method: 'post' });
      });
      // dispatch(wsAsyncActions.WS_UPLOAD({ fileName: file.name, context: fileStamp[]}))
    });
  };
  return (
    <div style={{ flexBasis: '33%', flexDirection: 'column', display: 'flex' }}>
      <div style={{ height: 'min-content', textAlign: 'center' }}>
        <Typography variant={'h6'} color={'primary'}>
          FTR DSP Upload
        </Typography>
      </div>
      <FileDropZone onDropAccepted={handleFile}>Drag DSP file here</FileDropZone>
    </div>
  );
};
