import { green, yellow, red } from '@material-ui/core/colors';
import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

const socketStateColor = createSelector(
  (state: RootState) => [state.socket.connected, state.socket.ready],
  socketState => (socketState[0] && socketState[1] ? green : socketState[0] ? yellow : red),
);

export { socketStateColor };
