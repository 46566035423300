import React from 'react';

import { makeStyles, Typography, Divider, Checkbox, InputLabel, Select, CircularProgress } from '@material-ui/core';
import { theme } from 'style/theme';
import { PaperItem } from 'components/styled/Grid';
import clsx from 'clsx';
import { ICarbonTicket } from 'types/Ticket.types';
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form';
import { FormControlPCP } from 'components/styled';
import { useAppSelector } from 'redux/hooks';
import { Product } from '../ticket.types';
import { toProperNoun } from 'lib/helpers';
import { getPortalFieldItems } from '../selectElement';
import { PortalSchema, PortalTableFilter } from 'types/sql/ae.portal.types';
import { BrokerageInput } from '../components';
import { backgroundColour } from './common';

const useStyles = makeStyles({
  headerRoot: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 8px 0px 8px',
  },

  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
    border: 0,
    borderRadius: 0,
  },
  paper: {
    flexDirection: 'column',
    padding: theme.spacing(2),
    width: '-webkit-fill-available',
    textAlign: 'left',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    height: '100%',
    transition: 'height 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    overflow: 'hidden',
  },
  buyer: {
    backgroundColor: '#d1ecf1',
  },
  seller: {
    backgroundColor: '#f8d7da',
  },
  divider: {
    marginTop: 3,
    marginBottom: 7,
    height: '1px',
    width: '-webkit-fill-available',
  },
  header: {
    alignSelf: 'center',
  },
  sideCheckbox: {
    marginLeft: 'auto',
  },
  minimised: {
    height: '4rem',
    overflow: 'hidden',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexWrap: 'wrap',
    placeContent: 'space-around',
    justifyContent: 'space-between',
  },
  formControl: {
    flex: '1 1 0px',
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    minWidth: '30%',
    textAlign: 'left',
  },
  formControlLabel: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  swapButton: {
    '& svg': {
      margin: theme.spacing(1.5),
    },
    backgroundColor: theme.palette.primary.main,
    width: '2.4rem',
    height: '2.4rem',
    borderRadius: '4px',
    '&:hover': {
      transition: '0.5s ease',
      color: 'rgb(154, 195, 191)',
      backgroundColor: '#dadada',
    },
  },
});

const makeSwitch = (productID: Product): PortalTableFilter<'entity'> => {
  const property: { [K in Product]: keyof Pick<PortalSchema['entity'], 'isCarbon' | 'isElec' | 'isGas'> } = {
    [Product.CARBON]: 'isCarbon',
    [Product.ELECTRICITY]: 'isElec',
    [Product.GAS]: 'isGas',
  };

  return {
    [property[productID]]: true,
  };
};

const CarbonTicketSide = ({ side, productID, control, setValue }: { side: keyof Pick<ICarbonTicket, 'buyer' | 'seller'>; productID: Product; control: Control<ICarbonTicket>; setValue: UseFormSetValue<ICarbonTicket> }): JSX.Element => {
  const classes = useStyles();
  const toggleKey: 'hasSeller' | 'hasBuyer' = side === 'buyer' ? `hasBuyer` : 'hasSeller';

  const entities = useAppSelector(state => getPortalFieldItems<'entity'>(state, 'entity', makeSwitch(productID)));
  const entityID = useWatch({
    control,
    name: `${side}.EntityID`,
  });

  const desks = useAppSelector(state => getPortalFieldItems<'desk'>(state, 'desk', { entityID }));
  const people = useAppSelector(state => getPortalFieldItems<'person'>(state, 'person', { entityID }));
  const brokers = useAppSelector(state => getPortalFieldItems<'person'>(state, 'person', { isBroker: true }));

  const sideEnabled = useWatch({
    control,
    name: toggleKey,
  });

  const brokerageRate = useWatch({
    control,
    name: `${side}.brokerage_rate`,
  });

  const volume = useWatch({
    control,
    name: `Volume`,
  });

  React.useEffect(() => {
    setValue(`${side}.brokerage`, +brokerageRate * +volume);
  }, [brokerageRate]);

  const { loaded } = useAppSelector(state => state.metadata.status);

  return (
    <PaperItem
      flex
      colSpan={2}
      rowSpan={1}
      className={clsx(classes.paper, classes[side], {
        [classes.minimised]: !sideEnabled,
      })}
    >
      <div className={classes.headerRoot}>
        <Typography className={classes.header} variant={'h6'}>{`${toProperNoun(side)} Details`}</Typography>
        <Controller name={toggleKey} control={control} render={({ field }) => <Checkbox checked={field.value} className={classes.sideCheckbox} {...field} />} />
      </div>
      <Divider className={classes.divider} />
      {!loaded ? (
        <CircularProgress className={classes.formRoot} />
      ) : (
        <div className={classes.formRoot}>
          <div className={classes.row}>
            <Controller
              name={`${side}.EntityID`}
              control={control}
              render={({ field }) => (
                <FormControlPCP className={classes.formControl}>
                  <InputLabel id={`company-${side}-label`}>Company</InputLabel>
                  <Select labelId={`company-${side}-label`} id={`company-${side}`} label='Company' {...field}>
                    {entities}
                  </Select>
                </FormControlPCP>
              )}
            />
            <Controller
              name={`${side}.CounterpartyID`}
              control={control}
              render={({ field }) => (
                <FormControlPCP className={classes.formControl}>
                  <InputLabel id={`desk-${side}-label`}>Trading Desk</InputLabel>
                  <Select labelId={`desk-${side}-label`} id={`desk-${side}`} disabled={entityID === 0} label='Trading Desk' {...field}>
                    {desks}
                  </Select>
                </FormControlPCP>
              )}
            />
            <Controller
              name={`${side}.TraderID`}
              control={control}
              render={({ field }) => (
                <FormControlPCP className={classes.formControl}>
                  <InputLabel id={`trader-${side}-label`}>Trader</InputLabel>
                  <Select labelId={`trader-${side}-label`} id={`trader-${side}`} disabled={entityID === 0} label='Trader' {...field}>
                    {people}
                  </Select>
                </FormControlPCP>
              )}
            />

            <Controller
              name={`${side}.BrokerID`}
              control={control}
              render={({ field }) => (
                <FormControlPCP className={classes.formControl}>
                  <InputLabel id={`broker-${side}-label`}>Broker</InputLabel>
                  <Select labelId={`broker-${side}-label`} id={`broker-${side}`} label='Broker' {...field}>
                    {brokers}
                  </Select>
                </FormControlPCP>
              )}
            />
            <BrokerageInput<ICarbonTicket> side={side} control={control} className={classes.formControl} backgroundColour={backgroundColour[side]} />
          </div>
        </div>
      )}
    </PaperItem>
  );
};

export { CarbonTicketSide };
