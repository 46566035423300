import React from 'react';

import { PaperItem } from 'components/styled/Grid';
import { Typography, Divider, InputLabel, Select, CircularProgress, MenuItem } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import { TicketFormControl, useTicketStyles } from '../styles';
import { useAppSelector } from 'redux/hooks';
import { getPortalFieldItems } from 'pages/ae/broking/ticket/selectElement';
import { DateBlockInput } from '../components/DateBlockInput';
import { ProductInput } from '../components/ProductInput';
import { TicketRow } from '../components/TicketRow';
import { VolumeInput } from '../components/VolumeInput';
import { IFTRTicket } from 'types/Ticket.types';

function FTRTicketDetails({ control }: { control: Control<IFTRTicket> }): JSX.Element {
  const classes = useTicketStyles();

  const instruments = useAppSelector(state => getPortalFieldItems<'reference_instrument'>(state, 'reference_instrument', { productID: 4 }));
  const schedules = useAppSelector(state => getPortalFieldItems<'electricity_schedule'>(state, 'electricity_schedule'));

  const { loaded } = useAppSelector(state => state.metadata.status);

  return (
    <PaperItem flex colSpan={5} rowSpan={1} className={classes.paper}>
      <div className={classes.headerRoot}>
        <Typography className={classes.header} variant={'h6'}>
          Ticket Details
        </Typography>
      </div>
      <Divider className={classes.divider} />

      {!loaded ? (
        <CircularProgress className={classes.formRoot} />
      ) : (
        <div className={classes.formRoot}>
          <ProductInput productID={4} controllerClassName={classes.formControl} lockProduct lockVenue />
          <TicketRow>
            <DateBlockInput<IFTRTicket> control={control} hours days />
            <Controller
              name='reference_instrumentID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='reference_instrument-label'>Reference Instrument</InputLabel>
                  <Select labelId='reference_instrument-label' id='reference_instrument' label='Reference Instrument' {...field}>
                    {instruments}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller
              name='electricity_scheduleID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='electricity_schedule-label'>Electricity Schedule</InputLabel>
                  <Select labelId='electricity_schedule-label' id='electricity_schedule' label='Electricity Schedule' {...field}>
                    {schedules}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller
              name='ftr_type'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='ftr_type-label'>FTR Type</InputLabel>
                  <Select labelId='ftr_type-label' id='ftr_type' label='FTR Type' {...field}>
                    <MenuItem value={'OPT'}>OPT</MenuItem>
                    <MenuItem value={'OBL'}>OBL</MenuItem>
                  </Select>
                </TicketFormControl>
              )}
            />
          </TicketRow>

          <VolumeInput productID={4} controllerClassName={classes.formControl} showTotalVolume showNotional />
        </div>
      )}
    </PaperItem>
  );
}

export { FTRTicketDetails };
