import { combineReducers, StateFromReducersMapObject, ActionFromReducersMapObject, Reducer } from '@reduxjs/toolkit';
import { connectRouter, routerActions, RouterState } from 'connected-react-router';
import { socket } from './socket';
import { createBrowserHistory } from 'history';
import { reducers } from '../slice';
import type { LocationDescriptorObject } from 'history';
import { serviceBus } from './servicebus';

export const history = createBrowserHistory<LocationDescriptorObject>();

const router: Reducer<RouterState<LocationDescriptorObject<unknown>>, ReturnType<typeof routerActions[keyof typeof routerActions]>> = connectRouter<LocationDescriptorObject>(history);

const reducerMap = {
  ...reducers,
  serviceBus,
  socket,
  router,
};

export type ReducersMapObject = typeof reducerMap;

export const rootReducer: Reducer<StateFromReducersMapObject<ReducersMapObject>, ActionFromReducersMapObject<ReducersMapObject>> = combineReducers(reducerMap);

export type RootState = ReturnType<typeof rootReducer>;
