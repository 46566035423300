import React, { Dispatch, SetStateAction } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useAPI } from 'modules/websocket/hooks';
import { DataGridTypeMap } from 'types/pantheon/pantheon.types';
import moment from 'moment';
import { API } from 'types/pantheon/PantheonSocket';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowHeader: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      padding: '0px 0px 0px 16px',
      fontWeight: 'bold',
    },
    cell: {
      padding: '0px 0px 0px 16px',
    },
    evenRow: {
      backgroundColor: theme.palette.background.paper,
    },
    oddRow: {
      backgroundColor: theme.palette.grey[300],
    },
    header: {
      borderCollapse: 'collapse',
    },
    table: {
      border: '1px',
      borderColor: theme.palette.primary.light,
      borderStyle: 'solid',
    },
  }),
);

export type DataTableSource = { schema: string; table: string } | undefined;

const useDataTable = (): [() => JSX.Element, Dispatch<SetStateAction<DataTableSource>>] => {
  const classes = useStyles();
  const [query, setQuery] = React.useState<DataTableSource>();

  const [data, setData] = React.useState<Record<string, unknown>[]>([]);
  const [fields, setFields] = React.useState<API.TableField[]>([]);

  const emit = useAPI();

  // effect that fetches data when query parameters changed
  React.useEffect(() => {
    if (query) {
      const { schema, table } = query;
      emit<Record<string, unknown>>(
        {
          type: 'API_REQUEST',
          method: 'get',
          schema,
          table,
          info: {
            fieldInfo: true,
          },
        },
        response => {
          if (response?.message && response.tableFields) {
            setData(response.message);
            setFields(response.tableFields);
          }
        },
      );
    }
  }, [query]);

  // returns string of cell contents, processed depending on SQL data type
  const renderCell = (row: number, field: API.TableField): string => {
    const cell = data[row][field.name];
    if (!cell) return '';

    switch (DataGridTypeMap[field.type]) {
      case 'date':
        return moment(cell as Date).format('DD-MM-YYYY');
      case 'number':
        return (cell as number).toFixed(2);
      case 'string':
        return cell as string;
      case 'boolean':
        return cell ? 'true' : 'false';
      case 'dateTime':
        return moment(cell as Date).format('DD-MM-YYYY HH:mm');
      default:
        return 'ERR';
    }
  };

  return [
    () => (
      <TableContainer>
        <Table className={classes.table} size='small' aria-label='a dense table' stickyHeader>
          <TableHead>
            <TableRow className={classes.header}>
              {fields.map((field: API.TableField, key: number) => (
                <TableCell className={classes.rowHeader} key={key} align='left'>
                  {field.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: Record<string, unknown>, rowKey: number) => (
              <TableRow className={rowKey % 2 === 0 ? classes.evenRow : classes.oddRow} key={rowKey}>
                {fields.map((field: API.TableField, cellNumber: number) => (
                  <TableCell className={classes.cell} key={`${field.name}-${cellNumber}`} align='left'>
                    {renderCell(rowKey, field)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ),
    setQuery,
  ];
};

export { useDataTable };
