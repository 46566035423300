import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSocketDispatch } from 'redux/hooks';
import { wsAsyncActions } from 'redux/middleware/socket/actions';

export interface BoldFTRRequest {
  source: string | null;
  sourceOptions: string[];
  aggregationPeriod: string | null;
  calculationPeriod: string | null;
  isObligation?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMySQL = (channel: string): [string[] | null, any[] | null, Dispatch<SetStateAction<BoldFTRRequest | null>>] => {
  const dispatch = useSocketDispatch();
  const [query, setQuery] = useState<BoldFTRRequest | null>(null);
  const [data, setData] = useState<unknown[] | null>(null);
  const [header, setHeader] = useState<string[] | null>(null);

  useEffect(() => {
    void dispatch(wsAsyncActions.WS_EMIT({ event: channel, message: { query } }))
      .catch(e => console.error(e))
      .then((data: unknown): void => {
        if (!data) return;
        if (Array.isArray(data)) {
          const [header, ...rows] = data;
          setHeader(header);
          setData(rows);
        }
      });
  }, [query]);

  return [header, data, setQuery];
};
