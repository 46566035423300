import React from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
// import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

import MoreIcon from '@material-ui/icons/MoreVert';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { NavLink, useHistory } from 'react-router-dom';
import { NavigateBefore } from '@material-ui/icons';
import { StyledTooltip } from '../styled/StyledTooltip';
import { useAppSelector, useSocketDispatch } from 'redux/hooks';
import { wsActions } from 'redux/middleware/socket/actions';
import SwapVerticalCircleTwoTone from '@material-ui/icons/SwapVerticalCircleTwoTone';
// import useDialog from '../generic/AlertDialog/hooks/useDialog';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { useSelector } from 'react-redux';
import { socketStateColor } from 'redux/middleware/socket/selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      marginRight: 'auto',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '2rem',
        display: 'block',
      },
    },
    sectionNav: {
      display: 'flex', //none
    },
    username: {
      paddingRight: '1rem',
    },
    iconRoot: {
      padding: 6,
    },
    sectionDesktop: {
      display: 'flex', //none
      paddingLeft: 6,
      paddingRight: 6,
      // marginLeft: 'auto',
      // [theme.breakpoints.up('md')]: {
      //   display: 'flex',
      // },
    },
    sectionMobile: {
      display: 'none', //flex
      // marginLeft: 'auto',
      // [theme.breakpoints.up('md')]: {
      //   display: 'none',
      // },
    },
  }),
);

const StyledIconButton = withStyles({
  root: {
    '&:hover': {
      transition: '0.5s ease',
      color: 'rgb(154, 195, 191)',
      backgroundColor: '#484855',
    },
  },
})(IconButton);

export function NavBar(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useSocketDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const version = useAppSelector(state => state.app.version);

  const account = useAccount();

  const socketIconColor = useSelector(socketStateColor);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [navMenuAnchorEl, setNavMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [renderLogOut] = React.useState<JSX.Element | null>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isNavMenuOpen = Boolean(navMenuAnchorEl);

  const logout = () => {
    void instance.logoutRedirect({ account }).then(() => dispatch(wsActions.WS_LOGOUT()));
  };

  const handleNavMenuClose = (handlerFunction?: () => void) => (): void => {
    setNavMenuAnchorEl(null);
    handlerFunction && handlerFunction();
  };

  const handleMobileMenuClose = (handlerFunction?: () => void) => (): void => {
    setMobileMoreAnchorEl(null);
    handlerFunction && handlerFunction();
  };

  const handleMenuClose = (handlerFunction?: () => void) => (): void => {
    setAnchorEl(null);
    handleMobileMenuClose(handlerFunction);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>): void => setMobileMoreAnchorEl(event.currentTarget);
  const handleNavMenuOpen = (event: React.MouseEvent<HTMLElement>): void => setNavMenuAnchorEl(event.currentTarget);

  const menuId = 'navbar-menu';
  const renderMobileMenuItems = (
    <Menu anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} id={menuId} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={isMenuOpen} onClose={handleMenuClose()}>
      <MenuItem onClick={handleMenuClose(logout)}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'navbar-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose()}
    >
      <MenuItem onClick={handleMobileMenuClose(logout)}>
        <IconButton aria-label='mail' color='inherit'>
          <ExitToApp />
        </IconButton>
        <p>Log out</p>
      </MenuItem>
      )
    </Menu>
  );

  const navMenuId = 'navbar-nav-menu';
  const disabled = true;

  return (
    <>
      <div id={'version'} style={{ position: 'absolute', bottom: 32, right: 48, zIndex: 5000, letterSpacing: 2 }}>
        <b>v{version}</b>
      </div>
      <AppBar position='sticky'>
        <Toolbar>
          <div className={classes.sectionNav}>
            <StyledTooltip title={'Navigate back'}>
              <StyledIconButton aria-label='back' color='inherit' onClick={() => history.goBack()}>
                <NavigateBefore fontSize={'large'} />
              </StyledIconButton>
            </StyledTooltip>
          </div>
          {!disabled && (
            <IconButton edge='start' className={classes.menuButton} color='inherit' aria-label='nav menu' aria-controls={navMenuId} aria-haspopup='true' onClick={handleNavMenuOpen}>
              <MenuIcon />
            </IconButton>
          )}
          <Typography className={classes.title} variant='h6' noWrap>
            Aotearoa Energy Client Portal
          </Typography>
          {isAuthenticated && (
            <div className={classes.username}>
              <Typography variant='subtitle2' noWrap>
                {account && `${account.name} - ${account.idTokenClaims?.companyname || 'Individual'}`}
              </Typography>
            </div>
          )}
          <div className={classes.sectionDesktop}>
            <StyledTooltip title={'Connection state'}>
              <SwapVerticalCircleTwoTone fontSize={'large'} style={{ color: socketIconColor['A200'] }} />
            </StyledTooltip>
          </div>
          {isAuthenticated && (
            <>
              <div className={classes.sectionDesktop}>
                <StyledTooltip title={'Log out of portal'}>
                  <StyledIconButton classes={{ root: classes.iconRoot }} aria-label='logout' color='inherit' onClick={logout}>
                    <ExitToApp fontSize={'large'} />
                  </StyledIconButton>
                </StyledTooltip>
              </div>

              <div className={classes.sectionMobile}>
                <StyledIconButton aria-label='show more' aria-controls={mobileMenuId} aria-haspopup='true' onClick={handleMobileMenuOpen} color='inherit'>
                  <MoreIcon />
                </StyledIconButton>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
      {isAuthenticated && renderMobileMenu}
      {isAuthenticated && renderMobileMenuItems}
      {isAuthenticated && renderLogOut}
      {isAuthenticated && (
        <Menu anchorEl={navMenuAnchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} id={navMenuId} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNavMenuOpen} onClose={handleNavMenuClose()}>
          <MenuItem component={NavLink} to={'/bold'} onClick={handleNavMenuClose()}>
            <StyledIconButton aria-label='mail' color='inherit'>
              <ExitToApp />
            </StyledIconButton>
            <p>Bold trading</p>
          </MenuItem>
        </Menu>
      )}
    </>
  );
}
