/* eslint-disable @typescript-eslint/no-misused-promises */
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { Socket, Manager } from 'socket.io-client';
import { Packet, PacketType } from 'socket.io-parser';
import { RootState } from 'redux/store';
import { wsActions, SocketActionType, EmitPayload, SocketThunkAction, ConnectPayload } from 'redux/middleware/socket';
import { isEmitPayload, isListenAction, isNotificationMessage } from './guards';
import parser from 'socket.io-msgpack-parser';
import { CallHistoryMethodAction } from 'connected-react-router';
import { History } from 'history';
import { fetchReferenceData, metadataSlice } from 'redux/slice/metadata/metadata';
import { Action, createAction, ThunkDispatch } from '@reduxjs/toolkit';
import { ThunkMiddleware } from 'redux-thunk';
import { PantheonNotification } from 'types/pantheon/pantheon.types';
import { actions as appActions } from 'redux/slice/app/app';
import { API } from 'types/pantheon/PantheonSocket';
import { validateMessage } from 'lib/helpers';
import { getAuthToken } from 'modules/msal/getToken';
import { ManagerOptions } from 'socket.io-client/build/esm/manager';
import { AuthError } from '@azure/msal-common';
import { loginRequest } from 'modules/msal/authConfig';
import { fetchDrawer } from 'redux/thunk';
import { DisconnectDescription } from 'socket.io-client/build/esm/socket';

enum InternalActions {
  ERR_TIMEOUT,
  API_RESPONSE,
  WS_ON,
  WS_LISTEN,
}

type SocketAPI = MiddlewareAPI<
  Dispatch<Action<SocketActionType> | CallHistoryMethodAction<[string, History]> | AnyAction> & ThunkDispatch<RootState, undefined, Action<keyof typeof SocketThunkAction | keyof typeof InternalActions>>,
  RootState
>;

const isResponseMessage = (obj: unknown): obj is API.Response<string> => {
  return typeof obj === 'object' && obj !== null && 'message' in obj && typeof (obj as API.Response<string>).message === 'string';
};

export const isConnectPayload = (obj: unknown): obj is ConnectPayload => {
  return obj !== null && typeof obj === 'object' && Array.isArray((obj as ConnectPayload).roles) && typeof (obj as ConnectPayload).oid === 'string';
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const socketMiddleware = (): ThunkMiddleware<RootState, Action<SocketActionType> | CallHistoryMethodAction<[string, History?] | keyof typeof SocketThunkAction>, undefined> => {
  return (api: SocketAPI) => {
    let event = 0;
    let ready = false;
    let connected = false;
    const socketHost = process.env.NODE_ENV === 'production' ? 'https://socket.aotearoa.energy' : 'http://localhost:4070';

    const ioManagerOptions: Partial<ManagerOptions> = {
      parser,
      forceNew: false,
      autoConnect: false,
      secure: true,
      reconnection: true,
      reconnectionDelay: 5000,
      timestampRequests: true,
      transports: ['websocket', 'polling'],
    };

    const ioManager = new Manager(socketHost, ioManagerOptions);

    const managerLog = (message: unknown) => console.log(`[Socket Manager] ${message}`);

    if (process.env.NODE_ENV === 'development') {
      ioManager.on('open', () => managerLog('open'));
      ioManager.on('error', (err: Error) => managerLog(`[Error] ${err.message} ${err.stack} ${err.name} ${err.cause}`));
      ioManager.on('ping', () => managerLog('ping'));
      ioManager.on('close', (reason: string, description?: DisconnectDescription) => {
        connected = false;
        ready = false;

        managerLog(`[Close] ${reason} ${description}`);
      });
      ioManager.on('reconnect_failed', () => managerLog('reconnect_failed'));
      ioManager.on('reconnect_attempt', (attempt: number) => managerLog(`[Reconnect attempt] ${attempt}`));
      ioManager.on('reconnect_error', (err: Error) => managerLog(`[Error] ${err.message} ${err.stack} ${err.name} ${err.cause}`));
      ioManager.on('reconnect', (attempt: number) => managerLog(`[Reconnect] ${attempt}`));
    }

    ioManager.on('packet', async (packet: Packet) => {
      const { type, data } = packet;
      if (type === PacketType.EVENT && api.getState().socket.ready) return;

      if (type === PacketType.CONNECT) return api.dispatch(wsActions.WS_CONNECTED());
      if (type === PacketType.EVENT && Array.isArray(data) && typeof data[0] === 'string' && data[0] === 'ready') {
        void api.dispatch(wsActions.WS_READY());
        ready = true;
        await Promise.allSettled([api.dispatch(fetchDrawer()), api.dispatch(fetchReferenceData())])
          .catch((err: Error) => managerLog(`[Ready Error] ${err.message} ${err.stack} ${err.name} `))
          .then(output => typeof output === 'object' && api.dispatch(metadataSlice.actions.setLoaded()));

        return;
      }
      if (type === PacketType.DISCONNECT) return api.dispatch(wsActions.WS_DISCONNECTED());
    });

    const socket: Socket = ioManager.socket('/', {});

    const timeoutAction = createAction<string, keyof typeof InternalActions>('ERR_TIMEOUT');
    const responseAction = createAction<unknown, keyof typeof InternalActions>('API_RESPONSE');

    const socketEmit = (payload: EmitPayload, next: Dispatch<Action<keyof typeof InternalActions>>) => {
      if (!isEmitPayload(payload)) return Promise.reject(new Error('Invalid payload'));
      return new Promise(resolve => {
        const { event, message } = payload;
        const emitPayload = validateMessage(message);
        const queryID = emitPayload.queryID;

        socket.emit(event, emitPayload);

        const timeout = setTimeout(() => {
          socket.off(queryID);
          next(timeoutAction(`${event} timed out`));
          resolve(false);
        }, 30000);

        socket.once(queryID, (data: API.Response<API.Response<unknown>>) => {
          clearTimeout(timeout);
          if (isResponseMessage(data)) {
            api.dispatch(appActions.enqueueSnack({ message: data.message, dismissed: false }));
          }

          next(responseAction(data));
          resolve(data);
        });
      });
    };

    return next => (action: AnyAction) => {
      switch (action.type) {
        case 'WS_CONNECT': {
          if (socket.connected || socket.io._readyState === 'opening') {
            return;
          }

          return getAuthToken(loginRequest.scopes).then(response => {
            if (response instanceof AuthError) {
              api.dispatch(appActions.ERROR(response.errorMessage));
            } else {
              // purge listeners
              socket.removeAllListeners();

              // set token
              const { accessToken } = response;
              socket.auth = { accessToken };

              // bind listeners
              socket.on('disconnect', (reason: Socket.DisconnectReason) => {
                api.dispatch(wsActions.WS_DISCONNECTED());
                // api.dispatch(appActions.ERROR(reason.toString()));
                if (reason === 'io server disconnect' || reason === 'io client disconnect') {
                  setTimeout(() => api.dispatch(wsActions.WS_CONNECT()), 10000);
                }
              });

              // socket.io.on('reconnect_attempt', () => {
              //   api.dispatch(appActions.WARNING('Attempting to reconnect socket...'));
              // });

              socket.on('connect_error', (reason: Error) => {
                api.dispatch(wsActions.WS_DISCONNECTED());
                // api.dispatch(appActions.ERROR(reason.toString()));
              });

              socket.on('close', () => {
                ready = false;
                connected = false;
                api.dispatch(wsActions.WS_DISCONNECTED());
                // api.dispatch(appActions.ERROR('Socket connection closed'));
              });

              socket.on('pantheon.notify', (data: PantheonNotification) => api.dispatch(wsActions.WS_NOTIFY(data)));

              socket.connect();

              // tiemout promise
              return void new Promise(resolve => {
                const timeout = setTimeout(() => {
                  if (!ready && !connected) {
                    api.dispatch(timeoutAction(`Socket connect timed out`));
                    api.dispatch(wsActions.WS_DISCONNECTED());
                  }
                  resolve(false);
                }, 30000);

                socket.on('connect', () => {
                  connected = true;
                  clearTimeout(timeout);
                  resolve(true);
                });
              });
            }
          });
        }
        case 'WS_LOGOUT': {
          process.env.NODE_ENV === 'development' && console.log('logout');

          break;
        }
        case 'WS_DISCONNECT': {
          if (socket.connected) {
            // api.dispatch(appActions.WARNING('Socket disconnect called...'));
            socket.disconnect();
          }
          break;
        }
        case 'WS_NOTIFY': {
          const { payload } = action;

          if (isNotificationMessage(payload)) {
            const { message, ...options } = payload.message;

            api.dispatch(appActions.enqueueSnack({ message, dismissed: false, options }));
          }
          break;
        }
        case 'WS_LISTEN': {
          if (isListenAction(action)) {
            const { channel, toState } = action.payload;
            if (toState === 'on') socket.on(channel, (payload: unknown) => next(wsActions.WS_ON({ action, payload })));
            if (toState === 'off') socket.off(channel);
          }
          return next(action);
        }
        case 'WS_API': {
          return socketEmit({ message: action.payload, event: 'pantheon.api' }, next);
        }
        case 'WS_SP': {
          return socketEmit({ message: action.payload, event: 'pantheon.sp' }, next);
        }
        case 'WS_RPC': {
          return socketEmit({ message: { ...action.payload, type: 'WS_RPC' }, event: 'pantheon.rpc' }, next);
        }
        case 'WS_EMIT': {
          return socketEmit(action.payload, next);
        }

        case 'WS_JOIN': {
          if (typeof action.payload === 'string') {
            socket.emit('room.join', { message: action.payload });
            return next(wsActions.WS_JOIN(action.payload));
          }
          return;
        }
        case 'WS_LEAVE': {
          if (typeof action.payload === 'string') {
            socket.emit('room.leave', { message: action.payload });
            return next(wsActions.WS_LEAVE(action.payload));
          }
          return;
        }
        default:
          if (process.env.NODE_ENV === 'development') {
            const newEvent = new Date().getTime();

            console.log((newEvent - event) / 1000, ' seconds ', action);
            event = newEvent;
          }
          return next(action);
      }
    };
  };
};

export default socketMiddleware();
