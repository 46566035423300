import React from 'react';

import { XGrid, GridColDef, GridSortDirection } from '@material-ui/x-grid';
import { gridStyles } from '../../datagrid.style';
import { ProofToolbar } from './ProofToolbar';
import { ModalComponentProps } from '../modal.types';

function ProofScreen(props: ModalComponentProps): JSX.Element {
  const gridClasses = gridStyles();
  const { closeModal, rows, columns } = props;
  const rowValues = Array.from(rows.values());

  // filters columns down to required
  const newColumns = columns.reduce<GridColDef[]>((prev: GridColDef[], curr: GridColDef) => {
    const requiredColumns = ['ID', 'Time', 'Product', 'Volume', 'Price', 'Option', 'hide_SEntity', 'hide_BEntity', 'Buy Account', 'Sell Account', 'Code'];
    return requiredColumns.includes(curr.field) ? [...prev, { ...curr, resizable: false, align: 'center', hide: false }] : prev;
  }, []);

  return (
    <XGrid
      columns={newColumns}
      hideFooterPagination={false}
      rows={rowValues}
      autoPageSize
      checkboxSelection
      pagination
      className={gridClasses.modalRoot}
      sortModel={[
        {
          field: 'ID',
          sort: 'desc' as GridSortDirection,
        },
      ]}
      components={{
        Toolbar: ProofToolbar(closeModal),
      }}
    />
  );
}
export { ProofScreen };
