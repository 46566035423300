import { createStyles, IconButton, InputAdornment, darken, lighten, makeStyles, TextField, Theme, InputLabel, Select } from '@material-ui/core';
import { withoutCommas } from 'lib/helpers';
import { useRPC } from 'modules/websocket/hooks';
import React from 'react';
import { Control, Controller, Path, useFormContext, useWatch } from 'react-hook-form';
import { useAppSelector } from 'redux/hooks';
import { SQLFunctionPayload } from 'types/pcp.types';
import { TicketTypes, TicketInterfaceMap } from 'types/Ticket.types';
import { getPortalFieldItems } from '../selectElement';
import GetAppIcon from '@material-ui/icons/GetApp';
import { FormControlPCP } from 'components/styled';

const allNumbers = (args: unknown[]): args is number[] => args.every(item => typeof item === 'number');

export interface FetchBrokerageParameters {
  entityID: number;
  productID: number;
  venueID: number;
  price: number;
  contract_typeID?: number;
  bro_typeID?: number;
  volume: number;
  duration: number;
}
const makeColours = (theme: Theme, colour: string) => ({
  color: theme.palette.getContrastText(colour),
  backgroundColor: colour,
});

const useStyles = makeStyles<Theme, { backgroundColour: string }>((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    inputAdornmentRoot: {
      height: 'unset',
      maxHeight: 'unset',
      marginRight: '-13px',
    },
    button: {
      '& svg': {
        margin: '8px',
      },
      height: '-webkit-fill-available',
      // marginRight: '-15px',
      // marginTop: '-17px',
      // marginBottom: '-17px',

      padding: 0,
      ...({ backgroundColour }) => makeColours(theme, backgroundColour),
      borderRadius: `0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px`,
      '&:hover': {
        transition: '0.5s ease',
        ...({ backgroundColour }) => makeColours(theme, darken(backgroundColour, 0.6)),
      },
    },
  }),
);
export const BrokerageInput = <T extends TicketInterfaceMap[TicketTypes]>({
  side,
  control,
  className,
  backgroundColour,
}: {
  side: keyof Pick<T, 'buyer' | 'seller'>;
  control: Control<T, any>;
  className?: string;
  backgroundColour: string;
}): JSX.Element => {
  const broType = useAppSelector(state => getPortalFieldItems(state, 'bro_type'));
  const emit = useRPC();
  const classes = useStyles({ backgroundColour });
  const [fetchedBro, setFetchedBro] = React.useState(false);

  const { setValue, getValues } = useFormContext();

  // entity, product, venue, duration
  const fetchBrokerage = () => {
    const values = getValues();

    const parameters = {
      entityID: values[side].EntityID,
      productID: values.ProductID,
      venueID: values.VenueID,
      price: values.Price,
      contract_typeID: values.ContractTypeID,
      volume: values.Volume,
      duration: values.Duration ?? values.Hours,
    };
    return new Promise((resolve: (broData: { broRate: number; brokerage: number }) => void) =>
      emit<SQLFunctionPayload<FetchBrokerageParameters>, Record<string, unknown>>(
        {
          type: 'RPC_REQUEST',
          method: 'execute',
          procedure: 'callFunctionSQL',
          parameters: {
            function: 'CALCULATE_BROKERAGE_SA',
            parameters,
          },
        },
        response => {
          // noop
        },
      ),
    );
  };

  const params = useWatch<T>({ control, name: ['Notional', 'ContractTypeID', `${side}.broTypeID`, `${side}.brokerage_rate`, 'totalVolume', 'Volume', 'Hours', 'Duration', `${side}.EntityID`, 'ProductID', 'VenueID'] as Path<T>[] });

  React.useEffect(() => {
    const [notional, ...others] = params;
    const [, broTypeID, broRate, ...numberParams] = others.map(item => Number(item));
    const brokerageData = [broTypeID, broRate];
    if (numberParams.every(item => item > 0) && typeof notional === 'string') {
      const [totalVolume, volume] = numberParams;
      const [broTypeID, broRate] = brokerageData;
      if (!fetchedBro) {
        void fetchBrokerage();
        setFetchedBro(true);
      } else {
        switch (broTypeID) {
          case 12:
            setValue(`${side}.brokerage`, volume * broRate);
            break;
          case 14:
            setValue(`${side}.brokerage`, withoutCommas(notional) * (broRate / 100));
            break;
          case 15:
            setValue(`${side}.brokerage`, totalVolume * broRate);
            break;
          default:
            setValue(`${side}.brokerage`, broRate);
        }
      }
    }
  }, [JSON.stringify(params)]);

  return (
    <>
      <Controller
        name={`${side}.brokerage_rate` as Path<T>}
        control={control}
        render={({ field }) => (
          <TextField
            size={'small'}
            id={`broRate-${side}`}
            label='Brokerage Rate'
            type={'number'}
            variant='outlined'
            className={className}
            {...field}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 0,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment classes={{ root: classes.inputAdornmentRoot }} position='end'>
                  <IconButton className={classes.button} classes={{ root: classes.root }} size={'small'}>
                    <GetAppIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <Controller
        name={`${side}.broTypeID` as Path<T>}
        control={control}
        render={({ field }) => (
          <FormControlPCP variant='outlined' className={className}>
            <InputLabel id={`broker-${side}-label`}>Brokerage Basis</InputLabel>
            <Select labelId='bro_type-label' id='bro_type' label='Brokerage Basis' {...field}>
              {broType}
            </Select>
          </FormControlPCP>
        )}
      />

      <Controller
        name={`${side}.brokerage` as Path<T>}
        control={control}
        render={({ field }) => (
          <TextField size={'small'} id={`brokerage-${side}`} label='Brokerage' type={'number'} variant='outlined' InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }} className={className} {...field} />
        )}
      />
    </>
  );
};
