import { useEffect } from 'react';
import { useListenerActionSubscribe, useSocketDispatch } from 'redux/hooks';
import { ListenPayload } from 'redux/middleware/socket/actions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useSocketListener = (channel: string, callback?: (...args: any) => void): void => {
  const dispatch = useSocketDispatch();

  // // const [data, setData] = useState<TResponse | null>(null);
  const action = useListenerActionSubscribe(channel);
  useEffect(() => {
    dispatch({ type: 'WS_LISTEN', payload: { channel, toState: 'on' } as ListenPayload });
    return (): void => void dispatch({ type: 'WS_LISTEN', payload: { channel, toState: 'off' } as ListenPayload });
  }, []);

  useEffect(() => {
    if (action) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      callback && callback(action.payload.payload);
    }
  }, [action]);
};
