import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Page, Section } from 'components/Wrapper';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    section: {
      width: '-webkit-fill-available',
      height: '-webkit-fill-available',
    },
  }),
);

export function Home(): JSX.Element {
  const classes = useStyles();

  return (
    <Page title={'AE Datacore'} rows={'1fr '} columns={'1f4'} columnCount={4}>
      <Section childClassName={classes.section}>
        <NavLink to={'/datacore/extractor'}>Data Extractor</NavLink>
      </Section>
    </Page>
  );
}
