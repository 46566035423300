import { Product } from '../ticket.types';
import { PortalSchema, PortalTableFilter } from 'types/sql/ae.portal.types';

export const makeSwitch = (productID: Product): PortalTableFilter<'entity'> => {
  const property: {
    [K in Product]: keyof Pick<PortalSchema['entity'], 'isCarbon' | 'isElec' | 'isGas'>;
  } = {
    [Product.CARBON]: 'isCarbon',
    [Product.ELECTRICITY]: 'isElec',
    [Product.GAS]: 'isGas',
  };

  return {
    [property[productID]]: true,
  };
};
