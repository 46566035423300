import moment from 'moment';
import React, { createContext, PropsWithChildren, useContext } from 'react';
import { useSocketInstance } from 'modules/websocket/context';
import { useAppDispatch } from 'redux/hooks';
import { actions } from 'redux/slice/match/match';
import { MatchPacketTypes, MatchContractDefinition, MatchPacketActions, isMatchContractDefinitionArray, MatchDefinitionsByNode, isContractStatePayload, MatchContractState } from 'types/match.types';
import { getContractsByNode, makeDurationObject } from './util';

const { connect, updateDefinitions, updateGroups, updateState, disconnect } = actions;

type MatchContext = {
  contractDefinitions: MatchContractDefinition<string>[];
  contractGroups: string[];
  contractState: Record<number, MatchContractState>;
};

const MatchContext = createContext<MatchContext>(null as unknown as MatchContext);

// eslint-disable-next-line @typescript-eslint/ban-types
export function MatchProvider({ children }: PropsWithChildren<{}>): JSX.Element {
  const socket = useSocketInstance();
  const dispatch = useAppDispatch();

  const [contextValue] = React.useState<MatchContext>({ contractDefinitions: [], contractGroups: [], contractState: {} });

  const matchConnect = React.useCallback(() => {
    socket.socket.emit('match.client', { action: 'connect' });
  }, []);

  React.useEffect(() => {
    matchConnect();
    socket.socket.on('disconnect', matchConnect);

    socket.socket.on('match.connected', () => {
      dispatch(connect());
      socket.socket.emit('match.data', { action: MatchPacketActions.GET, type: MatchPacketTypes.PRICES });
    });

    // eslint-disable-next-line @typescript-eslint/ban-types
    socket.socket.on('match.data', (data: { action: MatchPacketActions; type: MatchPacketTypes; payload: unknown }) => {
      const { action, type, payload } = data;
      if (action === undefined || type === undefined || payload === undefined) throw new Error('match data packet invalid');
      if (action === MatchPacketActions.UPDATE) {
        if (type === MatchPacketTypes.DEFS) {
          if (isMatchContractDefinitionArray(payload)) {
            dispatch(
              updateGroups(
                payload.reduce<string[]>((prev, curr) => {
                  if (prev.includes(curr.specification.duration)) return prev;
                  else return [...prev, curr.specification.duration];
                }, []),
              ),
            );
            dispatch(updateDefinitions(payload.map(def => ({ ...def, expiry: moment(def.expiry).toISOString() }))));
          }
        }

        if (type === MatchPacketTypes.MATCH_STATE) {
          if (isContractStatePayload(payload)) {
            dispatch(updateState(payload)); // [payload.contractId]: payload.state } }));
          }
        }
      }
    });

    return () => {
      socket.socket.emit('match.client', { action: 'disconnect' });
      socket.socket.off('disconnect', matchConnect);
      socket.socket.off('match.data');
      socket.socket.off('match.connected');
      dispatch(disconnect());
    };
  }, []);

  return <MatchContext.Provider value={contextValue}>{children}</MatchContext.Provider>;
}

// root level context hook
export function useMatchContext(): MatchContext {
  const result = useContext(MatchContext);
  if (!result) {
    throw new Error('Match context is only available inside its provider');
  }

  return result;
}

export function useMatchDefinitions(): { definitions: MatchDefinitionsByNode; syntheticGroups: string[] } {
  const matchContext = useMatchContext();

  const data = React.useMemo(
    () => ({
      definitions: {
        ben: makeDurationObject('ben', getContractsByNode('ben', matchContext.contractDefinitions)),
        ota: makeDurationObject('ota', getContractsByNode('ota', matchContext.contractDefinitions)),

        // ben: { month: getContractByDuration('month', definitionsByNode.ben), quarter: getContractByDuration('quarter', definitionsByNode.ben) },
        // ota: { month: getContractByDuration('month', definitionsByNode.ota), quarter: getContractByDuration('quarter', definitionsByNode.ota) },
      },
      syntheticGroups: matchContext.contractGroups.filter(group => group !== 'month' && group !== 'quarter' && group !== 'year'),
    }),
    [matchContext],
  );

  return data;
}

export const useMatchReady = (): boolean => {
  const matchContext = useMatchContext();
  return matchContext.contractDefinitions.length > 0;
};
