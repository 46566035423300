import { TokenCredential, AccessToken } from '@azure/core-auth';
import { PublicClientApplication, SilentRequest } from '@azure/msal-browser';

export class BrowserCredential implements TokenCredential {
  private hasAuthenticated = false;

  constructor(private publicApp: PublicClientApplication, private scopes: string[]) {}

  // Either confirm the account already exists in memory, or tries to parse the redirect URI values.
  async prepare(): Promise<void> {
    try {
      if (this.publicApp.getActiveAccount()) {
        this.hasAuthenticated = true;
        return;
      }
      await this.publicApp.handleRedirectPromise();
      this.hasAuthenticated = true;
    } catch (e) {
      console.error('BrowserCredential prepare() failed', e);
    }
  }

  // Should be true if prepare() was successful.
  isAuthenticated(): boolean {
    return this.hasAuthenticated;
  }

  // If called, triggers authentication via redirection.
  async loginRedirect(): Promise<void> {
    const loginRequest = {
      scopes: this.scopes,
    };
    await this.publicApp.ssoSilent(loginRequest);
  }

  // Tries to retrieve the token without triggering a redirection.
  async getToken(scopes: string): Promise<AccessToken> {
    if (!this.hasAuthenticated) {
      throw new Error('Authentication required');
    }

    const account = this.publicApp.getActiveAccount();
    if (!account) {
      throw new Error('No active account');
    }
    const parameters: SilentRequest = {
      account,
      scopes: [scopes],
    };

    const result = await this.publicApp.acquireTokenSilent(parameters);
    return {
      token: result.accessToken,
      expiresOnTimestamp: result.expiresOn?.getTime() || new Date().getTime(),
    };
  }
}
