import { makeStyles, Theme, createStyles, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { blueGrey, green } from '@material-ui/core/colors';
import React from 'react';

export type TableContentCell = string | number;

export type TableContentRow = TableContentCell[];
type StyleProps = {
  colour?: string;
  bgColour?: string;
};
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    rowHeader: {
      backgroundColor: ({ bgColour }) => bgColour || theme.palette.primary.light,
      color: ({ colour }) => colour || theme.palette.primary.contrastText,
      padding: '0px 0px 0px 4px',
      fontWeight: 'bold',
      borderRight: '1px',
      borderLeft: '1px',
      borderTop: '1px',
      borderBottom: '1px',

      borderColor: 'white',

      borderStyle: 'solid',
    },
    cell: {
      padding: '0px 0px 0px 4px',
      textAlign: 'center',
      borderRight: '1px',
      borderLeft: '1px',
      borderTop: '0px',
      borderBottom: '0px',
      borderColor: 'black',
      borderStyle: 'solid',
    },
    evenRow: {
      backgroundColor: theme.palette.background.paper,
    },
    oddRow: {
      backgroundColor: theme.palette.grey[300],
    },
    evenRowPast: {
      backgroundColor: green[50],
      color: blueGrey[500],
    },
    oddRowPast: {
      backgroundColor: green[200],
      color: blueGrey[300],
    },
    table: {
      border: '1px',
      borderColor: theme.palette.primary.light,
      borderStyle: 'solid',
      tableLayout: 'fixed',
      borderCollapse: 'collapse',
    },
  }),
);

export const PriceTable = ({
  headerRow,
  contentRows,
  skipColumns = [],
  title,
  bgColour,
  colour,
}: {
  headerRow: string[];
  contentRows: TableContentRow[];
  skipColumns?: number[];
  title: string;
  bgColour?: string;
  colour?: string;
}): JSX.Element => {
  const classes = useStyles({ bgColour, colour });

  return (
    <Table className={classes.table} size='small' aria-label='a dense table' padding='none' stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell className={classes.rowHeader} align='center' colSpan={headerRow.length}>
            {title}
          </TableCell>
        </TableRow>
        <TableRow>
          {headerRow.map((cell: string, key: number) => (
            <TableCell className={classes.rowHeader} key={key} align='center'>
              {cell}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {contentRows
          .map(row => row.filter((cell, i) => !skipColumns.includes(i)))
          .map((row: TableContentRow, rowKey: number) => {
            const isPast = !['PRS', 'NRS'].includes(row[1].toString().substring(0, 3));
            return (
              <TableRow className={rowKey % 2 === 0 ? (isPast ? classes.evenRowPast : classes.evenRow) : isPast ? classes.oddRowPast : classes.oddRow} key={rowKey}>
                {row.map((cell: TableContentCell, key: number) => (
                  <TableCell className={classes.cell} key={key} align={'right'}>
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};
