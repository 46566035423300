import { Action, configureStore, ThunkAction, CaseReducerActions, SliceCaseReducers } from '@reduxjs/toolkit';
import { socketMiddleware } from './middleware/socket';
import { rootReducer, RootState } from './reducer';
import { RouterAction, routerMiddleware } from 'connected-react-router';
import { ListenPayload, SocketActions, SocketAsyncActions } from './middleware/socket/actions';
import type { AllSliceActions } from './slice';
import { history } from './reducer';
import { ServiceBusActions } from './middleware/servicebus/actions';

export type { RootState };

export type Actions<T extends CaseReducerActions<SliceCaseReducers<RootState>>> = ReturnType<T[keyof T]>;

// type CleanState<T> = T extends CombinedState<infer S> ? { [K in keyof S]: CleanState<S[K]> } : T;

const middleware = [socketMiddleware(), routerMiddleware(history)];

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['enqueueSnack', 'closeSnackbar', 'removeSnackbar', 'app/sendSnack', 'app/snackConfirm', 'app/snackInfo', 'app/snackError', 'app/snackWarning'],
      },
    }).concat(...middleware),
});

export type StoreType = typeof store;

export interface CustomSocketDispatch<State = RootState, ExtraThunkArg = void, BasicAction extends Action = Action> {
  /** Accepts a thunk function, runs it, and returns whatever the thunk itself returns */
  <ReturnType>(thunkAction: ThunkAction<ReturnType, State, ExtraThunkArg, BasicAction>): ReturnType;
  /** Custom dispatch function for socket middleware, returns a promise from the middleware */
  <ReturnType>(action: SocketAsyncActions): Promise<ReturnType>;
  /** Accepts a standard action object, and returns that action object */
  <Action extends BasicAction>(action: Action): Action;
  /** A union of the other two overloads for TS inference purposes */
  <ReturnType, Action extends BasicAction>(action: Action | ThunkAction<ReturnType, State, ExtraThunkArg, BasicAction>): Action | ReturnType;
}

export type SocketDispatch = CustomSocketDispatch<RootState, undefined, SocketAsyncActions | SocketActions | ServiceBusActions | AllSliceActions | { type: 'WS_LISTEN'; payload: ListenPayload } | RouterAction>;
// export type RootState = ReturnType<typeof store.getState>;
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
// export type AppDispatch = Dispatch<Action | ThunkAction>;
export type DispatchReducer<TPayload> = (payload: TPayload[] | string) => { payload: TPayload[] | string; type: string; meta?: string };
