import React from 'react';
import { HeadersYProps } from '../eod.types';
import clsx from 'clsx';
import { useAppSelector } from 'redux/hooks';
import { EODRow } from './EODRow';
import { RowLabel } from '../AlphaEOD';
import { useStyles } from './useStyles';
import { HeaderBlock } from './HeaderBlock';

export const HeadersY = ({ settleDate, labels, data }: HeadersYProps) => {
  const classes = useStyles();
  const { matchMode, simpleView } = useAppSelector(state => state.broking);
  const columns = !matchMode ? 8 : !simpleView ? 8 : 3;
  return (
    <>
      <thead>
        <tr>
          <th colSpan={2} rowSpan={2} className='date-cell'>
            <b>{settleDate}</b>
          </th>
          <th className={clsx('spacer', { [classes.wideSpacer]: matchMode && simpleView })}></th>
          <th colSpan={columns} className='purple-header'>
            <b>OTA</b>
          </th>
          <th className={clsx('spacer', { [classes.wideSpacer]: matchMode && simpleView })}></th>

          <th colSpan={columns} className='purple-header'>
            <b>BEN</b>
          </th>
          <th className={clsx('spacer', { [classes.wideSpacer]: matchMode && simpleView })}></th>

          <th colSpan={columns} className='purple-header'>
            <b>BASIS</b>
          </th>
        </tr>
        <tr>
          <th className={clsx('spacer', { [classes.wideSpacer]: matchMode && simpleView })}></th>
          <HeaderBlock />
          <th className={clsx('spacer', { [classes.wideSpacer]: matchMode && simpleView })}></th>
          <HeaderBlock />
          <th className={clsx('spacer', { [classes.wideSpacer]: matchMode && simpleView })}></th>
          <HeaderBlock />
        </tr>
      </thead>
      {labels.map(([section, labels]) => (
        <tbody key={section} className={section}>
          {labels.map((label, i, arr) => {
            return (
              <tr key={`${label}-row`} className={'highlight'}>
                {i === 0 && (
                  <th rowSpan={arr.length} className={clsx(classes.section, 'section')}>
                    <b>
                      <span>{`${section.toUpperCase()}S`}</span>
                    </b>
                  </th>
                )}
                <RowLabel>{label}</RowLabel>
                <td className={clsx('spacer', { [classes.wideSpacer]: matchMode && simpleView })}></td>
                <EODRow {...data[section][label].ota} />
                <td className={clsx('spacer', { [classes.wideSpacer]: matchMode && simpleView })}></td>
                <EODRow {...data[section][label].ben} />
                <td className={clsx('spacer', { [classes.wideSpacer]: matchMode && simpleView })}></td>
                <EODRow {...data[section][label].basis} />
              </tr>
            );
          })}
        </tbody>
      ))}
    </>
  );
};
