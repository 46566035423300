import React from 'react';
import { XGrid, GridColDef, GridSortDirection } from '@material-ui/x-grid';
import { gridStyles } from '../../datagrid.style';
import { ConfirmToolbar } from './ConfirmToolbar';
import { ModalComponentProps } from '../modal.types';
import { TradeConfirmDisplay } from './TradeConfirmDisplay';
import { DealpadLocationState, DealpadType } from 'types/Ticket.types';
import { useLocation } from 'react-router-dom';

type FieldMap = { [K in DealpadType]: string[] };

export const getDealpadState = (state: DealpadLocationState): DealpadType => state?.dealpad || 'gas';

const commonFields = ['ID', 'Time', 'Product', 'Volume', 'Price', 'confo_Sell Trader', 'hide_SEntity', 'hide_BEntity', 'confo_Buy Trader', 'hide_Sell ID', 'hide_Buy ID'];

const confirmFields: FieldMap = {
  electricity_futures: [...commonFields, 'Option', 'Buy Account', 'Sell Account', 'Code'],
  carbon: [...commonFields, 'Payment Date', 'Delivery Date', 'Venue #'],
  electricity_efp: ['ID', 'Time', 'Product', 'Start Date', 'End Date', 'Volume', 'Price', 'Days', 'Hours', 'Total Volume', 'confo_Sell Trader', 'hide_SEntity'],
  electricity_otc: [...commonFields, 'Start Date', 'End Date', 'Days', 'Hours', 'Notional', 'Load type', 'Reference Node', 'Venue #'],
  gas: [...commonFields, 'Start Date', 'End Date', 'Duration', 'Total Volume', 'Venue #'],
  electricity_ftr: [...commonFields, 'Start Date', 'End Date', 'Duration', 'Total Volume', 'Venue #'],
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ConfirmScreen = (admin = false) =>
  function (props: ModalComponentProps): JSX.Element {
    const gridClasses = gridStyles();
    const { state } = useLocation<DealpadLocationState>();
    const dealpadType = getDealpadState(state);

    const { closeModal, rows, columns } = props;
    const [confoID, setConfoID] = React.useState<number[]>();

    const rowValues = Array.from(rows.values());

    // filters columns down to required
    const newColumns = columns.reduce<GridColDef[]>((prev: GridColDef[], curr: GridColDef) => {
      return confirmFields[dealpadType].includes(curr.field) ? [...prev, { ...curr, resizable: false, align: 'center', hide: false }] : prev;
    }, []);
    return confoID ? (
      <TradeConfirmDisplay trades={confoID} closeModal={closeModal} />
    ) : newColumns.length > 0 ? (
      <XGrid
        columns={newColumns}
        hideFooterPagination={false}
        rows={rowValues}
        autoPageSize
        checkboxSelection
        pagination
        sortModel={[
          {
            field: 'ID',
            sort: 'desc' as GridSortDirection,
          },
        ]}
        className={gridClasses.modalRoot}
        components={{
          Toolbar: ConfirmToolbar(admin, closeModal, setConfoID),
        }}
      />
    ) : (
      <></>
    );
  };
export { ConfirmScreen };
