import { createSelector } from '@reduxjs/toolkit';
import { getContractsByNode, makeDurationObject } from 'pages/ae/match/context/util';
import { RootState } from '../../store';

const getMatchDefinitions = (state: RootState) => state.match.contractDefinitions;
const getMatchGroups = (state: RootState) => state.match.contractGroups;
const getMatchContractsState = (state: RootState) => state.match.contractState;
// Other code such as selectors can use the imported `RootState` type

export const matchDefinitionsSelector = createSelector(getMatchDefinitions, getMatchGroups, (contractDefinitions, contractGroups) => ({
  definitions: {
    ben: makeDurationObject('ben', getContractsByNode('ben', contractDefinitions)),
    ota: makeDurationObject('ota', getContractsByNode('ota', contractDefinitions)),
    // ben: { month: getContractByDuration('month', definitionsByNode.ben), quarter: getContractByDuration('quarter', definitionsByNode.ben) },
    // ota: { month: getContractByDuration('month', definitionsByNode.ota), quarter: getContractByDuration('quarter', definitionsByNode.ota) },
  },
  syntheticGroups: contractGroups.filter(group => group !== 'month' && group !== 'quarter' && group !== 'year'),
}));

export const matchContractsStateSelector = createSelector(
  getMatchContractsState,
  (state: RootState, contractId: number) => contractId,
  (contractState, contractId) => contractState[contractId],
);

export const matchContractDefinition = createSelector(
  getMatchDefinitions,
  (state: RootState, definitionId: number) => definitionId,
  (definition, definitionId) => definition.find(def => def.ID === definitionId),
);
