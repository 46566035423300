import Highcharts from 'highcharts';
import React from 'react';
import { AEChart } from 'components/Charts/AEChart';

export const MonthlyBrokerageChart = ({ table }: { table: string }): JSX.Element => {
  const monthlyChartOptions: Highcharts.Options = React.useMemo(
    () => ({
      chart: {
        type: 'column',
      },
      tooltip: {
        formatter: function () {
          return `Month Total: <b>${this.total}</b><br/>`;
        },
      },
      xAxis: {
        type: 'category',
      },
      legend: {
        enabled: false,
      },
      yAxis: [
        {
          title: {
            text: null,
          },
          labels: {
            formatter: function () {
              if (typeof this.value === 'string') return this.value;
              if (this.value > 1000) return '$' + Highcharts.numberFormat(this.value / 1000, 0) + 'k'; //  only switch if > 1000
              return '$' + Highcharts.numberFormat(this.value, 0);
            },
          },
          min: 0,
        },
      ],
      plotOptions: {
        column: {
          stacking: 'normal',
          groupPadding: 0.1,
        },
      },
    }),
    [],
  );

  return <AEChart schema={'ae'} table={table} baseOptions={monthlyChartOptions} />;
};
