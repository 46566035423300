import { makeStyles, Theme, createStyles, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, InputLabel, Select, MenuItem, ButtonGroup, Button } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useSP } from 'modules/websocket/hooks';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FormControlPCP } from 'components/styled';
import { WITSNode } from 'redux/slice/metadata';
import { useAppSelector } from 'redux/hooks';
import { LoadType } from 'types/electricity.types';
import { SP } from 'types/pantheon/PantheonSocket';

export type TableContentCell = string | number;

export type TableContentRow = TableContentCell[];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowHeader: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      padding: '0px 0px 0px 16px',
      fontWeight: 'bold',
    },
    cell: {
      padding: '0px 0px 0px 16px',
    },
    evenRow: {
      backgroundColor: theme.palette.background.paper,
    },
    oddRow: {
      backgroundColor: theme.palette.grey[300],
    },
    formControl: {
      margin: theme.spacing(1),
      textAlign: 'left',
    },
    table: {
      border: '1px',
      borderColor: theme.palette.primary.light,
      borderStyle: 'solid',
    },
    inputRow: {
      display: 'flex',
      flexDirection: 'row',
      padding: 4,
      gap: '8px',
    },
    inputColumn: {
      display: 'flex',
      placeContent: 'space-evenly',
      flexDirection: 'column',
      padding: 4,
      gap: '8px',
    },
  }),
);

interface ElecData {
  date: Date;
  node: string;
  price: number;
  runType: string;
}

export enum ProductType {
  witsNodes,
  witsBasisNodes,
}

export type ProductTypeKeys = keyof typeof ProductType;

export const ElecTable = ({ duration, type }: { duration: 'month' | 'quarter'; type: ProductType }): JSX.Element => {
  const classes = useStyles();
  const { loading, electricity } = useAppSelector(state => ({ loading: !state.metadata.status.loaded, electricity: state.metadata.electricity }));

  const emit = useSP({ noSuccess: true });

  const [headerRow] = React.useState<string[]>(['Date', 'Day Avg', 'Type', 'Avg to date']);
  const [contentRows, setContent] = React.useState<TableContentRow[]>([]);

  const [selectedDate, setSelectedDate] = React.useState<MaterialUiPickersDate>(moment().startOf('month'));

  const [nodeType, setNodeType] = React.useState(type);
  const [nodeOptions, setNodeOptions] = React.useState<WITSNode[]>([]);
  const [node, setNode] = React.useState<string>('');
  const [loadType, setLoadType] = React.useState<LoadType>('BASE');

  React.useEffect(() => {
    if (!loading) {
      const options = electricity[ProductType[nodeType] as ProductTypeKeys];
      setNodeOptions([...options]);
      setNode(options[0].nodeFull);
    }
  }, [electricity, nodeType, loading]);

  React.useEffect(() => {
    if (node.length === 0 || !selectedDate) return;
    const start = moment(selectedDate);
    if (duration === 'quarter') start.subtract(2, 'month');
    const end = moment(selectedDate).endOf(duration);

    const sp = nodeType === ProductType.witsNodes ? 'electricityRunningAverage' : 'electricityBasisRunningAverage';
    const params = [loadType, ...(nodeType === 0 ? [node] : node.split(' / ')), start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')];

    const req: SP.Request<string[]> = {
      type: 'SP_REQUEST',
      method: 'execute',
      procedure: {
        sp,
        params,
        database: 'datacore',
      },
    };
    emit<[ElecData[]]>(req, response => {
      if (!response?.message || !Array.isArray(response.message)) return;
      setContent(
        response.message[0].map((value: ElecData) => {
          return Object.values(value)
            .filter((v: unknown, i: number) => i !== 1)
            .map((v: unknown, i: number) => (headerRow[i] === 'Date' ? moment(v as Date).format('DD-MM-YY') : (v as string)));
        }),
      );
    });
  }, [selectedDate, node, loadType]);

  const handleDateChange = (date: MaterialUiPickersDate | null) => {
    if (date && duration === 'quarter') {
      const newDate = moment(date).set('month', Math.ceil((date.month() + 1) / 3) * 3 - 1);
      setSelectedDate(newDate);
    } else setSelectedDate(date);
  };
  const handleNodeChange = (event: React.ChangeEvent<{ value: unknown }>) => setNode(event.target.value as string);
  const changeNodeType = (value: number) => () => setNodeType(value);

  const changeLoadType = (value: LoadType) => () => setLoadType(value);

  return (
    <TableContainer>
      <div className={classes.inputRow}>
        <div className={classes.inputColumn}>
          <ButtonGroup size={'small'} orientation='vertical' color='primary' variant='contained'>
            <Button variant={nodeType === ProductType.witsNodes ? 'contained' : 'outlined'} onClick={changeNodeType(ProductType.witsNodes)}>
              GXP/GIP
            </Button>
            <Button variant={nodeType === ProductType.witsBasisNodes ? 'contained' : 'outlined'} onClick={changeNodeType(ProductType.witsBasisNodes)}>
              Basis
            </Button>
          </ButtonGroup>
          <ButtonGroup size={'small'} orientation='vertical' color='primary' variant='contained'>
            <Button variant={loadType === 'BASE' ? 'contained' : 'outlined'} onClick={changeLoadType('BASE')}>
              Base
            </Button>
            <Button disabled variant={loadType === 'PEAK' ? 'contained' : 'outlined'} onClick={changeLoadType('PEAK')}>
              Peak
            </Button>
          </ButtonGroup>
        </div>
        <div className={classes.inputColumn}>
          <FormControlPCP>
            <InputLabel id={`node-label`}>Node</InputLabel>
            <Select labelId={`node-label`} id={`node`} value={node} label='Node' onChange={handleNodeChange}>
              {nodeOptions.map((option: WITSNode) => (
                <MenuItem value={option.nodeFull} key={option.nodeFull}>
                  {option.nodeName}
                </MenuItem>
              ))}
            </Select>
          </FormControlPCP>

          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              className={classes.formControl}
              autoOk
              disableFuture
              size={'small'}
              inputVariant='outlined'
              variant='dialog'
              openTo='month'
              views={['year', 'month']}
              label='Year and Month'
              value={selectedDate}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      <Table className={classes.table} size='small' aria-label='a dense table' padding='none' stickyHeader>
        <TableHead>
          <TableRow>
            {headerRow.map((cell: string, key: number) => (
              <TableCell className={classes.rowHeader} key={key} align='left'>
                {cell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {contentRows.map((row: TableContentRow, rowKey: number) => (
            <TableRow className={rowKey % 2 === 0 ? classes.evenRow : classes.oddRow} key={rowKey}>
              {row.map((cell: TableContentCell, key: number) => (
                <TableCell className={classes.cell} key={key} align='left'>
                  {typeof cell === 'number' ? cell.toFixed(2) : cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
