import { createReducer, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { StoredNotification } from 'types/pantheon/pantheon.types';
import { ReceivedPayload, wsActions } from '../middleware/socket/actions';

export interface SocketStore {
  connected: boolean;
  ready: boolean;
  rooms: string[];
  listeners: string[];
  incoming: PayloadAction<ReceivedPayload, string> | null;
  notifications: StoredNotification[];
}

const initialState: SocketStore = {
  connected: false,
  ready: false,
  rooms: [],
  listeners: [],
  incoming: null,
  notifications: [],
};

export const socketReducer = createReducer(initialState, builder => {
  builder
    .addCase(wsActions.WS_CONNECTED, state => {
      state.connected = true;
    })
    .addCase(wsActions.WS_READY, state => {
      state.ready = true;
    })
    .addCase(wsActions.WS_DISCONNECTED, state => {
      state.connected = false;
      state.ready = false;
    })
    .addCase(wsActions.WS_JOIN, (state, action) => {
      state.rooms.push(action.payload);
    })
    .addCase(wsActions.WS_LEAVE, (state, action) => {
      state.rooms = state.rooms.filter(room => room !== action.payload);
    })
    .addCase(wsActions.WS_LISTEN, (state, action) => {
      if (action.payload.toState === 'on') state.listeners.push(action.payload.channel);
      if (action.payload.toState === 'off') state.listeners.splice(state.listeners.indexOf(action.payload.channel), 1);
    })
    .addCase(wsActions.WS_ON, (state, action) => {
      state.incoming = action;
    })
    .addCase(wsActions.WS_NOTIFY, (state, action) => {
      state.notifications.unshift({ ...action.payload, ts: Date.now() });
    })
    .addCase(wsActions.WS_PURGE_INCOMING, state => {
      state.incoming = null;
    });
});

export type SocketActions = PayloadAction<typeof wsActions[keyof typeof wsActions]>;
export const socket: Reducer<SocketStore, SocketActions> = socketReducer;
