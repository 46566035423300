import React from 'react';

import { Page, Section } from 'components/Wrapper';
import { useAPI } from 'modules/websocket/hooks';
import { API } from 'types/pantheon/PantheonSocket';

type Threshold = 'unit' | 'percent';

interface AlertSource {
  name: string;
  points: number[];
}

interface AlertDefiniton {
  ID: number;
  alertSource: AlertSource;
  alertFrequency: number;
  alertThreshold: number;
  threshholdType: Threshold;
  alertEmail: string[];
  alertSMS: string[];
}
function Home(): JSX.Element {
  const emit = useAPI();

  const [sources, setSources] = React.useState<API.GetResponse<AlertSource>>();
  const [definitions, setDefinitons] = React.useState<API.GetResponse<AlertDefiniton>>();

  React.useEffect(() => {
    emit<AlertDefiniton>(
      {
        type: 'API_REQUEST',
        method: 'get',
        schema: 'janus',
        table: 'alertDefinition',
      },
      setDefinitons,
    );

    emit<AlertSource>(
      {
        type: 'API_REQUEST',
        method: 'get',
        schema: 'janus',
        table: 'alertSource',
      },
      setSources,
    );
  }, []);

  return (
    <Page title='Gas Alert Interface'>
      <Section colSpan={2} rowSpan={2} header={'Configured Alerts'}>
        <ul>
          {definitions?.message?.map((definition: AlertDefiniton) => (
            <li key={definition.ID}>{JSON.stringify(definition, null, 1)}</li>
          ))}
        </ul>
      </Section>
      <Section colSpan={1} rowSpan={2} header={'Available sources'}>
        <ul>
          {sources?.message?.map((source: AlertSource) => (
            <li key={source.name}>
              {source.name} - {source.points.toString()}
            </li>
          ))}
        </ul>
      </Section>
      <Section colSpan={1} rowSpan={4} header={'Alert History'}>
        alerts
      </Section>
      <Section colSpan={3} rowSpan={2} header={'Not sure yet'}>
        new alert dialog
      </Section>
    </Page>
  );
}

export { Home };
