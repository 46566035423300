import { makeStyles, Theme, createStyles, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Input } from '@material-ui/core';
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { matchDefinitionsSelector } from 'redux/slice/match/selectors';
import { getUniqueNames } from './context/util';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    formControl: {
      margin: 0,
      textAlign: 'left',
    },
    rowHeader: {
      border: '1px solid black',
      padding: '0px 16px',
      fontWeight: 'bold',
    },
    textCell: {
      padding: '0px 8px 0px 8px',
      border: '1px solid black',
    },
    tableContainer: {
      paddingBottom: 10,
      overflow: 'hidden',
    },
    inputCell: {
      border: '1px solid black',
      padding: 0,
    },
    inputBox: {
      '& .MuiInputBase-input': {
        padding: 0,
      },
      '&::before': {
        borderBottom: '0px',
      },
      '&::after': {
        borderBottom: '0px',
      },
    },
    table: {
      tableLayout: 'fixed',
      '& .MuiTableCell-root': {
        fontFamily: '"Segoe UI", sans-serif',
      },
    },
  }),
);

export const MatchSubmitter = (): JSX.Element => {
  const classes = useStyles();
  const { definitions } = useAppSelector(state => matchDefinitionsSelector(state));

  const contractCount = getUniqueNames([...definitions.ben.month, ...definitions.ota.month, ...definitions.ben.quarter, ...definitions.ota.quarter, ...definitions.ben.year, ...definitions.ota.year]);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow style={{ height: 24 }}>
            <TableCell className={classes.rowHeader} align='center' colSpan={2}>
              OTA
            </TableCell>
            <TableCell className={classes.rowHeader} align='center' colSpan={2}>
              BEN
            </TableCell>
            <TableCell className={classes.rowHeader} align='center' colSpan={2}>
              BASIS
            </TableCell>
          </TableRow>
          <TableRow style={{ height: 24 }}>
            <TableCell className={classes.rowHeader} align='center'>
              BID
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              OFFER
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              BID
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              OFFER
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              BID
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              OFFER
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contractCount.map(contract => (
            <TableRow key={contract} style={{ height: 24 }}>
              <TableCell className={classes.inputCell} align='center'>
                <Input className={classes.inputBox} id='reduction-high' type={'number'} />
              </TableCell>
              <TableCell className={classes.inputCell} align='center'></TableCell>
              <TableCell className={classes.inputCell} align='center'></TableCell>
              <TableCell className={classes.inputCell} align='center'></TableCell>
              <TableCell className={classes.inputCell} align='center'></TableCell>
              <TableCell className={classes.inputCell} align='center'></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
