/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Action, ActionCreatorWithPayload, createAction, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { SessionPayload } from './payloads';

// define actions here
export enum ServiceBusAction {
  SB_STARTED,
  SB_START,
  SB_START_SESSION,
  SB_SESSION_STARTED,
  SB_CLIENT,
}

export type ServiceBusActionType = keyof typeof ServiceBusAction;

// eslint-disable-next-line @typescript-eslint/ban-types

export type ServiceBusActions = Action<'SB_START'> | PayloadAction<string, 'SB_STARTED'> | PayloadAction<[string, string], 'SB_START_SESSION'> | PayloadAction<string, 'SB_SESSION_STARTED'>;

export const sbActions = {
  SB_STARTED: createAction<string, 'SB_STARTED'>('SB_STARTED'),
  SB_START: createAction('SB_START'),
  SB_START_SESSION: createAction<[string, string], 'SB_START_SESSION'>('SB_START_SESSION'),
  SB_SESSION_STARTED: createAction<string, 'SB_SESSION_STARTED'>('SB_SESSION_STARTED'),
};
