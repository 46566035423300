import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Page, Section } from 'components/Wrapper';
import { DataList } from './DataList';
import clsx from 'clsx';
import { useDataTable } from './hooks/useDataTable';
import { ItemSection } from 'components/Wrapper/ItemSection';

// TODO fix theme so background color is set there
const useStyles = makeStyles(() =>
  createStyles({
    noPadding: {
      padding: 0,
    },
    section: {
      width: '-webkit-fill-available',
      height: '-webkit-fill-available',
    },
  }),
);

export function Extractor(): JSX.Element {
  const classes = useStyles();
  const [DataTable, setQuery] = useDataTable();

  return (
    <Page title={'AE Datacore extraction interface'} rows={'1fr'} columns={'1fr 3fr'} columnCount={4}>
      <ItemSection childClassName={clsx(classes.section)} className={clsx(classes.noPadding)} elevation={0}>
        <DataList setTableQuery={setQuery} />
      </ItemSection>
      <Section scroll colSpan={3} header={'Data view'} childClassName={classes.section}>
        <DataTable />
      </Section>
    </Page>
  );
}
