import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { EODPayload, ASXSnapshot, Section } from 'pages/ae/alpha/eod/eod.types';

// type MatchVolume = {
//   [K in Section]: {
//     [contract: string]: number | null;
//   };
// };

export type ContractVolume = { bid?: number; ask?: number };
export type UserMatchVolume = Record<string, ContractVolume>;

export type MatchVolumeUpdate = {};
// Define a type for the slice state
export interface BrokingStore {
  asxEOD: Record<number, ASXSnapshot>;
  latestSnapshot: number;
  matchMode: boolean;
  simpleView: boolean;
  matchVolumeStep: number;
  matchVolume: UserMatchVolume;
  pendingVolume: UserMatchVolume;
}

// Define the initial state using that type
const initialState: BrokingStore = { asxEOD: {}, latestSnapshot: 0, matchVolume: {}, matchMode: false, matchVolumeStep: 1, simpleView: false, pendingVolume: {} };

export const brokingSlice = createSlice({
  name: 'brokingSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setASXEOD: (state, action: PayloadAction<EODPayload[]>) => {
      action.payload.map(item => {
        return (state.asxEOD[item.settleDate] = item.eodData);
      });

      const newLatest = action.payload.reduce<number>((prev: number, curr: EODPayload) => {
        return Math.max(prev, curr.settleDate);
      }, 0);

      if (state.latestSnapshot < newLatest) {
        state.latestSnapshot = newLatest;
      }
    },

    setMatchMode: (state, action: PayloadAction<boolean>) => {
      state.matchMode = action.payload;
    },
    setVolumeStep: (state, action: PayloadAction<number>) => {
      state.matchVolumeStep = action.payload;
    },
    enableSimpleView: (state, action: PayloadAction<boolean>) => {
      state.simpleView = action.payload;
    },
    hydrateVolume: (state, action: PayloadAction<UserMatchVolume>) => {
      state.matchVolume = action.payload;
    },
    setMatchVolume: (state, action: PayloadAction<{ contract: string; side: 'bid' | 'ask'; volume: number }>) => {
      const contract = state.pendingVolume[action.payload.contract];
      if (!contract) state.pendingVolume[action.payload.contract] = {};

      if (state.matchVolume[action.payload.contract]?.[action.payload.side] === undefined && action.payload.volume <= 0) {
        delete state.pendingVolume[action.payload.contract];
      } else {
        state.pendingVolume[action.payload.contract][action.payload.side] = action.payload.volume;
      }
    },
  },
});

export type BrokingActions = typeof brokingSlice.actions[keyof typeof brokingSlice.actions];

export const { actions } = brokingSlice;
export const broking: Reducer<BrokingStore, BrokingActions> = brokingSlice.reducer;
