import React from 'react';
import { theme } from 'style/theme';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useAPI } from 'modules/websocket/hooks';
import { useSnackSend } from 'hooks/useSnackSend';

type APIChartProps = {
  schema: string;
  table: string;
  baseOptions: Highcharts.Options;
};

const AEChart = (props: APIChartProps): JSX.Element => {
  const { schema, table, baseOptions } = props;
  const [options, setOptions] = React.useState<Highcharts.Options>(baseOptions);

  const emit = useAPI();
  const { sendSnack } = useSnackSend();

  React.useEffect(() => {
    emit<{ chartOptions: Highcharts.Options }>(
      {
        type: 'API_REQUEST',
        method: 'get',
        schema,
        table,
      },
      response => {
        if (!response?.message || response.message.length !== 1) return;
        const { title, xAxis, series } = response.message[0].chartOptions;
        if (!title || !xAxis || !series) return sendSnack(`Chart response data was incomplete (title: ${Boolean(title)}, xAxis: ${Boolean(xAxis)}, series: ${Boolean(series)})`, 'error');

        const chartOptions: Highcharts.Options = {
          ...baseOptions,
          title,
          xAxis: {
            ...baseOptions.xAxis,
            ...xAxis,
          },
          series,
        };

        setOptions(chartOptions);
      },
    );
  }, []);

  return <HighchartsReact containerProps={{ style: { display: 'flex', borderRadius: theme.shape.borderRadius, height: '100%', width: '100%' } }} highcharts={Highcharts} constructorType={'chart'} options={options} />;
};

export { AEChart };
