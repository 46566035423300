import React, { PropsWithChildren } from 'react';
import { CSSProperties } from 'react';
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';

const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  margin: 8,
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: '#989898',
  borderStyle: 'dashed',
  placeContent: 'center',
  backgroundColor: '#fafafa',
  minWidth: '25%',
  color: '#ababab',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export interface FileDropZoneProps extends Omit<DropzoneOptions, 'onDropRejected'> {
  onError?: (error?: string) => void;
  borderColor?: string;
  backgroundColor?: string;
  color?: string;
}

export const FileDropZone = ({ children, borderColor, backgroundColor, color, onError, onDropAccepted, ...dropProps }: PropsWithChildren<FileDropZoneProps>): JSX.Element => {
  const onDropRejected = (fileRejections: FileRejection[]) => {
    if (!onError) return;

    const errors = fileRejections
      .map(rejection => rejection.errors)
      .flat()
      .map(err => err.message);
    onError(errors.join(', '));
  };

  const handleAccepted = (files: File[], event: DropEvent) => {
    onError && onError();
    onDropAccepted && onDropAccepted(files, event);
  };
  const { getRootProps, getInputProps, isDragActive, fileRejections, acceptedFiles } = useDropzone({ onDropRejected, onDropAccepted: handleAccepted, ...dropProps });
  const colours = { borderColor, backgroundColor, color };
  const rejectAccept = (acceptedFiles: File[], fileRejections: FileRejection[]) => (fileRejections.length > 0 ? rejectStyle : acceptedFiles.length > 0 ? acceptStyle : {});

  const style: CSSProperties = React.useMemo(
    () => ({
      ...baseStyle,
      ...colours,
      ...(isDragActive ? activeStyle : rejectAccept(acceptedFiles, fileRejections)),
    }),
    [isDragActive, acceptedFiles, fileRejections],
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};
