import { createSelector } from 'reselect';
import { ASXSnapshot, ordering, Section } from 'pages/ae/alpha/eod/eod.types';
import { RootState } from 'redux/store';

const getEODSnapshots = (state: RootState) => state.broking.asxEOD;
export const hasPending = (state: RootState) => Object.values(state.broking.pendingVolume).length > 0;
export const pendingVolume = (state: RootState) => state.broking.pendingVolume;

// Other code such as selectors can use the imported `RootState` type
export const latestSnapshotSelector = createSelector(getEODSnapshots, (snapshots: Record<number, ASXSnapshot>): [ASXSnapshot | undefined, number, string] => {
  const latest = Math.max(0, ...Object.keys(snapshots).map(ts => parseInt(ts)));
  return [snapshots[latest], latest, new Date(latest).toLocaleDateString()];
});

export const isPending = createSelector(
  pendingVolume,
  (state: RootState, contract: string, side: 'bid' | 'ask') => ({ contract, side }),
  (pendingVolume, { contract, side }: { contract: string; side: 'bid' | 'ask' }) => {
    return pendingVolume[contract]?.[side] !== undefined;
  },
);

export const makeIsPendingSelector = () =>
  createSelector(
    pendingVolume,
    (state: RootState, contract: string, side: 'bid' | 'ask') => ({ contract, side }),
    (pendingVolume, { contract, side }: { contract: string; side: 'bid' | 'ask' }) => {
      return pendingVolume[contract]?.[side] !== undefined;
    },
  );

export const getEODLabels = createSelector(latestSnapshotSelector, (snapshot: [ASXSnapshot | undefined, number, string]): [Section, string[]][] => {
  const [data] = snapshot;
  if (!data) return [];

  return Object.entries(data)
    .sort((a, b) => (ordering[a[0] as Section] < ordering[b[0] as Section] ? -1 : 1))
    .map(([section, data]) => {
      return [
        section as Section,
        Object.entries(data)
          .sort((a, b) => (new Date(a[1].date).getTime() > new Date(b[1].date).getTime() ? 1 : -1))
          .map(([label]) => label),
      ];
    });
});
