import { createAsyncThunk, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { wsAsyncActions } from '../../middleware/socket/actions';
import { API, SP } from 'types/pantheon/PantheonSocket';
import { Actions, RootState, SocketDispatch } from '../../store';
import { DataSetDefinition, ReferenceData, WITSNode, WITSRun } from './types';
import { PortalStore } from 'types/sql/ae.portal.types';

// Define a type for the slice state
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MetadataKeylist {}

export interface MetadataStore extends ReferenceData {
  status: { loaded: boolean };
  ticketMeta: PortalStore;
}

type ThunkAPIConfig = {
  state: RootState;
  dispatch: SocketDispatch;
};

// First, create the thunk
export const fetchReferenceData = createAsyncThunk<void, void, ThunkAPIConfig>('metadata/fetchReferenceData', async (_, thunkAPI) => {
  // const parsedData = await Promise.all(
  const request: SP.Request = {
    type: 'SP_REQUEST',
    method: 'execute',
    procedure: {
      sp: 'portalReferenceData',
      database: 'datacore',
    },
  };

  await thunkAPI
    .dispatch<SP.Response<[WITSNode[], WITSNode[], WITSRun[], DataSetDefinition[]]>>(wsAsyncActions.WS_SP(request))
    .then(response => {
      if (response?.message && Array.isArray(response.message)) {
        const [witsNodes, witsBasisNodes, witsRunTypes, datasets] = response.message;
        thunkAPI.dispatch(metadataSlice.actions.setReferenceMeta({ electricity: { witsNodes, witsBasisNodes, witsRunTypes }, datasets }));
      }
    })
    .catch(error => console.error(error));
});

// First, create the thunk
export const fetchDrawerItems = createAsyncThunk<void, void, ThunkAPIConfig>('app/fetchDrawerItems', async (_, thunkAPI) => {
  // const parsedData = await Promise.all(
  const request: SP.Request = {
    type: 'SP_REQUEST',
    method: 'execute',
    procedure: {
      sp: 'portalReferenceData',
      database: 'datacore',
    },
  };

  await thunkAPI
    .dispatch<SP.Response<[WITSNode[], WITSNode[], WITSRun[], DataSetDefinition[]]>>(wsAsyncActions.WS_SP(request))
    .then(response => {
      if (response?.message && Array.isArray(response.message)) {
        const [witsNodes, witsBasisNodes, witsRunTypes, datasets] = response.message;
        thunkAPI.dispatch(metadataSlice.actions.setReferenceMeta({ electricity: { witsNodes, witsBasisNodes, witsRunTypes }, datasets }));
      }
    })
    .catch(error => console.error(error));
});

// Define the initial state using that type
const initialState: MetadataStore = {
  status: { loaded: false },

  electricity: { witsNodes: [], witsRunTypes: [], witsBasisNodes: [] },
  datasets: [],
  ticketMeta: {
    desk: [],
    bro_type: [],
    futures: [],
    futures_months: [],
    option_exercise_type: [],
    option_type: [],
    giveup_account: [],
    person: [],
    entity: [],
    unit: [],
    venue: [],
    brokerage: [],
    product: [],
    electricity_schedule: [],
    reference_instrument: [],
  },
};

// make API request for API data
export const makeRequest = (table: string, fields?: string[]): API.Request => ({ type: 'API_REQUEST', method: 'get', schema: 'ae', table, fields: fields || ['ID', 'Name'] });

export const metadataSlice = createSlice({
  name: 'metadata',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLoaded: state => void (state.status.loaded = true),

    setTicketMeta: (state, action: PayloadAction<Partial<PortalStore>>) => {
      state.ticketMeta = { ...state.ticketMeta, ...action.payload };
    },
    setReferenceMeta: (state, action: PayloadAction<{ electricity: { witsNodes: WITSNode[]; witsRunTypes: WITSRun[]; witsBasisNodes: WITSNode[] }; datasets: DataSetDefinition[] }>) => {
      const { electricity, datasets } = action.payload;
      state.electricity = electricity;
      state.datasets = datasets;
    },
  },
  // extraReducers: builder => {
  //   // Add reducers for additional action types here, and handle loading state as needed

  // },
});
export type MetadataActions = Actions<typeof metadataSlice.actions>;
export const { actions } = metadataSlice;
export const metadata: Reducer<MetadataStore, MetadataActions> = metadataSlice.reducer;
