/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Divider, Grid, Theme } from '@material-ui/core';
import GavelIcon from '@material-ui/icons/Gavel';
import { UberIconButton } from 'components/UberIconButton';
import { GridRowData, GridRowId, useGridSlotComponentProps } from '@material-ui/x-grid';
import { makeContractText, makeTradeLeg, TradeLeg } from '../submitlib';
import { useAppDispatch, useSocketDispatch } from 'redux/hooks';
import { wsAsyncActions } from 'redux/middleware/socket/actions';
import { actionWithSelectionHOC } from '../common';
import { appActions } from 'redux/slice/app/app';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbarRoot: {
      width: '-webkit-fill-available',
      borderWidth: `0px 0px 1px 0px`,
      borderStyle: `solid`,
      borderColor: theme.palette.divider,
      borderRadius: `0px`,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
    },
  }),
);

const SubmitToolbar = (closeModal: () => void, setGenerated: (data: string[]) => void, setID: (data: number[]) => void, admin: boolean) => (): JSX.Element => {
  const classes = useStyles();
  const { apiRef } = useGridSlotComponentProps();
  const socketDispatch = useSocketDispatch();
  const dispatch = useAppDispatch();
  const actionWithSelection = actionWithSelectionHOC(apiRef, socketDispatch);

  const generate = () => {
    const rows = apiRef.current.getSelectedRows();

    // make array of execution data and id for each leg and set SubmitScreen state
    const generatedData = Array.from(rows).reduce<{ data: string[]; id: number[] }>(
      (prev: { data: string[]; id: number[] }, trade: [GridRowId, GridRowData], i: number) => {
        const data = trade[1];
        const legData: TradeLeg = {
          ticketID: +trade[0],
          legNumber: i + 1,
          volume: data.Volume,
          contract: makeContractText(data.Code),
          code: data.Code,
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          price: `$${data.Price.toFixed(2)}`,
          buyer: data.hide_BEntity,
          buyerAccount: data['Buy Account'],
          seller: data.hide_SEntity,
          sellerAccount: data['Sell Account'],
          option: data.Option,
        };
        return { data: [...prev.data, makeTradeLeg(legData)], id: [...prev.id, +trade[0]] };
      },
      { data: [], id: [] },
    );

    setGenerated(generatedData.data);
    setID(generatedData.id);
  };

  const amendFlag = (setting: boolean) => () => {
    const rows = apiRef.current.getSelectedRows();
    socketDispatch(
      wsAsyncActions.WS_API({
        type: 'API_REQUEST',
        method: 'patch',
        schema: 'ae',
        table: 'ticket',
        id: Array.from(rows).map((trade: [GridRowId, GridRowData]) => +trade[0]),
        data: { isLodged: setting },
      }),
    )
      .then(() => closeModal())
      .catch(e => console.error(e));
  };

  return (
    <Grid container alignItems='center' classes={{ root: classes.toolbarRoot }}>
      <UberIconButton onClick={actionWithSelection(generate)} tooltip={'Generate trade lodgement email for selected trades?'} Icon={GavelIcon} />
      <Divider orientation='vertical' flexItem />
      {admin && (
        <>
          <UberIconButton
            onClick={actionWithSelection(() => dispatch(appActions.CONFIRM({ message: 'Forcibly Mark selected trades as lodged?', onConfirm: amendFlag(true) })))}
            tooltip={'Forcibly Mark selected trades as lodged'}
            Icon={GavelIcon}
          />
          <UberIconButton
            onClick={actionWithSelection(() => dispatch(appActions.CONFIRM({ message: 'Forcibly Mark selected trades as not lodged?', onConfirm: amendFlag(true) })))}
            tooltip={'Forcibly Mark selected trades as not lodged'}
            Icon={GavelIcon}
          />

          <Divider orientation='vertical' flexItem />
        </>
      )}
    </Grid>
  );
};

export { SubmitToolbar };
