import { makeStyles, Theme, createStyles, Typography, Divider, Button } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import moment from 'moment';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useSnackSend } from 'hooks/useSnackSend';
import { useRPC } from 'modules/websocket/hooks';
import { makeHTML } from '../renderToHTML';
import { ExecutePayload } from 'types/pcp.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
    leg: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
    },
  }),
);

// styled block
const Block = styled.div`
  padding: 16px 8px;
`;

const dividerStyle = {
  color: grey[500],
};

const generateLine = (line: string) => {
  const colonPosition = line.indexOf(':');

  if (colonPosition === -1) return <i>{line}</i>;
  const chunk = line.split(':');
  return (
    <>
      <b>{chunk[0]}:</b>
      <i>{chunk[1]}</i>
    </>
  );
};

const TradeExecutionDisplay = ({ legData, legID, closeModal }: { legData: string[]; legID: number[]; closeModal: (data?: unknown) => void }): JSX.Element => {
  // custom header component
  const Header = ({ children }: PropsWithChildren<unknown>) => {
    return (
      <>
        <Divider variant={'fullWidth'} style={dividerStyle} />
        <Typography
          variant={'h6'}
          component={'div'}
          style={{
            padding: 8,
            fontStyle: 'italic',
            backgroundColor: grey[200],
            color: '#303030',
          }}
        >
          {children}
        </Typography>
        <Divider variant={'fullWidth'} style={dividerStyle} />
      </>
    );
  };

  // wrapped for trade leg lines
  const PresentationLine = ({ children, line }: PropsWithChildren<{ line: number }>) => (
    <Typography component={'div'} variant={line === 0 ? 'subtitle1' : 'body2'} paragraph={line === 1} style={(line === 0 && { fontStyle: 'italic', textDecorationLine: 'underline', fontWeight: 'bolder' }) || {}}>
      {children}
    </Typography>
  );
  const classes = useStyles();
  const { sendSnack } = useSnackSend();

  const emit = useRPC();

  // email subject
  const subject = 'Aotearoa Energy Futures Block Trade to execute';

  // email body, memoised
  const emailBody = React.useMemo(
    () => (
      <div style={{ overflowY: 'auto' }} id='execution-email'>
        <Block>
          {legData.map((data: string, i: number) => (
            <div className={classes.leg} key={i}>
              {data.split('\n').map((line: string, j: number) => (
                <PresentationLine key={j} line={j}>
                  {generateLine(line)}
                </PresentationLine>
              ))}
            </div>
          ))}
        </Block>
        <Block>
          <Typography component={'div'} variant={'subtitle1'} paragraph style={{ fontWeight: 'bold', textAlign: 'center' }}>
            Trade execution email created at {moment().format('HH:mm DD/MM/YYYY')}
          </Typography>
        </Block>
      </div>
    ),
    [],
  );

  // generates standalone html for email
  // const [body] = makeStandaloneHTML(emailBody);

  // dispatches mail payload to backend for handling
  const blockAction = (action: 'send' | 'reject') => () => {
    closeModal();
    if (action === 'send') {
      const tableEl = document.getElementById(`execution-email`);
      const html = tableEl && makeHTML(tableEl);
      if (!html) sendSnack('Error generating email', 'error');
      else
        emit<ExecutePayload>({
          type: 'RPC_REQUEST',
          method: 'execute',
          procedure: 'submitExecution',
          parameters: {
            to: 'BPS', //? how to notate sender/recipients? s
            from: 'ae-fo', //? how to notate sender/recipients?
            raw: false,
            subject,
            html,
            onSent: {
              type: 'API_REQUEST',
              method: 'patch',
              schema: 'ae',
              table: 'ticket',
              id: legID,
              data: { isLodged: true },
            },
          },
        });
    }
  };

  return (
    <>
      <Header>Email metadata</Header>
      <Block>
        <Typography component={'div'} variant={'body1'}>
          <b>Subject:</b> <i>{subject}</i>
          <br />
          <b>Recipient:</b> <i>Bell Potter Securities</i>
        </Typography>
      </Block>
      <Header>Email body</Header>
      {emailBody}
      <Divider variant={'fullWidth'} style={dividerStyle} />

      <Block style={{ display: 'flex', placeContent: 'space-evenly' }}>
        <Button variant={'contained'} color={'primary'} onClick={blockAction('send')}>
          confirm block and dispatch email
        </Button>
        <Button variant={'contained'} color={'secondary'} onClick={blockAction('reject')}>
          reject block
        </Button>
      </Block>
    </>
  );
};

export { TradeExecutionDisplay };
