// import { leadingZeroPad } from '@skipnz/juno';
import moment from 'moment';
import { v4 } from 'uuid';
export type BracketType = '[]' | '{}' | '()';

export const ts = (brackets: BracketType): string => brackets[0] + moment().format('DD-MM-YYYY HH:mm:ss') + brackets[1];

export const tsPrepend = (original: string, brackets: BracketType = '[]'): string => ts(brackets) + '-' + original;

export const customZeroPad = (digits: number, source: number | string): string => (source.toString().length < digits ? `${'0'.repeat(digits - source.toString().length)} ${source}` : source).toString();

export const timerParse = (reference: number, precision?: number, noPrefix?: boolean): string => {
  const diff = moment().diff(moment(reference), 'ms');
  const absDiff = Math.abs(diff);
  const freqToPrecision = precision || 100;
  const prefix = diff > 0 || noPrefix ? '' : '-';
  const ms = absDiff % 1000;

  const subSecond = Math.floor(ms / freqToPrecision);
  const seconds = Math.floor((absDiff / 1000) % 60);
  const minutes = Math.floor((absDiff / 60000) % 60);

  const str = `${prefix}${customZeroPad(2, minutes)}:${customZeroPad(2, seconds)}`; // removed leading zero pad

  if (!precision || precision === 1) return str;
  return str + '.' + customZeroPad(precision / 1000, subSecond);
};

export const withCommas = (input: number): string => input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// non-null object type guard
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNonNullObject = (input: unknown): input is Record<any, unknown> => input !== null && typeof input === 'object';

// convert first character to uppercase
export const toProperNoun = (input: string): string => input.charAt(0).toUpperCase() + input.slice(1);

export const validateMessage = (message: unknown, extra?: Record<string | number, unknown>): { queryID: string; [key: string | number]: unknown } => {
  return isNonNullObject(message) ? { ...message, ...extra, queryID: v4() } : { data: message, ...extra, queryID: v4() };
};

export const withoutCommas = (input: string | undefined): number => (typeof input === 'string' ? Number(input.replace(/,/g, '')) : 0);
