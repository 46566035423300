import { useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';

export const useSocketRoom = (room: string): void => {
  const dispatch = useAppDispatch();

  // TODO add some sort of ack
  useEffect(() => {
    dispatch({ type: 'WS_JOIN', payload: room });

    return (): void => void dispatch({ type: 'WS_LEAVE', payload: room });
  }, [room]);
};
