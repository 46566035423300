import { Dispatch, useEffect, useState, useRef } from 'react';
import { makeSocketID } from '../util';
import { useSnackSend } from 'hooks/useSnackSend';
import { useSocketDispatch } from 'redux/hooks';
import { Pantheon, StatusCode } from 'types/pantheon/PantheonSocket';

export const useSocket = <TResponse extends Pantheon.Response<unknown>, TRequest extends Pantheon.Request<Pantheon.PacketType>>(channel: string, request?: TRequest): [TResponse | null, Dispatch<TRequest>] => {
  const dispatch = useSocketDispatch();

  const { sendSnack } = useSnackSend();

  const [query, setQuery] = useState<TRequest | null>(request || null);
  const [data, setData] = useState<TResponse | null>(null);

  const sockID = useRef<string>(makeSocketID());
  const complete = useRef<boolean>(false);
  const sent = useRef<boolean>(false);

  const emit = (message: TRequest) => {
    // remove listener for previous message
    // socket.off(sockID.current);

    // new ID and reset
    sockID.current = makeSocketID();
    complete.current = false;
    sent.current = false;

    // emit
    setQuery(message);
  };

  // error response handler
  useEffect(() => {
    data && data.code !== StatusCode.OK && sendSnack((data.message as Pantheon.Response<string>).message || 'Unknown response', 'error');
  }, [data]);

  useEffect(() => {
    if (query && !complete.current && !sent.current) {
      void dispatch({ type: 'WS_EMIT', payload: { event: channel, message: query } })
        .catch(e => console.error(e))
        .then(response => {
          complete.current = true;
          setData(response as TResponse);
        });

      sent.current = true;
      // setImmediate(() =>
      // dispatch(actions.WS_ONCE({ event: sockID.current, callback: (response: TResponse) => {
      //
      //   setData(response);
      // });
      // );
    }

    // return () => void socket.off(sockID.current);
  }, [query, complete, sent, sockID]);

  return [data, emit];
};
