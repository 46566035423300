import { NodeData, RowData, TenorData, ASXSnapshot } from './eod.types';

// type guard for NodeData
const isNodeData = (x: any): x is NodeData => {
  return (
    typeof x === 'object' &&
    ((x as NodeData).bid === null || typeof (x as NodeData).bid === 'number') &&
    ((x as NodeData).ask === null || typeof (x as NodeData).ask === 'number') &&
    ((x as NodeData).last === null || typeof (x as NodeData).last === 'number') &&
    ((x as NodeData).volume === null || typeof (x as NodeData).volume === 'number') &&
    ((x as NodeData).spread === null || typeof (x as NodeData).spread === 'number') &&
    ((x as NodeData).lastSettleDelta === null || typeof (x as NodeData).lastSettleDelta === 'number') &&
    ((x as NodeData).dayChange === null || typeof (x as NodeData).dayChange === 'number') &&
    typeof (x as NodeData).prev === 'number' &&
    typeof (x as NodeData).settle === 'number'
  );
};
// type guard for RowData
const isRowData = (x: any): x is RowData => {
  return typeof x === 'object' && isNodeData((x as RowData).ota) && isNodeData((x as RowData).ben) && isNodeData((x as RowData).basis) && typeof (x as RowData).date === 'string';
};
// type guard for TenorData
const isTenorData = (x: any): x is TenorData => {
  return typeof x === 'object' && Object.values(x).every(v => isRowData(v));
};
// type guard for Snapshot type
export const isSnapshot = (x: any): x is ASXSnapshot => {
  return typeof x === 'object' && Object.values(x).every(v => isTenorData(v));
};
