import { makeStyles, Theme, createStyles, Typography, Divider, Button, TableHead, Table, TableCell, TableContainer, TableBody, TableRow, IconButton, Paper, PaperProps } from '@material-ui/core';
import { grey, indigo, green } from '@material-ui/core/colors';
import React, { PropsWithChildren } from 'react';

import styled from 'styled-components';
import { AccordionChild, AccordionRoot, useAccordion } from 'lab/Accordion';
import { useAPI, useRPC } from 'modules/websocket/hooks';
import clsx from 'clsx';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ConfirmationRow, ConfirmationRecord } from '..';
import { makeHTML } from '../renderToHTML';
import { ConfirmationPayload, TransactionPayload } from 'types/pcp.types';
import { API } from 'types/pantheon/PantheonSocket';
import { Product } from 'pages/ae/broking/ticket/ticket.types';
import { TicketLocationState } from 'types/Ticket.types';
import { useLocation } from 'react-router-dom';
import { getDealpadState } from 'pages/ae/broking/dealpad/modals/confirm/ConfirmScreen';

const useRootStyles = makeStyles(() =>
  createStyles({
    button: {
      flex: '1',
      maxWidth: '33%',
    },
    buttonRoot: {
      display: 'flex',
      placeContent: 'space-evenly',
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
    accordion: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.black,
    },
    tableHeader: { backgroundColor: theme.palette.primary.light },
    evenRow: { backgroundColor: theme.palette.grey[100] },
    oddRow: { backgroundColor: theme.palette.background.paper },
    allRows: {},
    headerCell: {
      color: theme.palette.common.white,
    },
    tableCell: {
      padding: '4px 0px',

      '&:first-child': {
        paddingLeft: '8px !important',
      },
      '&:last-child': {
        paddingLeft: '8px !important',
      },
    },
    accordionDetail: { padding: '0px 16px 16px 16px' },
    accordionSummary: { margin: 0 },
    heading: {
      flexBasis: '33.33%',
      flexShrink: 0,
      alignSelf: 'center',
      paddingLeft: theme.spacing(2),
    },
    iconButton: {
      margin: theme.spacing(1),
      '&:hover': {
        color: green[100],
        backgroundColor: theme.palette.grey[600],
      },
    },
    confoTicked: {
      backgroundColor: green[100],
    },
  }),
);

// styled block
const Block = styled.div`
  padding: 16px 8px;
`;

const dividerStyle = {
  color: grey[500],
};

// custom header component
const Header = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <>
      <Divider variant={'fullWidth'} style={dividerStyle} />
      <Typography
        component={'div'}
        variant={'h6'}
        style={{
          padding: '4px 8px 4px 8px',
          fontStyle: 'italic',
          backgroundColor: indigo[50],
          color: '#303030',
        }}
      >
        {children}
      </Typography>
      <Divider variant={'fullWidth'} style={dividerStyle} />
    </>
  );
};

const ConfirmSection = (props: { i: number; email: string; confo: ConfirmationRow[]; toggleApproval: () => void; approved: boolean }) => {
  const { i, email, confo, toggleApproval, approved } = props;

  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/ban-types
  const AccordionTitle = ({ children }: PropsWithChildren<{}>) => {
    const Icon = approved ? CheckCircleIcon : RadioButtonUncheckedIcon;
    // tap into accordion context so we can collapse it
    const [, , collapse] = useAccordion();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      !approved && collapse();
      toggleApproval();
    };

    return (
      <>
        <IconButton aria-label='delete' className={classes.iconButton} onClick={handleClick}>
          <Icon fontSize='inherit' />
        </IconButton>
        <Typography className={classes.heading}>{children}</Typography>
      </>
    );
  };

  return (
    <AccordionChild
      heading={<AccordionTitle>{`${confo[0].Company} (${email})`}</AccordionTitle>}
      id={i.toString()}
      className={clsx(classes.accordion, { [classes.confoTicked]: approved })}
      summaryProps={{ className: classes.accordionSummary }}
      detailProps={{ className: classes.accordionDetail }}
    >
      <TableContainer
        id={`confo-table-${email}`}
        component={({ children, ...props }: PropsWithChildren<PaperProps>) => (
          <Paper elevation={2} {...props}>
            {children}
          </Paper>
        )}
      >
        <Table padding={'none'}>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              {Object.keys(confo[0]).map((column: string) => (
                <TableCell key={`${column}-title`} className={clsx(classes.headerCell, classes.tableCell)}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {confo.map((row: ConfirmationRow, i: number) => (
              <TableRow key={`row-${i}`} className={clsx(classes.allRows, i % 2 > 0 ? classes.oddRow : classes.evenRow)}>
                {Object.values(row).map((cell: string | number, j: number) => (
                  <TableCell key={`row-${i}-cell-${j}`} className={classes.tableCell}>
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AccordionChild>
  );
};
// AE Ref	Trade Time	Product	Side	Volume	Start Date	End Date	Duration	Price	Total Volume	Company	Trader	Commission	Venue	Venue Ref

const TradeConfirmDisplay = ({ trades, closeModal }: { trades: number[]; closeModal: (data?: unknown) => void; product?: Product }): JSX.Element => {
  const classes = useRootStyles();
  const { state } = useLocation<TicketLocationState>();
  const dealpadType = getDealpadState(state);

  const emit = useAPI();
  const rpc = useRPC();
  const [confos, setConfos] = React.useState<Record<string, ConfirmationRow[]>>({});
  const [approved, setApproval] = React.useState<Record<string, boolean>>({});
  const confoKeys = Object.keys(confos);

  // get confo data
  React.useEffect(
    () =>
      emit<ConfirmationRecord>(
        {
          type: 'API_REQUEST',
          method: 'get',
          schema: 'ae',
          table: `portal.confirmation.${dealpadType}`,
          fk: ['TicketID', trades],
        },
        (response?: API.GetResponse<ConfirmationRecord>) => {
          if (!response || !response.message) return;
          //TODO do group by in SQL view and return JSON object?
          const parsed = response.message.reduce<Record<string, ConfirmationRow[]>>((prev: Record<string, ConfirmationRow[]>, curr: ConfirmationRecord) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { confirmationEmail, TicketID, ...confoLine } = curr;
            !prev[confirmationEmail] && (prev[confirmationEmail] = []);
            prev[confirmationEmail].push(confoLine);
            return prev;
          }, {});
          setConfos(parsed);
        },
      ),
    [],
  );

  // populate approval record when confos change
  React.useEffect(() => setApproval(confoKeys.reduce<Record<string, boolean>>((prev: Record<string, boolean>, curr: string) => ({ ...prev, [curr]: false }), {})), [confos]);

  // dispatches mail payload to backend for handling
  const sendConfos = () => {
    // convert confo tables into stand-alone HTML
    const emails = confoKeys.filter((email: string) => approved[email]);
    const emailHTML: Record<string, string> = {};

    for (let i = 0; i < emails.length; i++) {
      const email = emails[i];
      const tableEl = document.getElementById(`confo-table-${email}`);
      if (tableEl) emailHTML[email] = makeHTML(tableEl);
    }

    // reduces Record of (approved) ConfoRows[] down to just the AE Ref's in an array
    const parameters = {
      transactions: confoKeys.reduce<Record<string, number[]>>((prev: Record<string, number[]>, curr: string) => {
        approved[curr] && (prev[curr] = confos[curr].map((row: ConfirmationRow) => row['AE Ref']));
        return prev;
      }, {}),
      emailHTML,
    };

    if (dealpadType === 'electricity_otc')
      rpc<TransactionPayload>({
        type: 'RPC_REQUEST',
        method: 'execute',
        procedure: 'confirmTransaction', // 'createTermSheet',
        parameters, //parameters: { transactions: parameters.transactions, outputType: 'termSheet' },
      });
    else
      rpc<ConfirmationPayload>({
        type: 'RPC_REQUEST',
        method: 'execute',
        procedure: 'confirmTransaction',
        parameters,
      });
    closeModal();
  };

  const handleApproveAll = () => setApproval(Object.keys(approved).reduce<Record<string, boolean>>((prev: Record<string, boolean>, curr: string) => ({ ...prev, [curr]: true }), {}));
  const approve = (email: string) => () => setApproval(state => ({ ...state, [email]: !approved[email] }));

  const accordionItems = React.useMemo(
    () =>
      confoKeys.map((email: string, i: number) => {
        const confo = confos[email];
        if (!confo[0]) return <>No rows</>;
        return <ConfirmSection key={i} email={email} i={i} confo={confo} toggleApproval={approve(email)} approved={approved[email] || false} />;
      }),
    [confos, approved],
  );

  return (
    <>
      <Header>Email metadata</Header>
      <Block>
        <Typography variant={'body1'}>
          <b>Subject:</b>
          <i style={{ paddingLeft: 4 }}>Aotearoa Energy Trade Confirmation</i>
          <br />
          <b>Recipient(s):</b>
          <i style={{ paddingLeft: 4 }}>{confoKeys.map((key: string) => confos[key][0].Company).join(', ')}</i>
        </Typography>
      </Block>
      <Header>Confirmations</Header>
      <Block style={{ overflowY: 'auto' }}>
        <AccordionRoot>{accordionItems.map((el: JSX.Element) => el)}</AccordionRoot>
      </Block>
      <Divider variant={'fullWidth'} style={dividerStyle} />
      <Block className={classes.buttonRoot}>
        <Button className={classes.button} variant={'contained'} color={'primary'} onClick={handleApproveAll} startIcon={Object.keys(approved).every(item => approved[item]) ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}>
          approve all
        </Button>
        <Button className={classes.button} disabled={!Object.keys(approved).some(item => approved[item])} variant={'contained'} color={'primary'} onClick={sendConfos}>
          send
        </Button>
      </Block>
    </>
  );
};

export { TradeConfirmDisplay };
