import React from 'react';

import { PaperItem } from 'components/styled/Grid';
import { Typography, Divider, TextField, InputLabel, Select, CircularProgress } from '@material-ui/core';
import { IGasTicket } from 'types/Ticket.types';
import { Control, Controller, useWatch } from 'react-hook-form';
import { TicketFormControl, useTicketStyles } from '../styles';
import { useAppSelector } from 'redux/hooks';
import { Product } from '../ticket.types';
import { getPortalFieldItems } from '../selectElement';
import moment from 'moment';

function GasTicketDetails({ control }: { control: Control<IGasTicket> }): JSX.Element {
  const productID = Product.GAS;

  const classes = useTicketStyles();

  // data context with form select items
  const { loaded } = useAppSelector(state => state.metadata.status);

  const products = useAppSelector(state => getPortalFieldItems<'product'>(state, 'product', { productID }));
  const ProductID = useWatch({ control, name: 'ProductID' });
  const venues = useAppSelector(state => getPortalFieldItems<'venue'>(state, 'venue', { products: ProductID || productID }));
  const units = useAppSelector(state => getPortalFieldItems<'unit'>(state, 'unit', { productID }));

  const volume = useWatch({ control, name: 'Volume' });
  const startDate = useWatch({ control, name: 'StartDate' });
  const endDate = useWatch({ control, name: 'EndDate' });
  const price = useWatch({ control, name: 'Price' });
  const [duration, setDuration] = React.useState(moment(endDate, 'YYYY-MM-DD').diff(moment(startDate, 'YYYY-MM-DD'), 'days') + 1);
  const [totalVolume, setTotalVolume] = React.useState(duration * volume);
  const [notional, setNotional] = React.useState(totalVolume * price);

  React.useEffect(() => {
    setDuration(moment(endDate, 'YYYY-MM-DD').diff(moment(startDate, 'YYYY-MM-DD'), 'days') + 1);
  }, [startDate, endDate]);

  React.useEffect(() => {
    setTotalVolume(duration * volume);
  }, [duration, volume]);

  React.useEffect(() => {
    setNotional(totalVolume * price);
  }, [totalVolume, price]);

  return (
    <PaperItem flex colSpan={5} rowSpan={1} className={classes.paper}>
      <div className={classes.headerRoot}>
        <Typography className={classes.header} variant={'h6'}>
          Ticket Details
        </Typography>
      </div>
      <Divider className={classes.divider} />

      {!loaded ? (
        <CircularProgress className={classes.formRoot} />
      ) : (
        <div className={classes.formRoot}>
          <div className={classes.row}>
            <Controller
              name='TradeTime'
              control={control}
              render={({ field }) => (
                <TextField
                  id='tradeTime'
                  size={'small'}
                  label='Trade Time & Date'
                  type='datetime-local'
                  variant={'outlined'}
                  className={classes.formControl}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />

            <Controller
              name='ProductID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='product-label'>Product Category</InputLabel>
                  <Select labelId='product-label' id='product' label='Product Category' {...field} readOnly>
                    {products}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller
              name='VenueID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='venue-label'>Trade Venue</InputLabel>
                  <Select labelId='venue-label' id='venue' label='Trade Venue' {...field}>
                    {venues}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller
              name='VenueReference'
              control={control}
              render={({ field }) => <TextField size={'small'} id={`venuereference`} label='Venue Reference' type={'text'} InputProps={{ type: 'text' }} variant='outlined' className={classes.formControl} {...field} />}
            />
          </div>
          <div className={classes.row}>
            <Controller
              name='StartDate'
              control={control}
              render={({ field }) => (
                <TextField
                  id='StartDate'
                  size={'small'}
                  label='Start Date'
                  type='date'
                  variant={'outlined'}
                  className={classes.formControl}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
            <Controller
              name='EndDate'
              control={control}
              render={({ field }) => (
                <TextField
                  id='EndDate'
                  size={'small'}
                  label='End Date'
                  type='date'
                  variant={'outlined'}
                  className={classes.formControl}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
          </div>

          <div className={classes.row}>
            <Controller
              name='Price'
              control={control}
              render={({ field }) => <TextField size={'small'} id={`price`} label='Price' type={'number'} InputProps={{ type: 'decimal' }} variant='outlined' className={classes.formControl} {...field} />}
            />

            <Controller
              name='Volume'
              control={control}
              render={({ field }) => <TextField size={'small'} id={`volume`} label='Volume' type={'number'} InputProps={{ type: 'numeric' }} variant='outlined' className={classes.formControl} {...field} />}
            />
            <Controller
              name='TradeUnitID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='trade-unit-label'>Trade Unit</InputLabel>
                  <Select labelId='trade-unit-label' id='trade-unit' label='Trade Unit' {...field}>
                    {units}
                  </Select>
                </TicketFormControl>
              )}
            />
          </div>
          <div className={classes.row}>
            <TextField size={'small'} id={`duration`} value={duration} label='Trade Duration' type={'number'} InputProps={{ type: 'numeric', readOnly: true }} variant='outlined' className={classes.formControl} />

            <TextField size={'small'} id={`totalVolume`} value={totalVolume} label='Total Volume' type={'number'} InputProps={{ type: 'numeric', readOnly: true }} variant='outlined' className={classes.formControl} />

            <TextField size={'small'} id={`notional`} value={notional} label='Notional' type={'number'} InputProps={{ type: 'numeric', readOnly: true }} variant='outlined' className={classes.formControl} />
          </div>
        </div>
      )}
    </PaperItem>
  );
}

export { GasTicketDetails };
