import moment from 'moment';

//TODO could probably do with being standardised and split out somewhere
export interface TradeLeg {
  ticketID: number | string;
  legNumber: number;
  volume: number;
  contract: string;
  code: string;
  price: string;
  buyer: string;
  buyerAccount: string;
  seller: string;
  sellerAccount: string;
  option: string;
}

type TicketNode = 'OTA' | 'BEN' | '**ERROR**';
type TicketContractType = 'QUARTER' | 'MONTH' | '**ERROR**';
type TicketLoadType = 'BASE' | 'PEAK' | '**ERROR**';

// futures month codes to month number map
const monthMap = {
  F: 1,
  G: 2,
  H: 3,
  J: 4,
  K: 5,
  M: 6,
  N: 7,
  Q: 8,
  U: 9,
  V: 10,
  X: 11,
  Z: 12,
};

// returns node name form futures code
const getNode = (code: string): TicketNode => {
  const otaCodes = ['EA', 'ED'];
  const benCodes = ['EE', 'EH'];

  if (otaCodes.includes(code.substring(0, 2))) return 'OTA';
  if (benCodes.includes(code.substring(0, 2))) return 'BEN';
  return '**ERROR**';
};

// returns either BASE or PEAK depending on contract load type
const getElectricityLoadType = (code: string): TicketLoadType => {
  const base = ['EA', 'EE', 'ED', 'EH'];
  const peak: string[] = [];
  if (base.includes(code.substring(0, 2))) return 'BASE';
  if (peak.includes(code.substring(0, 2))) return 'PEAK';
  return '**ERROR**';
};

// returns whther futures is monthly or quarterly
const getFutureType = (code: string): TicketContractType => {
  const quarter = ['EA', 'EE'];
  const month = ['ED', 'EH'];
  if (quarter.includes(code.substring(0, 2))) return 'QUARTER';
  if (month.includes(code.substring(0, 2))) return 'MONTH';
  return '**ERROR**';
};

// turns month number and future type into text contract description (i.e. Q1 on a quarterly, Jan on a monthly)
const makeContractDateText = (month: number, type: TicketContractType) => {
  switch (type) {
    case 'QUARTER':
      return `Q${month / 3}`;
    case 'MONTH':
      return moment(new Date().setMonth(month - 1)).format('MMM');
    default:
      return '**ERROR**';
  }
};

// returns plain name of the contract month/quarter and year (i.e. Q1-2022 on a quarterly, Jan-2022 on a monthly)
const getContractDate = (code: string): string => {
  const futureType = getFutureType(code);
  const year = new Date().getFullYear().toString().substring(0, 3) + code[code.length - 1];
  const monthNumber = monthMap[code[code.length - 2] as keyof typeof monthMap];
  const month = makeContractDateText(monthNumber, futureType);

  return `${month}-${year}`;
};

const makeContractText = (code: string): string => {
  return `${getNode(code)} ${getElectricityLoadType(code)} ${getContractDate(code)}`;
};

const makeTradeLeg = (tradeLeg: TradeLeg): string => {
  // template set out by BPS
  const legTemplate = `LEG %legNumber% - AE Ref #%ticketID%
%volume% lots %contract% %option% (%code%) @ %price%
 Buyer: %buyer% (%buyerAccount%)
 Seller: %seller% (%sellerAccount%)`;

  // populate template with data
  return Object.keys(tradeLeg).reduce<string>((prev: string, key: string) => prev.replace(`%${key}%`, tradeLeg[key as keyof TradeLeg]?.toString()), legTemplate);
};

export { makeTradeLeg, makeContractText };
