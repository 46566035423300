import moment from 'moment';

interface StackRow {
  TradingDate: string;
  TradingPeriod: number;
  ParticipantCode: string;
  PointOfConnection: string | string[];
  Unit: string;
  ProductType: string;
  ProductClass: string;
  ReserveType: string;
  ProductDescription: string;
  UTCSubmissionDate: string;
  UTCSubmissionTime: string;
  SubmissionOrder: number;
  IsLatestYesNo: string;
  Tranche: number;
  MaximumRampUpMegawattsPerHour: number;
  MaximumRampDownMegawattsPerHour: number;
  PartiallyLoadedSpinningReservePercent: number;
  MaximumOutputMegawatts: number;
  ForecastOfGenerationPotentialMegawatts: number;
  Megawatts: number;
  DollarsPerMegawattHour: number;
}

export interface StackData {
  rows: StackRow[];
  count: number;
  participants: string[];
  nodes: string[];
}

interface BandItem {
  bandVolume: number;
  cumulativeVolume: number;
  count: number;
}
export type PriceData = {
  band: Map<number, BandItem>;
  tp: number;
  time: moment.Moment;
};

export type PriceStack = Record<number, PriceData>;

export type StackSeries = Record<number, Highcharts.SeriesAreaOptions>;

export interface StackRequestBody {
  type: 'offers' | 'bids';
  date: string;
  code: string;
  PointOfConnection?: string | string[];
  ParticipantCode?: string;
  ProductType?: 'Energy' | 'Reserves';
  IsLatestYesNo?: 'Y' | 'N';
}

export const stackReducer = (inputData: StackData, existingData: PriceStack = {}) => {
  const uniqueBands = new Set<number>();
  const existingKeys = new Set(Object.keys(existingData).map(key => moment(key).format('YYYY-MM-DD')));
  const newKeys = new Set(inputData.rows.map(row => moment(row.TradingDate).format('YYYY-MM-DD')));
  const uniqueKeys: string[] = [];

  newKeys.forEach(key => {
    if (!existingKeys.has(key)) {
      uniqueKeys.push(key);
    }
  });

  let cumulativeVolume = 0;
  return inputData.rows
    .sort((a, b) => {
      const aUnix = moment(a.TradingDate, 'YYYY-MM-DD')
        .add((a.TradingPeriod - 1) / 2, 'hours')
        .unix();
      const bUnix = moment(b.TradingDate, 'YYYY-MM-DD')
        .add((b.TradingPeriod - 1) / 2, 'hours')
        .unix();

      if (aUnix === bUnix) return a.DollarsPerMegawattHour - b.DollarsPerMegawattHour;
      return aUnix - bUnix;
    })
    .reduce<PriceStack>((prev, curr) => {
      if (curr.Megawatts === 0) return prev;
      // init tp if not there
      const tp = curr.TradingPeriod;
      const time = moment(curr.TradingDate, 'YYYY-MM-DD').add((tp - 1) / 2, 'hours');
      const tpTimeUnix = time.unix();

      if (prev[tpTimeUnix] === undefined) {
        prev[tpTimeUnix] = { band: new Map<number, BandItem>(), tp, time };
        cumulativeVolume = 0;
      }

      // add the price band if missing
      const currentBand = prev[tpTimeUnix].band.get(curr.DollarsPerMegawattHour);
      const currentBandVolume = currentBand?.bandVolume ?? 0;
      const currentBandCount = currentBand?.count ?? 0;
      cumulativeVolume += curr.Megawatts;
      prev[tpTimeUnix].band.set(curr.DollarsPerMegawattHour, { bandVolume: currentBandVolume + curr.Megawatts, cumulativeVolume, count: currentBandCount + 1 });
      uniqueBands.add(curr.DollarsPerMegawattHour);

      return prev;
    }, existingData);
};
