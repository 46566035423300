import React from 'react';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { theme } from './style/theme';
import { NavBar } from './components/Nav';
import moment from 'moment';
import { history } from './redux/reducer';
import Content from './Content';
import { ModalProvider } from './lab/ModalProvider/ModalProvider';
import 'style/highchartGlobals';

import { ConnectedRouter } from 'connected-react-router';

import { LicenseInfo } from '@material-ui/x-grid';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { RouterNavigationClient } from 'modules/msal/RouterNavigationClient';
import { Login } from 'router/Login';

LicenseInfo.setLicenseKey('579928b7361d0799cead6f64f8fc9c6dT1JERVI6NDAzNDQsRVhQSVJZPTE2Nzk3MzI3NTEwMDAsS0VZVkVSU0lPTj0x');

moment.locale('en-NZ');

export default function App({ instance }: { instance: PublicClientApplication }): JSX.Element {
  const navigationClient = new RouterNavigationClient(history);
  instance.setNavigationClient(navigationClient);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ConnectedRouter history={history}>
          <MsalProvider instance={instance}>
            <ModalProvider>
              <NavBar />
              <Content />
              <UnauthenticatedTemplate>
                <Login />
              </UnauthenticatedTemplate>
            </ModalProvider>
          </MsalProvider>
        </ConnectedRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
