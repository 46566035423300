import React from 'react';
import clsx from 'clsx';
import { useAppSelector } from 'redux/hooks';
import { useStyles } from './useStyles';

export const HeaderBlock = () => {
  const { matchMode, simpleView } = useAppSelector(state => state.broking);
  const classes = useStyles();
  return (
    <>
      {(!matchMode || (matchMode && !simpleView)) && (
        <>
          <th className='column-header lhbb'>Bid</th>
          <th className='column-header rhbb'>Ask</th>
          <th className='column-header'>Last</th>
        </>
      )}
      {!matchMode && <th className='column-header'>Volume</th>}
      {(!matchMode || (matchMode && !simpleView)) && (
        <>
          <th className='column-header'>Last &Delta;</th>
          <th className='column-header'>Settle &Delta;</th>
        </>
      )}
      {!matchMode && <th className='column-header lhbb rhbb'>Prev Settle</th>}
      {matchMode && <th className='bid-header'>Bid Volume (MW)</th>}
      <th className={clsx('column-header', 'rhbb', { [classes.wideSettle]: matchMode && simpleView })}>Settle</th>
      {matchMode && <th className='ask-header'>Ask Volume (MW)</th>}
    </>
  );
};
