export type NodeData = {
  node: 'OTA' | 'BEN' | 'BASIS';
  code: string;
  bid: number | null;
  ask: number | null;
  last: number | null;
  volume: number | null;
  spread: number | null;
  lastSettleDelta: number | null;
  dayChange: number | null;
  prev: number;
  settle: number;
};

export type RowData = {
  date: string;
  ordinal: number;
  ota: NodeData;
  ben: NodeData;
  basis: NodeData;
};

export const ordering: {
  [K in Section]: number;
} = {
  cal: 12,
  quarter: 3,
  month: 1,
};

export type TenorData = {
  [label: string]: RowData;
};

export type Section = 'month' | 'quarter' | 'cal';
export type Block = 'ota' | 'ben' | 'basis';

export type ASXSnapshot = {
  [K in Section]: TenorData;
};

export type SectionProps = { settleDate: string; section: Section; data: TenorData; matchMode: boolean };
export type HeadersYProps = { settleDate: string; labels: [Section, string[]][]; data: ASXSnapshot };
export type SectionBlockProps = SectionProps & { name: Block };
export type SortedSnapshot = [string, [Section, string[]][]];
export type EODPayload = { eodData: ASXSnapshot; settleDate: number };
