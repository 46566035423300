import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Page, Section } from 'components/Wrapper';
import { MatchGrid } from './MatchGrid';
import { MatchSubmitter } from './MatchSubmitter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {},
    inputRow: {
      display: 'flex',
      flexDirection: 'row',
      padding: 4,
      gap: '8px',
    },
    inputColumn: {
      display: 'flex',
      placeContent: 'space-evenly',
      width: 200,
      flexDirection: 'column',
      padding: 4,
      gap: '8px',
    },
    formControl: {
      margin: theme.spacing(1),
      textAlign: 'left',
    },
  }),
);

export function Match(): JSX.Element {
  const classes = useStyles();
  // // const socket = useSocketInstance();

  return (
    <Page title={'AE MATCH'} style={{ gridTemplateColumns: '100%' }} rows={'5rem 1fr'} columnCount={1}>
      <div style={{ display: 'grid', gap: '16px', gridTemplateColumns: '2fr 1fr' }}>
        <Section childClassName={classes.section}>
          <MatchGrid />
        </Section>
        <Section childClassName={classes.section}>
          <MatchSubmitter />
        </Section>
      </div>
    </Page>
  );
}
