import React from 'react';

import { PaperItem } from 'components/styled/Grid';
import { Typography, Divider, TextField, InputLabel, MenuItem, Select, FormControlLabel, Switch, CircularProgress } from '@material-ui/core';
import { IFuturesTicket } from 'types/Ticket.types';
import clsx from 'clsx';
import { Control, Controller, useWatch } from 'react-hook-form';
import { TicketFormControl, useTicketStyles } from '../styles';
import { useAppSelector } from 'redux/hooks';
import { getPortalFieldItems } from '../selectElement';

function ElectricityTicketDetails({ control }: { control: Control<IFuturesTicket> }): JSX.Element {
  const classes = useTicketStyles();
  const isOption = useWatch({
    control,
    name: 'isOption',
  });

  const products = useAppSelector(state => getPortalFieldItems<'product'>(state, 'product', { productID: 4 }));
  const venues = useAppSelector(state => getPortalFieldItems<'venue'>(state, 'venue', { products: 4 }));
  const option_type = useAppSelector(state => getPortalFieldItems<'option_type'>(state, 'option_type'));
  const option_exercise_type = useAppSelector(state => getPortalFieldItems<'option_exercise_type'>(state, 'option_exercise_type'));
  const futures_months = useAppSelector(state => getPortalFieldItems<'futures_months'>(state, 'futures_months'));
  const futures = useAppSelector(state => getPortalFieldItems<'futures'>(state, 'futures'));
  const trade_units = useAppSelector(state => getPortalFieldItems<'unit'>(state, 'unit', { productID: 4 }));
  // data context with form select items
  const { loaded } = useAppSelector(state => state.metadata.status);

  return (
    <PaperItem flex colSpan={5} rowSpan={1} className={classes.paper}>
      <div className={classes.headerRoot}>
        <Typography className={classes.header} variant={'h6'}>
          Ticket Details
        </Typography>
      </div>
      <Divider className={classes.divider} />

      {!loaded ? (
        <CircularProgress className={classes.formRoot} />
      ) : (
        <div className={classes.formRoot}>
          <div className={classes.row}>
            <Controller
              name='TradeTime'
              control={control}
              render={({ field }) => (
                <TextField
                  id='tradeTime'
                  size={'small'}
                  label='Trade Time & Date'
                  type='datetime-local'
                  variant={'outlined'}
                  className={classes.formControl}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
            <Controller
              name='ProductID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='product-label'>Product Category</InputLabel>
                  <Select labelId='product-label' id='product' label='Product Category' {...field}>
                    {products}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller
              name='VenueID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='venue-label'>Trade Venue</InputLabel>
                  <Select labelId='venue-label' id='venue' label='Trade Venue' {...field}>
                    {venues}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller name='isBlock' control={control} render={({ field }) => <FormControlLabel className={classes.formControlLabel} control={<Switch checked={field.value} color='primary' {...field} />} label='Block Trade' />} />
          </div>
          <div className={classes.row}>
            <Controller
              name='FuturesCodeID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='future-contract-label'>Futures Contract</InputLabel>
                  <Select labelId='future-contract-label' id='future-contract' label='Futures Contract' {...field}>
                    {futures}
                  </Select>
                </TicketFormControl>
              )}
            />

            <Controller
              name='FuturesMonthID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='future-month-label'>Futures Contract</InputLabel>
                  <Select labelId='future-month-label' id='future-month' label='Futures Month' {...field}>
                    {futures_months}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller
              name='FuturesYear'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='future-year-label'>Futures Year</InputLabel>
                  <Select labelId='future-year-label' id='future-year' label='Futures Year' {...field}>
                    {[0, 1, 2, 3, 4].map((i: number) => (
                      <MenuItem key={i} value={new Date().getFullYear() + i}>
                        {new Date().getFullYear() + i}
                      </MenuItem>
                    ))}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller name='isOption' control={control} render={({ field }) => <FormControlLabel className={classes.formControlLabel} control={<Switch checked={field.value} color='primary' {...field} />} label='Futures Option' />} />
          </div>
          <div
            className={clsx(classes.row, {
              [classes.hiddenRow]: !isOption,
            })}
          >
            <Controller
              name='OptionExerciseTypeID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='future-option-exercise-label'>Exercise Type</InputLabel>
                  <Select labelId='future-option-exercise-label' id='future-option-exercise' {...field} label='Futures Exercise Type'>
                    {option_exercise_type}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller
              name='OptionTypeID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='future-option-type-label'>Option Type</InputLabel>
                  <Select labelId='future-option-type-label' id='future-option-type' {...field} label='Futures Option Type'>
                    {option_type}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller
              name='OptionStrike'
              control={control}
              render={({ field }) => <TextField size={'small'} id={`strike`} label='Strike Price' type={'number'} InputProps={{ type: 'decimal' }} variant='outlined' className={classes.formControl} {...field} />}
            />
            <Controller
              name='OptionIV'
              control={control}
              render={({ field }) => <TextField size={'small'} id={`impliedVol`} label='Implied Volatility' type={'number'} InputProps={{ type: 'decimal' }} variant='outlined' className={classes.formControl} {...field} />}
            />
            <Controller
              name='OptionUnderlyingPrice'
              control={control}
              render={({ field }) => <TextField size={'small'} id={`underlyingPrice`} label='Underlying Price' type={'number'} InputProps={{ type: 'decimal' }} variant='outlined' className={classes.formControl} {...field} />}
            />
          </div>
          <div className={classes.row}>
            <Controller
              name='Volume'
              control={control}
              render={({ field }) => <TextField size={'small'} id={`volume`} label='Volume' type={'number'} InputProps={{ type: 'numeric' }} variant='outlined' className={classes.formControl} {...field} />}
            />
            <Controller
              name='TradeUnitID'
              control={control}
              render={({ field }) => (
                <TicketFormControl>
                  <InputLabel id='trade-unit-label'>Trade Unit</InputLabel>
                  <Select labelId='trade-unit-label' id='trade-unit' label='Trade Unit' {...field}>
                    {trade_units}
                  </Select>
                </TicketFormControl>
              )}
            />
            <Controller
              name='Price'
              control={control}
              render={({ field }) => <TextField size={'small'} id={`price`} label='Price' type={'number'} InputProps={{ type: 'decimal' }} variant='outlined' className={classes.formControl} {...field} />}
            />
          </div>
        </div>
      )}
    </PaperItem>
  );
}

export { ElectricityTicketDetails };
