import React from 'react';
import ReactDOM from 'react-dom';
// import './css/index.css';
import './fonts/centraNo2.css';
import App from './App';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './modules/msal/authConfig';
import { isAuthenticationResult } from './modules/msal/guards';
import { store } from './redux/store';
import { Provider } from 'react-redux';

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.INITIALIZE_START) {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }
  }
  if (event.eventType === EventType.LOGIN_SUCCESS && isAuthenticationResult(event.payload)) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    console.error(JSON.stringify(event));
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App instance={msalInstance} />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
