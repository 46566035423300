import { createStyles, InputAdornment, InputLabel, makeStyles, Select, TextField } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useAppSelector } from 'redux/hooks';
import { getPortalFieldItems } from '../selectElement';
import { TicketRow } from './TicketRow';

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      '& .MuiSelect-select': {
        '&:focus': {
          backgroundColor: 'initial',
        },
      },
    },
  }),
);
export const VolumeInput = ({ productID, controllerClassName, showTotalVolume = false, showNotional = false }: { productID?: number; controllerClassName?: string; showTotalVolume?: boolean; showNotional?: boolean }): JSX.Element => {
  const { control, watch } = useFormContext();
  const classes = useStyles();

  const unit = watch('TradeUnitID') as number;
  const currentUnit = useAppSelector(state => state.metadata.ticketMeta.unit.find(item => item.unitID === unit));
  const units = useAppSelector(state => getPortalFieldItems<'unit'>(state, 'unit', { productID }));

  return (
    <TicketRow>
      <Controller
        name='Volume'
        control={control}
        render={({ field }) => (
          <TextField
            size={'small'}
            id={`volume`}
            label='Volume'
            type={'number'}
            InputProps={{
              type: 'number',
            }}
            variant='outlined'
            className={controllerClassName}
            {...field}
          />
        )}
      />
      <Controller
        name='TradeUnitID'
        control={control}
        render={({ field }) => (
          <Select variant={'standard'} margin={'none'} className={classes.select} disableUnderline labelId='trade-unit-label' id='trade-unit' label='Trade Unit' {...field}>
            {units}
          </Select>
        )}
      />
      {showTotalVolume && (
        <Controller
          name='totalVolume'
          control={control}
          render={({ field }) => (
            <TextField
              size={'small'}
              id={`totalVolume`}
              label='Total Volume'
              type={'number'}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true,
                type: 'number',
                endAdornment: <InputAdornment position='end'>{currentUnit?.totalVolumeUnit}</InputAdornment>,
              }}
              variant='outlined'
              className={controllerClassName}
              {...field}
            />
          )}
        />
      )}

      <Controller
        name='Price'
        control={control}
        render={({ field }) => <TextField size={'small'} id={`price`} label='Price' type={'number'} InputProps={{ type: 'decimal' }} variant='outlined' className={controllerClassName} {...field} />}
      />
      {showNotional && (
        <Controller
          name='Notional'
          control={control}
          render={({ field }) => (
            <TextField
              size={'small'}
              id={`Notional`}
              label='Notional Value'
              type={'number'}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true, type: 'numeric', startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
              variant='outlined'
              className={controllerClassName}
              {...field}
            />
          )}
        />
      )}
    </TicketRow>
  );
};
