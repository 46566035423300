import { MysqlError } from 'mysql';
import { Product } from 'pages/ae/broking/ticket/ticket.types';
import { ICarbonTicket, IFuturesTicket } from 'types/Ticket.types';
import { Pantheon } from '../types/pantheon/PantheonSocket';

export const isMysqlError = (obj: unknown): obj is MysqlError => {
  const asMysqlError = obj as MysqlError;
  const keys = ['code', 'errNo'];
  return typeof obj === 'object' && keys.every((key: string) => asMysqlError[key as keyof MysqlError] !== undefined);
};

// type guard for Pantheon.Response
export const isPantheonResponse = (obj: unknown): obj is Pantheon.Response => {
  return typeof obj === 'object' && obj !== null && typeof (obj as Pantheon.Response).code === 'number' && (obj as Pantheon.Response).message !== undefined;
};

// array of string, boolean, number, Date arrays type guard
export const isArrayOf =
  <T>(type: T extends string ? 'string' : T extends number ? 'number' : 'unknown') =>
  (obj: unknown): obj is T[][] => {
    return Array.isArray(obj) && obj.every((element: unknown) => Array.isArray(element) && element.every(subElement => typeof subElement === type));
  };

// array of string, boolean, number, Date arrays type guard
export const isArrayOfArrays = (obj: unknown): obj is (string | number | Date | boolean | null)[][] => {
  return Array.isArray(obj) && obj.every((element: unknown) => Array.isArray(element) && element.every(subElement => ['string', 'number', 'boolean', 'Date', 'null'].includes(typeof subElement)));
};

// IFuturesTicket type guard using ProductID as discriminator
export const isFuturesTicket = (obj: unknown): obj is IFuturesTicket => {
  return typeof obj === 'object' && obj !== null && typeof (obj as IFuturesTicket).ProductID === 'number' && (obj as IFuturesTicket).ProductID === Product.ELECTRICITY;
};

// ICarbonTicket type guard using ProductID as discriminator
export const isCarbonTicket = (obj: unknown): obj is ICarbonTicket => {
  return typeof obj === 'object' && obj !== null && typeof (obj as ICarbonTicket).ProductID === 'number' && (obj as ICarbonTicket).ProductID === Product.CARBON;
};
