import React from 'react';

import { Header } from 'components/Header';
import { GasTicketSide } from '../ticketSides';
import { GasTicketDetails } from '../ticketDetails';
import { TicketFooter } from '../TicketFooter';
import { useLocation } from 'react-router-dom';
import { useSocketEmit } from 'modules/websocket/hooks';

import { IGasTicket, IGasTicketSide, TicketLocationState } from 'types/Ticket.types';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import moment from 'moment';
import { StyledContainer } from 'components/styled';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { UberIconButton } from 'components/UberIconButton';
import { createStyles } from '@material-ui/styles';
import { darken, makeStyles, Theme } from '@material-ui/core';
import { API } from 'types/pantheon/PantheonSocket';
import { Product } from '../ticket.types';
import { toProperNoun } from 'lib/helpers';
import { useAccount } from '@azure/msal-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    swapButton: {
      '& svg': {
        margin: theme.spacing(1.5),
      },

      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      width: '2.4rem',
      height: '2.4rem',
      borderRadius: '4px',
      '&:hover': {
        transition: '0.5s ease',
        color: darken(theme.palette.primary.main, 0.25),
        backgroundColor: '#dadada',
      },
    },
  }),
);

function GasTicket(): JSX.Element {
  const product = Product.GAS;
  const classes = useStyles();

  const account = useAccount();

  const { state } = useLocation<TicketLocationState>();

  const [emit] = useSocketEmit('pantheon.api');
  const productName = Product[product].toLowerCase();
  const methods = useForm<IGasTicket>({
    defaultValues: {
      ID: '' as unknown as number,
      TradeTime: moment().format('YYYY-MM-DDTHH:mm'),
      StartDate: moment().format('YYYY-MM-DD'),
      EndDate: moment().format('YYYY-MM-DD'),
      VenueReference: '',
      ProductID: Product.GAS,
      VenueID: 3,
      Volume: '' as unknown as number,
      Price: '' as unknown as number,
      hasBuyer: true,
      hasSeller: true,
      TradeUnitID: 3,
      ContractTypeID: 4,
      seller: {
        ID: '' as unknown as number,
        EntityID: '' as unknown as number,
        CounterpartyID: '' as unknown as number,
        TraderID: '' as unknown as number,
        brokerage_rate: '' as unknown as number,
        brokerage: '' as unknown as number,
        broTypeID: 12,
        SubmitterID: (account?.idTokenClaims?.['extn.aeID'] as number[])[0] || ('' as unknown as number),
        BrokerID: (account?.idTokenClaims?.['extn.aeID'] as number[])[0] || ('' as unknown as number),
      },
      buyer: {
        ID: '' as unknown as number,
        EntityID: '' as unknown as number,
        CounterpartyID: '' as unknown as number,
        TraderID: '' as unknown as number,
        brokerage_rate: '' as unknown as number,
        brokerage: '' as unknown as number,
        broTypeID: 12,
        SubmitterID: (account?.idTokenClaims?.['extn.aeID'] as number[])[0] || ('' as unknown as number),
        BrokerID: (account?.idTokenClaims?.['extn.aeID'] as number[])[0] || ('' as unknown as number),
      },
    },
  });
  const { control, handleSubmit, getValues, setValue } = methods;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit: SubmitHandler<IGasTicket> = data => {
    // console.log(data);
  };

  React.useEffect(() => {
    if (state?.ticketId) {
      const { ticketId } = state;
      emit<API.Request, API.GetResponse<{ ID: number; Ticket: IGasTicket }>>(
        {
          type: 'API_REQUEST',
          method: 'get',
          schema: 'ae',
          table: `portal.ticket.gas`,
          id: ticketId,
        },
        (response?: API.GetResponse<{ ID: number; Ticket: IGasTicket }>) => {
          const ticketData = response && response.message && response.message[0]?.Ticket;
          if (!ticketData) return;

          // TODO bit gross...
          const { seller, buyer, ...ticket } = ticketData;
          for (const key in seller) setValue(`seller.${key as keyof IGasTicketSide}`, seller[key as keyof IGasTicketSide] || ('' as unknown as number));
          for (const key in buyer) setValue(`buyer.${key as keyof IGasTicketSide}`, buyer[key as keyof IGasTicketSide] || ('' as unknown as number));

          // TODO worst bit...
          for (const key in ticket) {
            if (key === 'TradeTime') setValue('TradeTime', moment(ticket.TradeTime).format('YYYY-MM-DDTHH:mm'));
            else if (key.substring(0, 2) === 'is' || key.substring(0, 3) === 'has') {
              setValue(`${key as keyof IGasTicket}`, Boolean(ticket[key as keyof Omit<IGasTicket, 'buyer' | 'seller'>]));
            } else {
              setValue(`${key as keyof IGasTicket}`, ticket[key as keyof Omit<IGasTicket, 'buyer' | 'seller'>] || ('' as unknown as number));
            }
          }

          seller && setValue('seller.brokerage', seller['brokerage']);
          buyer && setValue('buyer.brokerage', buyer['brokerage']);
        },
      );
    }
  }, [state]);

  const swapSides = () => {
    const { seller, buyer, hasSeller, hasBuyer } = { ...getValues() };

    if (hasSeller) {
      setValue('hasBuyer', true);
      setValue('buyer', seller);
    } else {
      setValue('hasBuyer', false);
      setValue('buyer', null);
    }

    if (hasBuyer) {
      setValue('hasSeller', true);
      setValue('seller', buyer);
    } else {
      setValue('hasSeller', false);
      setValue('buyer', null);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={() => void handleSubmit(onSubmit)}>
        <StyledContainer columns={'1fr 1fr min-content 1fr 1fr'} rows={'2.5rem 1fr 1fr 5rem'}>
          <Header text={`${toProperNoun(productName)} Deal Ticket ${state?.ticketId || ''}`} max columnCount={5} />

          <GasTicketDetails control={control} />
          <GasTicketSide side={'seller'} control={control} setValue={setValue} productID={product} />
          <div style={{ alignSelf: 'center' }}>
            <UberIconButton autoMargin={'left'} classOverride={classes.swapButton} onClick={swapSides} tooltip={'Swap sides'} Icon={SwapHorizIcon} />
          </div>
          <GasTicketSide side={'buyer'} control={control} setValue={setValue} productID={product} />
          <TicketFooter getValues={getValues} />
        </StyledContainer>
      </form>
    </FormProvider>
  );
}

export { GasTicket };
