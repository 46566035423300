import { Box, Button, CircularProgress, Fade, FormControlLabel, Paper, Switch, TextField } from '@material-ui/core';
import React, { ChangeEventHandler } from 'react';
import { useSocketDispatch } from 'redux/hooks';
import { wsAsyncActions } from 'redux/middleware/socket/actions';
import { API, StatusCode } from 'types/pantheon/PantheonSocket';
import { EODPayload } from './eod.types';
import { isSnapshot } from './guards';
import { brokingActions } from 'redux/slice';
import { useAppSelector } from 'redux/hooks';
import { latestSnapshotSelector, getEODLabels } from 'redux/slice/broking/selectors';
import { DigitalClock } from 'components/DigitalClock/DigitalClock';
import { HeadersY } from './components/HeadersY';
import { useStyles } from './components/useStyles';
import moment from 'moment';
import { UserMatchVolume } from 'redux/slice/broking/broking';
import { useAccount } from '@azure/msal-react';

type MatchVolumeData = {
  userID: number;
  tradeDate: string;
  matchVolume: UserMatchVolume;
};

export const RowLabel = ({ children }: { children: string }) => {
  return <th className='label'>{children}</th>;
};

export const EODGrid = (): JSX.Element => {
  const dispatch = useSocketDispatch();
  const classes = useStyles();

  const { matchMode, matchVolumeStep, simpleView } = useAppSelector(state => state.broking);
  // const clientIdentifier = useAppSelector(state => state.serviceBus.clientIdentifier);
  const account = useAccount();

  const userID = account?.idTokenClaims?.oid;

  // const changesPending = useAppSelector(hasPending);
  const [snapshot, latestSnapshot, settleDate] = useAppSelector(latestSnapshotSelector);
  const labels = useAppSelector(getEODLabels);

  const toggleMode = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => dispatch(brokingActions.setMatchMode(checked));
  const toggleView = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => dispatch(brokingActions.enableSimpleView(checked));
  const changeStep: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => dispatch(brokingActions.setVolumeStep(event.target.value as unknown as number));
  const setStep = (step: number) => () => dispatch(brokingActions.setVolumeStep(step));
  React.useEffect(() => {
    dispatch<API.Response<EODPayload[]>>(
      wsAsyncActions.WS_API({
        type: 'API_REQUEST',
        method: 'get',
        table: 'portal.asx_eod',
        schema: 'broker',
        date: latestSnapshot === null ? undefined : { sign: '>', field: 'settleDate', min: latestSnapshot / 1000 },
      }),
    )
      .then(({ code, message }) => {
        if (code === StatusCode.OK && Array.isArray(message) && isSnapshot(message[0].eodData)) {
          dispatch(brokingActions.setASXEOD(message));
        }
      })
      .catch(e => console.error(e));
  }, []);

  React.useEffect(() => {
    userID &&
      latestSnapshot !== null &&
      dispatch<API.Response<MatchVolumeData[]>>(
        wsAsyncActions.WS_API({
          type: 'API_REQUEST',
          method: 'get',
          table: 'portal.match.volume',
          schema: 'pcp',
          date: { field: 'tradeDate', min: moment(latestSnapshot).format('YYYY-MM-DD') },
          fk: ['userID', userID],
        }),
      ).then(response => {
        if (response?.code === StatusCode.OK) {
          const data = response?.message?.[0]?.matchVolume;
          if (data !== undefined) {
            dispatch(brokingActions.hydrateVolume(data));
          }
        }
      });
  }, [settleDate]);
  // console.log(matchMode, !changesPending);
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', padding: 8, height: 'auto', flexShrink: 1, justifyContent: 'flex-end' }}>
        <Box className={classes.clock} style={{ alignSelf: 'center', marginRight: 16 }} color='#F00'>
          NZ Time
        </Box>
        <DigitalClock type={'time'} frequency={10} className={classes.clock} />
        {/* <DigitalClock type={'countdown'} frequency={1} timerEnd={moment().add(1, 'hour').unix() * 1000} /> */}
      </div>
      {settleDate === null || !snapshot ? (
        <div style={{ display: 'flex', margin: 'auto', flexGrow: 1, alignItems: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Paper style={{ placeSelf: 'center', padding: 2, borderRadius: '8px', backgroundColor: '#000', flexGrow: 1, alignItems: 'center' }} elevation={2}>
          <Fade in={settleDate !== null} timeout={2500}>
            <table style={{ borderRadius: 8 }} className='eod'>
              <HeadersY settleDate={settleDate} labels={labels} data={snapshot} />
            </table>
          </Fade>
        </Paper>
      )}
      <div style={{ display: 'flex', flexDirection: 'row', padding: 16, justifyContent: 'space-around', height: 'max-content', marginTop: 'auto', gap: '16px' }}>
        <FormControlLabel control={<Switch checked={matchMode} onChange={toggleMode} name='mode' color='primary' />} label='Futures Match Submission Enabled' />
        <FormControlLabel control={<Switch disabled={!matchMode} checked={simpleView} onChange={toggleView} name='view' color='primary' />} label='Simple match view' />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            id='volume-step'
            label='Volume step (MW)'
            type='number'
            size={'small'}
            variant='outlined'
            value={matchVolumeStep}
            onChange={changeStep}
            inputProps={{
              min: 0,
              step: 0.1,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!matchMode}
          />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gap: 4, padding: 4 }}>
            <Button variant={'contained'} color='secondary' classes={{ root: classes.stepPreset }} disabled={!matchMode} onClick={setStep(1)}>
              1
            </Button>
            <Button variant={'contained'} color='secondary' classes={{ root: classes.stepPreset }} disabled={!matchMode} onClick={setStep(3)}>
              3
            </Button>
            <Button variant={'contained'} color='secondary' classes={{ root: classes.stepPreset }} disabled={!matchMode} onClick={setStep(5)}>
              5
            </Button>
          </div>
        </div>
        <Button variant='contained' color='primary' disabled={!matchMode || (matchMode && true)} className={classes.matchButton}>
          submit volumes
        </Button>
        <Button variant='contained' color='primary' disabled={!matchMode} className={classes.matchButton}>
          refer all volume
        </Button>
        <Button variant='contained' color='primary' disabled={!matchMode || (matchMode && true)} className={classes.matchButton}>
          discard changes
        </Button>
      </div>
    </>
  );
};
