import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import BarChartIcon from '@material-ui/icons/BarChart';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import TuneIcon from '@material-ui/icons/Tune';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import WebIcon from '@material-ui/icons/Web';
import StorageIcon from '@material-ui/icons/Storage';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import GavelIcon from '@material-ui/icons/Gavel';

import { theme } from 'style/theme';
import { NavLink } from 'react-router-dom';
import { SvgIconTypeMap, ClickAwayListener } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { StyledTooltip } from '../styled/StyledTooltip';
import { useAppSelector } from '../../redux/hooks';
import { drawerSelector } from 'redux/slice/app/selectors';
const drawerWidth = 240;

// eslint-disable-next-line @typescript-eslint/ban-types
const iconObject: Record<string, OverridableComponent<SvgIconTypeMap<{}, 'svg'>>> = {
  BarChartIcon,
  FormatListNumberedIcon,
  AddAlertIcon,
  TuneIcon,
  AnnouncementIcon,
  DashboardIcon,
  AccountBalanceIcon,
  NotificationsIcon,
  WebIcon,
  StorageIcon,
  DeviceHubIcon,

  GavelIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    // transition: 'width 1s ease-out;',
    transition: 'width 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',

    width: drawerWidth,
    overflow: 'hidden',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    transition: 'width 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',

    width: drawerWidth,
  },
  drawerClose: {
    transition: 'width 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',

    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    position: 'unset',
  },
}));

const MiniDrawer = React.forwardRef((props, ref) => {
  const classes = useStyles(theme);

  const drawerItems = useAppSelector(drawerSelector);

  const [open, setOpen] = React.useState(false);
  const setClosed = () => open && setOpen(false);

  const toggleDrawer = () => setOpen(state => !state);

  return (
    <ClickAwayListener onClickAway={setClosed}>
      <Drawer
        ref={ref}
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.paper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <List>
          <ListItem button onClick={toggleDrawer}>
            <ListItemIcon>{theme.direction === 'rtl' ? open ? <ChevronRightIcon /> : <ChevronLeftIcon /> : open ? <ChevronLeftIcon /> : <ChevronRightIcon />}</ListItemIcon>
          </ListItem>
        </List>
        <Divider />
        <List>
          <StyledTooltip title={'Navigate to homepage'}>
            <ListItem button component={NavLink} to={'/'}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={'Homepage'} />
            </ListItem>
          </StyledTooltip>
        </List>
        <Divider />
        <List>
          {drawerItems.map((item, i) => {
            const IconComponent = iconObject[`${item.icon}Icon`];
            return IconComponent ? (
              <StyledTooltip key={i} title={item.text}>
                <ListItem button component={NavLink} to={item.path}>
                  <ListItemIcon>
                    <IconComponent />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </StyledTooltip>
            ) : (
              <React.Fragment key={i}></React.Fragment>
            );
          })}
        </List>
      </Drawer>
    </ClickAwayListener>
  );
});

export { MiniDrawer };
