import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Page, Section } from 'components/Wrapper';
import { ElecTable, ProductType } from './Table';
// import { useGlobalData } from 'components/GlobalContext';

const useStyles = makeStyles(() =>
  createStyles({
    section: {
      width: '-webkit-fill-available',
      height: '-webkit-fill-available',
    },
  }),
);

export function Home(): JSX.Element {
  const classes = useStyles();

  return (
    <Page title={'NZ Electricity Dashboard'} rows={'1fr '} columns={'repeat(4, 1fr)'} columnCount={4}>
      <Section header={'MONTH'} childClassName={classes.section}>
        <ElecTable duration={'month'} type={ProductType.witsNodes} />
      </Section>
      <Section header={'QUARTER'} childClassName={classes.section}>
        <ElecTable duration={'quarter'} type={ProductType.witsNodes} />
      </Section>
      <Section header={'MONTH'} childClassName={classes.section}>
        <ElecTable duration={'month'} type={ProductType.witsBasisNodes} />
      </Section>
      <Section header={'QUARTER'} childClassName={classes.section}>
        <ElecTable duration={'quarter'} type={ProductType.witsBasisNodes} />
      </Section>
    </Page>
  );
}
