import { CircularProgress, FormControlLabel, FormGroup, makeStyles, Switch } from '@material-ui/core';
import React from 'react';
import { useSocket } from 'modules/websocket/hooks';
import { API, StatusCode } from 'types/pantheon/PantheonSocket';

const useStyles = makeStyles(() => ({
  control: {
    placeContent: 'center',
    paddingTop: 10,
    alignContent: 'space-evenly',
  },
}));

// query object respinse interface
interface BoldAussieAlertSettings {
  settings: { frequency: 'intraday' | 'daily' };
}

export const AlertTiming = (): JSX.Element => {
  const [timingSetting, querySettings] = useSocket<API.GetResponse<BoldAussieAlertSettings>, API.Request>('pantheon.api');
  const [updateResponse, sendUpdate] = useSocket<API.Response<unknown>, API.Request>('pantheon.api');
  const [isReady, setReady] = React.useState(false);
  const classes = useStyles();

  // func to get latest value
  const getLatest = () =>
    querySettings({
      type: 'API_REQUEST',
      method: 'get',
      schema: 'bold_services',
      table: 'janusModule',
      fields: ['settings'],
      fk: ['name', 'AUSSIE_ALERT'],
    });

  // pull data on first load
  React.useLayoutEffect(() => getLatest(), []);

  // process response when it changes
  React.useEffect(() => {
    if (timingSetting && Array.isArray(timingSetting.message)) {
      const data: BoldAussieAlertSettings = timingSetting.message[0];
      setDaily(data.settings.frequency === 'daily');
      if (!isReady) setReady(true);
    }
  }, [timingSetting, isReady]);

  // settings state
  const [isDaily, setDaily] = React.useState(false);

  // handle change (update db)
  const handleFrequencyChange = () => {
    const updatePacket: API.Request = {
      type: 'API_REQUEST',
      method: 'patch',
      schema: 'bold_services',
      table: 'janusModule',
      fk: ['name', 'AUSSIE_ALERT'],
      data: { settings: JSON.stringify({ frequency: !isDaily ? 'daily' : 'intraday' }) },
    };

    sendUpdate(updatePacket);
  };

  // if successful change, update value from DB (ensures correct value always shown)
  React.useEffect(() => {
    if (updateResponse?.code === StatusCode.OK) {
      getLatest();
    }
  }, [updateResponse]);

  // component
  return isReady ? (
    <FormGroup row className={classes.control}>
      <FormControlLabel control={<Switch checked={isDaily} onChange={handleFrequencyChange} name='Aussie outage Daily Only' inputProps={{ 'aria-label': 'report daily only checkbox' }} />} label='Aussie outage report - Once a day ' />
    </FormGroup>
  ) : (
    <CircularProgress />
  );
};
