import { SnackbarKey, useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import { appActions } from './slice';
import { useAppSelector } from './hooks';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

// import { removeSnackbar } from './redux/actions';
let displayed: SnackbarKey[] = [];

export const useNotifier = (): void => {
  const dispatch = useDispatch();
  const notifications = useAppSelector(store => store.app.notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  const withClose = (key: string, action?: () => void) => () => {
    closeSnackbar(key);
    action && action();
  };

  const snackDismiss = (key: string) => (
    <IconButton aria-label='confirm' onClick={() => closeSnackbar(key)} size={'small'}>
      <CloseIcon />
    </IconButton>
  );

  React.useEffect(() => {
    notifications.forEach(({ key, message, options = { variant: 'info', persist: false }, dismissed = false, custom }) => {
      const { closeButton, onConfirm, onCancel } = custom || {};

      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (!key || displayed.includes(key)) return;

      if (onConfirm)
        enqueueSnackbar(message, {
          variant: 'info',
          persist: false,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(appActions.removeSnackbar({ key: myKey }));
            removeDisplayed(myKey);
          },
          action: (key: string) => (
            <div>
              <IconButton aria-label='confirm' onClick={withClose(key, onConfirm)} size={'small'}>
                <CheckIcon />
              </IconButton>
              <IconButton aria-label='close' onClick={withClose(key, onCancel)} size={'small'}>
                <CloseIcon />
              </IconButton>
            </div>
          ),
        });
      // display snackbar using notistack
      else
        enqueueSnackbar(message, {
          key,
          ...options,
          action: closeButton ? snackDismiss : undefined,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(appActions.removeSnackbar({ key: myKey }));
            removeDisplayed(myKey);
          },
        });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};
