import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Page, Section } from 'components/Wrapper';
import { useAppDispatch } from 'redux/hooks';
import { fetchFuturesTable } from 'redux/slice/futures/futures';
import { MatchGrid } from './MatchGrid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {},
    inputRow: {
      display: 'flex',
      flexDirection: 'row',
      padding: 4,
      gap: '8px',
    },
    inputColumn: {
      display: 'flex',
      placeContent: 'space-evenly',
      width: 200,
      flexDirection: 'column',
      padding: 4,
      gap: '8px',
    },
    formControl: {
      margin: theme.spacing(1),
      textAlign: 'left',
    },
  }),
);

export function AlphaMatch(): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  console.log('match');
  React.useEffect(() => {
    dispatch(fetchFuturesTable('electricityNode'))
      .then((...args: unknown[]) => {
        console.log(args);
      })
      .catch(e => console.error(e));
  }, []);

  return (
    <Page title={'ASX FUTURES MATCH'} style={{ gridTemplateColumns: '100%' }} rows={'5rem 1fr'} columnCount={1}>
      <div style={{ display: 'grid', gap: '16px', gridTemplateColumns: '2fr 1fr' }}>
        <Section childClassName={classes.section}>
          <MatchGrid />
        </Section>
        <Section childClassName={classes.section}></Section>
      </div>
    </Page>
  );
}
