import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, InputLabel, Select, OutlinedInput, InputAdornment, MenuItem } from '@material-ui/core';
import { theme } from 'style/theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';
import { PaperItem } from 'components/styled/Grid';
import { StyledFlexContainer } from 'components/styled/StyledContainer';
import { fetchStackData } from './stackFetch';
import { StackDatePicker } from './StackDatePicker';
import { PriceStack, StackData, stackReducer, StackSeries } from './stackReducer';
import { EOSChart } from './StackChart';

const groupings = ['Participant', 'Node', 'Scheme'] as const;
type GroupingType = typeof groupings[number];

const styles = makeStyles({
  item: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    width: '-webkit-fill-available',
    textAlign: 'center',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
  },

  formControl: {
    margin: theme.spacing(1),
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: 200,
    textAlign: 'left',
  },
  input: {},
});

export const schemes = {
  Manapouri: ['MAN2201'],
  Clutha: ['ROX1101', 'ROX2201', 'CYD2201'],
  Waitaki: ['OHA2201', 'OHB2201', 'OHC2201', 'BEN2202', 'AVI2201', 'WTK0111'],
  Waikeromoana: ['TUI1101'], // ??? PRI0 & TUI0
  Tongariro: ['TKU2201'],
  Taranaki: ['MKE1101', 'SFD2201', 'JRD1101'], // ??? SFD21 & SPL0
  Huntly: ['HLY2201'], // ??? HLY 1 - 5
  Geothermal: ['KAW1101', 'OKI2201', 'NAP2201', 'NAP2202', 'WRK0331', 'WRK2201', 'THI2201', 'PPI2201'], // ??? WRK0331 = RKA0 & TAA0, THI2201 = THI 1 & 2
  'Waikato Hydro': ['ARA2201', 'OHK2201', 'ATI2201', 'WKM2201', 'MTI2201', 'WPA2201', 'ARI1101', 'ARI1102', 'KPO1101'], // ??? WKM2201 = MOK0 & WKM0
};

const getDateDiff = (start: moment.Moment, end: moment.Moment): number => end.diff(start, 'days');

interface ChartState {
  prices: PriceStack;
  series: StackSeries;
  startDate: moment.Moment;
  endDate: moment.Moment;
  groupings: {
    [key in GroupingType]: string[];
  };
}

type Actions =
  | {
      type: 'ADD';
      payload: StackData;
    }
  | {
      type: 'CHANGE_DATE';
      payload: {
        date: moment.Moment;
        target: keyof Pick<ChartState, 'startDate' | 'endDate'>;
      };
    }
  | { type: 'CLEAR' }
  | { type: 'RELOAD' }
  | { type: 'FILTER'; payload: number | '' };

const initialState: ChartState = {
  prices: {},
  series: {},
  startDate: moment().subtract(2, 'days').startOf('day'),
  endDate: moment().subtract(2, 'days').endOf('day'),
  groupings: {
    Participant: [],
    Node: [],
    Scheme: Object.keys(schemes),
  },
};

const getDates = (prices: PriceStack): string[] => {
  const dates = Object.keys(prices).reduce<string[]>((prev, curr) => {
    const date = moment(Number.parseFloat(curr) * 1000).format('YYYY-MM-DD');
    if (!prev.includes(date)) {
      prev.push(date);
    }
    return prev;
  }, []);
  return dates;
};

const missingDates = (prices: PriceStack, startDate: moment.Moment, endDate: moment.Moment): string[] => {
  const dates = getDates(prices);
  const missing = [];

  const diff = endDate.endOf('day').diff(startDate, 'days');

  for (let i = 0; i <= diff; i++) {
    const date = moment(startDate).add(i, 'days');

    if (!dates.includes(date.format('YYYY-MM-DD'))) {
      missing.push(date.format('YYYY-MM-DD'));
    }
  }
  return missing;
};

const distinctMerge = (a: string[], b: string[]): string[] => {
  const set = new Set([...a, ...b]);
  return Array.from(set);
};

const chartReducer = (state: ChartState, action: Actions) => {
  switch (action.type) {
    case 'ADD': {
      const prices = stackReducer(action.payload, state.prices);
      return {
        ...state,
        groupings: {
          Node: distinctMerge(state.groupings.Node, action.payload.nodes),
          Participant: distinctMerge(state.groupings.Participant, action.payload.participants),
          Scheme: state.groupings.Scheme,
        },
        series: pricesToSeries(prices, state.startDate, state.endDate),
        prices,
      };
    }
    case 'CHANGE_DATE': {
      const { date, target } = action.payload;
      const startDate = target === 'startDate' && date.startOf('day');
      const endDate = target === 'endDate' && date.endOf('day');

      return { ...state, [target]: startDate || endDate, series: pricesToSeries(state.prices, startDate || state.startDate, endDate || state.endDate) };
    }
    case 'CLEAR': {
      return { ...state, prices: {}, series: {} };
    }
    case 'FILTER': {
      return {
        ...state,
        series: pricesToSeries(state.prices, state.startDate, state.endDate, typeof action.payload === 'number' ? action.payload : null),
      };
    }
    default:
      return state;
  }
};

const filterSeries = (filter: number | '') => ({ type: 'FILTER' as const, payload: filter });

const addData = (prices: StackData) => ({ type: 'ADD' as const, payload: prices });
const changeDate = (date: moment.Moment, target: keyof Pick<ChartState, 'startDate' | 'endDate'>) => ({
  type: 'CHANGE_DATE' as const,
  payload: { date, target },
});
const clearData = () => ({ type: 'CLEAR' as const });

const pricesToSeries = (tps: PriceStack, startDate: moment.Moment, endDate: moment.Moment, filter?: number | null): StackSeries => {
  return Object.entries(tps).reduce<StackSeries>((prev, [tpStart, data]) => {
    const mark = Number.parseInt(tpStart, 10);
    const low = startDate.unix();
    const high = endDate.endOf('day').unix();

    if (mark < low || mark > high) return prev;
    const bands = data.band.entries();

    for (const [band, data] of bands) {
      if (filter && band > filter) return prev;

      if (!prev[band])
        prev[band] = {
          type: 'area',
          name: band.toString(),
          data: [],
          showInLegend: false,
        };

      if (!prev[band].data) prev[band].data = [];

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      prev[band].data!.push({ x: Number.parseInt(tpStart) * 1000, y: data.bandVolume });
    }

    return prev;
  }, {});
};

function OfferStack(): JSX.Element {
  const classes = styles();

  const [state, dispatch] = React.useReducer(chartReducer, initialState);
  const [groupBy, setGrouping] = React.useState<GroupingType>('Participant');
  const [currentGroupingValue, setGroupingValue] = React.useState<string>('CTCT');
  const [priceFilter, setPriceFilter] = React.useState<number | ''>('');

  const fetchDate = async (date: Moment | string) => {
    const mergeFilter = () => {
      switch (groupBy) {
        case 'Node':
          return { PointOfConnection: currentGroupingValue };
        case 'Participant':
          return { ParticipantCode: currentGroupingValue };
        case 'Scheme':
          return { PointOfConnection: schemes[currentGroupingValue as keyof typeof schemes] };
      }
    };

    return fetchStackData({
      code: 'xKEu6MkIfbxyxwI_9Absw9P0n5y2nFSQEVwv1DNEn2n1AzFuFUcmng==',
      type: 'offers',
      date: typeof date === 'string' ? date : date.format('YYYY-MM-DD'),
      ...mergeFilter(),
      ProductType: 'Energy',
      IsLatestYesNo: 'Y',
    });
  };

  const dateUpdated = (target: keyof Pick<ChartState, 'startDate' | 'endDate'>) => (date: Moment) => {
    dispatch(changeDate(date, target));
  };

  const update = () => {
    const datesToFetch = missingDates(state.prices, state.startDate, state.endDate);

    const res: StackData[] = [];
    datesToFetch.length > 0 &&
      Promise.all(datesToFetch.map(date => fetchDate(date).then(data => res.push(data))))
        .then(() => {
          res.map(data => dispatch(addData(data)));
        })
        .catch(e => console.error(e));
  };

  const fetchDateRun = (startDate: Moment, endDate: Moment) => {
    const datesToFetch = missingDates(state.prices, state.startDate, state.endDate);

    const dayCount = getDateDiff(startDate, endDate);
    for (let i = 0; i <= dayCount; i++) {
      const thisDay = moment(startDate).add(i, 'days');

      if (datesToFetch.includes(thisDay.format('YYYY-MM-DD')))
        fetchDate(moment(startDate).add(i, 'days'))
          .then(data => dispatch(addData(data)))
          .catch(e => console.error(e));
    }
  };

  const removePriceFilter = () => {
    setPriceFilter('');
    dispatch(filterSeries(''));
  };

  const handleGroupingValueChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    dispatch(clearData());
    setGroupingValue(event.target.value as string);
  };

  const updatePriceFilter: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = e => {
    e.target.value === '' ? setPriceFilter('') : setPriceFilter(Number.parseInt(e.target.value));
  };

  const applyPriceFilter = () => {
    dispatch(filterSeries(priceFilter));
  };

  const handleGroupingChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const newState = event.target.value as GroupingType;
    if (newState !== groupBy) {
      dispatch(clearData());
      switch (newState) {
        case 'Node': {
          setGroupingValue('');
          setGrouping('Node');
          break;
        }
        case 'Participant': {
          setGroupingValue('');
          setGrouping('Participant');
          break;
        }
        case 'Scheme': {
          setGroupingValue('');
          setGrouping('Scheme');

          break;
        }
      }
    }
  };

  React.useEffect(() => {
    if (state.groupings.Node.length === 0) fetchDateRun(moment().subtract(2, 'days'), moment().subtract(2, 'days'));
  }, [state.groupings]);

  const groupingSelector = React.useMemo(() => {
    return (
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel id='company-select-label'>{groupBy}</InputLabel>
        <Select labelId='company-select-label' id='company-select' value={currentGroupingValue} onChange={handleGroupingValueChange} label={groupBy}>
          {state.groupings[groupBy].sort().map((item: string) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }, [groupBy, currentGroupingValue, state.groupings]);

  return (
    <StyledFlexContainer>
      <EOSChart
        header='NZ Electricity Offer stacks'
        style={{ flexGrow: 1, width: '100%', height: '100%', padding: theme.spacing(2), textAlign: 'center', color: theme.palette.text.primary, whiteSpace: 'nowrap' }}
        seriesData={state.series} //eosData && eosData[currentGroupingValue]
        date={[state.startDate.format('DD-MM-YYYY'), state.endDate.format('DD-MM-YYYY')]}
        grouping={currentGroupingValue}
        extraOptions={{ backgroundColor: theme.palette.background.default }}
      />
      <PaperItem flex className={classes.paper} style={{ flexFlow: 'wrap', minHeight: 100, justifyContent: 'space-evenly', gap: '16px' }}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'en'}>
          <StackDatePicker label={'Start Date'} initial={state.startDate} onChange={dateUpdated('startDate')} />
          <StackDatePicker label={'End Date'} initial={state.endDate} onChange={dateUpdated('endDate')} />
        </MuiPickersUtilsProvider>
        {groupingSelector}

        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='grouping-select-label'>Grouping</InputLabel>
          <Select labelId='grouping-select-label' id='grouping-select' value={groupBy} onChange={handleGroupingChange} label='Grouping'>
            {groupings.map((group: string) => (
              <MenuItem key={group} value={group}>
                {group}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} variant='outlined'>
          <InputLabel id='filter-input-label'>Exclude price bands above</InputLabel>
          <OutlinedInput id='filter-input' type={'number'} label={'Exclude price bands above'} value={priceFilter} onChange={updatePriceFilter} startAdornment={<InputAdornment position='start'>$</InputAdornment>} />
        </FormControl>
        <Button variant={'contained'} color={'primary'} onClick={applyPriceFilter} style={{ height: 'min-content', alignSelf: 'center' }}>
          Filter data
        </Button>
        {priceFilter && (
          <Button variant={'contained'} color={'secondary'} onClick={removePriceFilter} style={{ height: 'min-content', alignSelf: 'center' }}>
            Remove filter
          </Button>
        )}
        <Button variant='contained' color='primary' onClick={update}>
          Get Electricity offer stack data
        </Button>
      </PaperItem>
    </StyledFlexContainer>
  );
}
// onClick={getEOS}
export { OfferStack };
