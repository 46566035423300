import Highcharts from 'highcharts';
import { theme } from 'style/theme';
import FullScreen from 'highcharts/modules/full-screen';
import Exporting from 'highcharts/modules/exporting';

// apply modules
FullScreen(Highcharts);
Exporting(Highcharts);

const fontStyle: Highcharts.CSSObject = {
  fontSize: '11pt',
  color: '#000',
  fill: '#000',
  fontFamily: `'Centra No 2 Medium'`,
};

const axisLabelStyle: Highcharts.CSSObject = {
  color: '#000',
  fill: '#000',
};

export const globalOptions: Highcharts.Options = {
  time: {
    useUTC: false,
    timezone: 'Pacific/Auckland',
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  chart: {
    backgroundColor: theme.palette.background.paper,
    style: fontStyle,
    panning: {
      enabled: true,
      type: 'x',
    },
  },
  loading: {
    hideDuration: 1000,
    showDuration: 1000,
    style: {
      backgroundColor: 'rgba(106,151,189, 0.3)',
    },
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    crosshair: false,
    labels: {
      align: 'center',
      style: {
        ...axisLabelStyle,
      },
    },
    title: {
      style: {
        ...axisLabelStyle,
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        ...axisLabelStyle,
      },
    },
    gridLineColor: '#ccc',
    gridLineWidth: 1,
    crosshair: false,
    title: {
      style: {
        ...axisLabelStyle,
      },
    },
  },
  lang: {
    numericSymbols: [' k', ' M', ' B', ' T'],
  },
  plotOptions: {
    line: {
      lineWidth: 1,
    },
    series: {
      turboThreshold: 10000,
      connectNulls: false,
      animation: false,
      marker: {
        enabled: false,
      },
    },
  },
  title: {
    align: 'left',
    style: {
      ...fontStyle,
    },
  },
  legend: {
    enabled: true,
  },
};

Highcharts.setOptions(globalOptions);
