import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Divider, Grid, Theme } from '@material-ui/core';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { UberIconButton } from 'components/UberIconButton';
import { GridRowData, GridRowId, useGridSlotComponentProps } from '@material-ui/x-grid';
import { useSocketEmit } from 'modules/websocket/hooks';
import { useAppDispatch } from 'redux/hooks';
import { appActions } from 'redux/slice/app/app';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbarRoot: {
      width: '-webkit-fill-available',
      borderWidth: `0px 0px 1px 0px`,
      borderStyle: `solid`,
      borderColor: theme.palette.divider,
      borderRadius: `0px`,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
    },
  }),
);

const ProofToolbar = (closeModal: () => void) => (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { apiRef } = useGridSlotComponentProps();
  const [emit] = useSocketEmit('pantheon.api');

  // action wrapper to check selection. uses action for rows, or alerts if none are selected
  const actionWithSelection = (action: () => void) => () => {
    const selected = apiRef.current.getSelectedRows();
    selected.size === 0 ? dispatch(appActions.WARNING('You must have selected some rows for this action')) : action();
  };

  const onConfirm = () => {
    const rows = apiRef.current.getSelectedRows();

    emit(
      {
        type: 'API_REQUEST',
        method: 'patch',
        schema: 'ae',
        table: 'ticket',
        id: Array.from(rows).map((trade: [GridRowId, GridRowData]) => +trade[0]),
        data: { isProofed: true },
      },
      () => closeModal(),
    );
  };

  return (
    <Grid container alignItems='center' classes={{ root: classes.toolbarRoot }}>
      <UberIconButton onClick={actionWithSelection(() => dispatch(appActions.CONFIRM({ message: 'Mark selected trades as proofed?', onConfirm })))} tooltip={'Mark selected trades as proofed'} Icon={PlaylistAddCheckIcon} />
      <Divider orientation='vertical' flexItem />
    </Grid>
  );
};

export { ProofToolbar };
