import { Action, createAsyncThunk, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/reducer';
import { sbActions } from './actions';
import { initialiseClient } from './lib/busSession';
import { SocketDispatch } from 'redux/store';
import { ServiceBusClient, ServiceBusSessionReceiver } from '@azure/service-bus';

export enum ServiceBusThunkAction {
  SB_INIT,
}

export type ServiceBusThunkActionType = keyof typeof ServiceBusThunkAction;
export type ServiceBusThunkActions = Action<ServiceBusThunkActionType>;

export type ServuceBusThunks = ThunkAction<ServiceBusClient, RootState, unknown, Action<'SB_INIT'>>;

export type SessionParameters = {
  topic: string;
  subscription: string;
};

type BusThunkAPIConfig = {
  state: RootState;
  dispatch: SocketDispatch;
};

export const initiliseBusClient = createAsyncThunk<ServiceBusClient, void, BusThunkAPIConfig>('serviceBus/SB_INIT', async (_, thunkAPI) => {
  const client = await initialiseClient();
  thunkAPI.dispatch(sbActions.SB_STARTED(client.identifier));
  return client;
});

export const serviceBusSession = (client: ServiceBusClient) =>
  createAsyncThunk<ServiceBusSessionReceiver, SessionParameters, BusThunkAPIConfig>('serviceBus/SB_INIT', async ({ topic, subscription }, thunkAPI) => {
    const session = await client.acceptSession(topic, subscription);
    thunkAPI.dispatch(sbActions.SB_SESSION_STARTED(session.sessionId));
    return session;
  });
