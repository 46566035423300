import { ModalProps } from '@material-ui/core';
import { PaperItemProps } from 'components/styled';
import React, { createContext, PropsWithChildren, useContext, useState, useRef } from 'react';
import { ModalContainer } from './ModalContainer';

export type EpicModalProps = ModalProps & {
  children: JSX.Element;
  paperItemProps?: PaperItemProps;
};

type ProviderContext = readonly [(option: EpicModalOption) => void, () => void];

const ModalContext = createContext<ProviderContext>(null as unknown as ProviderContext);

type EpicModalOption = Omit<EpicModalProps, 'open'>;

// eslint-disable-next-line @typescript-eslint/ban-types
export function ModalProvider({ children }: PropsWithChildren<{}>): JSX.Element {
  const [modal, setModal] = useState<EpicModalProps | undefined>();

  const createModal = (option: EpicModalOption) => {
    const modal = { ...option, open: true };
    setModal(modal);
  };

  const closeModal = () => {
    setModal(undefined);
  };

  const contextValue = useRef([createModal, closeModal] as const);
  const { children: modalChildren, open, ...modalProps } = modal || {};
  return (
    <ModalContext.Provider value={contextValue.current}>
      {children}
      {modal && open && modalChildren && (
        <ModalContainer closeModal={closeModal} open={open} {...modalProps}>
          {modalChildren}
        </ModalContainer>
      )}
    </ModalContext.Provider>
  );
}

export function useModal(): ProviderContext {
  const result = useContext(ModalContext);
  if (!result) {
    throw new Error('Modal context is only available inside its provider');
  }
  return result;
}
