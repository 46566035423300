import moment from 'moment';
export type BracketType = '[]' | '{}' | '()';

export const leadingZeroPad = (n: number | string): number | string => (n < 10 ? `0${n}` : n);

export const ts = (brackets: BracketType): string => brackets[0] + moment().format('DD-MM-YYYY HH:mm:ss') + brackets[1];

export const tsPrepend = (original: string, brackets: BracketType = '[]'): string => ts(brackets) + '-' + original;

export const customZeroPad = (digits: number, source: number | string): string => (source.toString().length < digits ? `${'0'.repeat(digits - source.toString().length)}${source}` : source).toString();

export const timerParse = (reference: number, precision?: number, noPrefix?: boolean): string => {
  const diff = moment().diff(moment(reference), 'ms');
  const absDiff = Math.abs(diff);
  const freqToPrecision = precision || 100;
  const prefix = diff > 0 || noPrefix ? '' : '-';
  const ms = absDiff % 1000;

  const subSecond = Math.floor(ms / freqToPrecision);
  const seconds = Math.floor((absDiff / 1000) % 60);
  const minutes = Math.floor((absDiff / 60000) % 60);

  const str = `${prefix}${leadingZeroPad(minutes)}:${leadingZeroPad(seconds)}`;

  if (!precision || precision === 1) return str;
  return str + '.' + customZeroPad(precision / 1000, subSecond);
};

export const withCommas = (input: number): string => input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
