export enum Product {
  GAS = 2,
  CARBON = 3,
  ELECTRICITY = 4,
}
export enum Venue {
  ETP = 1,
  SFE = 3,
  OTC = 4,
  HSA = 5,
}

export enum ContractType {
  CFD = 1,
  FTR,
  FUTURES,
  PHYSICAL,
  EFP,
  REPO,
  CREDIT,
  TSWAP,
}

export type FTRType = 'OPT' | 'OBL';
