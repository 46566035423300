import { makeStyles, Theme, createStyles, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, IconButton } from '@material-ui/core';
import moment from 'moment';
import React, { PropsWithChildren } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { fetchJSON } from 'redux/slice/futures/futures';
import clsx from 'clsx';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
export type TableContentCell = string | number;

export type TableContentRow = TableContentCell[];

// declare module 'react' {
//   interface EventTarget {
//     childNodes: NodeList;
//   }
// }
const rowColours: {
  [key: string]: {
    even: {
      backgroundColor: string;
      color: string;
    };
    odd: {
      backgroundColor: string;
      color: string;
    };
  };
} = {
  sell: {
    even: {
      backgroundColor: '#cc0000',
      color: '#fff',
    },
    odd: {
      backgroundColor: '#ff0000',
      color: '#fff',
    },
  },
  buy: {
    even: {
      backgroundColor: '#003399',
      color: '#fff',
    },
    odd: {
      backgroundColor: '#3333ff',
      color: '#fff',
    },
  },
  settle: {
    even: {
      backgroundColor: '#aeaaaa',
      color: '#000',
    },
    odd: {
      backgroundColor: '#d0cece',
      color: '#000',
    },
  },
  MONTHS: {
    even: {
      backgroundColor: '#a9d08e',
      color: '#000',
    },
    odd: {
      backgroundColor: '#c6e0b4',
      color: '#000',
    },
  },
  QUARTERS: {
    even: {
      backgroundColor: '#8ea9db',
      color: '#000',
    },
    odd: {
      backgroundColor: '#b4c6e7',
      color: '#000',
    },
  },
  YEARS: {
    even: {
      backgroundColor: '#ffd966',
      color: '#000',
    },
    odd: {
      backgroundColor: '#ffe699',
      color: '#000',
    },
  },
};

const verticalLabelColours: {
  [key: string]: {
    backgroundColor: string;
    color: string;
  };
} = {
  YEARS: {
    backgroundColor: '#bf8f00',
    color: '#fff',
  },

  MONTHS: {
    backgroundColor: '#548235',
    color: '#fff',
  },
  QUARTERS: {
    backgroundColor: '#305496',
    color: '#fff',
  },
};

const makeRowStyle = (type: string, rowIndex: number) => rowColours[type]?.[rowIndex % 2 === 0 ? 'even' : 'odd'] || {};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    rowHeader: {
      border: '1px solid black',
      padding: '16px',
      fontWeight: 'bold',
      backgroundColor: '#808080',
      color: '#fff',
    },
    spacerCell: {
      backgroundColor: 'inherit !important',
      border: '0px solid black !important',
      minWidth: '12px',
    },
    cont: {
      padding: 40,
    },
    contractButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: '2px',
      width: 'min-content',
      marginTop: 4,
      marginBottom: 4,
    },
    controlCell: {
      padding: '0px !important',
    },

    tableContainer: {
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
    },
    table: {
      width: 'auto',
      '& .MuiTableCell-root': {
        fontFamily: '"Segoe UI", sans-serif',
        padding: '4px 16px',
      },
    },
    verticalLabel: {
      writingMode: 'vertical-lr',
      transform: 'rotate(180deg)',
      textAlign: 'center',
      // padding: '0px !important',
      fontSize: '16pt',
      fontWeight: 700,
      border: '1px solid black',
    },
    outputCell: {
      padding: '8px',
      textAlign: 'center',
      border: '1px solid black',
    },
    numberCell: {
      padding: '8px',
      border: '1px solid black',
      textAlign: 'center',
      minWidth: '24px',
    },
    textCell: {
      padding: '8px',
      border: '1px solid black',
    },
  }),
);
type ContentRow = {
  label: string;
  start: string;
  settles: [number, number, number];
  buy: [number, number, number];
  sell: [number, number, number];
};

type ContractSectionProps = {
  section?: string;
  content?: ContentRow[];
};

type MatchSections = Record<string, ContractSectionProps>;

const ContractSection = (props: ContractSectionProps): JSX.Element => {
  const { section, content } = props;
  if (!section || !content) return <></>;
  const mouseExitDiv = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    console.log('------exit--------');
    console.log(event.target);
  };

  const classes = useStyles();

  return (
    <>
      {content.map((row, i) => {
        return (
          <TableRow style={{ height: '18px' }} key={section + i.toString()}>
            {i === 0 && (
              <TableCell className={classes.verticalLabel} align='left' style={verticalLabelColours[section]} rowSpan={content.length}>
                {section}
              </TableCell>
            )}
            <TableCell className={classes.textCell} style={makeRowStyle(section, i)} align='left'>
              {row.label}
            </TableCell>
            {[...row.settles].map((cell, j) => {
              return (
                <React.Fragment key={'cell' + section + j.toString()}>
                  <TableCell className={clsx(classes.textCell, classes.spacerCell, classes.controlCell)} align='center'>
                    <div className={classes.contractButtonContainer} style={{ display: 'flex', paddingRight: 0, backgroundColor: 'green', borderTopLeftRadius: 12, borderBottomLeftRadius: 12, marginLeft: 4 }}>
                      <IconButton color='primary' aria-label='decrease' size={'small'}>
                        <RemoveIcon />
                      </IconButton>
                      <IconButton color='primary' aria-label='increase' size={'small'}>
                        <AddIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell className={classes.numberCell} style={makeRowStyle('buy', i)} align='left'>
                    {row.buy[j] > 0 && row.buy[j]}
                  </TableCell>
                  <TableCell className={classes.numberCell} style={makeRowStyle('settle', i)} align='left'>
                    {cell.toFixed(2)}
                  </TableCell>
                  <TableCell
                    onMouseOver={event => {
                      console.log('-----enter---------');
                      console.log((event.target as HTMLTableCellElement).classList);
                    }}
                    onMouseOut={mouseExitDiv}
                    className={classes.numberCell}
                    style={makeRowStyle('sell', i)}
                    align='left'
                  >
                    {row.sell[j] > 0 && row.sell[j]}
                  </TableCell>
                  <TableCell className={clsx(classes.textCell, classes.spacerCell, classes.controlCell)} align='center'>
                    <div className={classes.contractButtonContainer} style={{ display: 'flex', paddingLeft: 0, backgroundColor: 'red', borderTopRightRadius: 12, borderBottomRightRadius: 12, marginRight: 4 }}>
                      <IconButton color='primary' aria-label='decrease' size={'small'}>
                        <RemoveIcon />
                      </IconButton>
                      <IconButton color='primary' aria-label='increase' size={'small'}>
                        <AddIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </React.Fragment>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
};

const HeaderSection = (props: PropsWithChildren<{ classes: ClassNameMap<string> }>) => (
  <>
    <TableCell className={clsx(props.classes.rowHeader, props.classes.spacerCell)} align='center'>
      {' '}
    </TableCell>
    <TableCell className={props.classes.rowHeader} align='center' colSpan={3}>
      {props.children}
    </TableCell>
    <TableCell className={clsx(props.classes.textCell, props.classes.spacerCell)} align='center'>
      {' '}
    </TableCell>
  </>
);

const HeaderRowGHroup = (props: { classes: ClassNameMap<string> }) => (
  <>
    <TableCell className={clsx(props.classes.rowHeader, props.classes.spacerCell)} align='center'>
      {' '}
    </TableCell>
    <TableCell className={props.classes.rowHeader} align='center'>
      BUY
    </TableCell>
    <TableCell className={props.classes.rowHeader} align='center'>
      SETTLE
    </TableCell>
    <TableCell className={props.classes.rowHeader} align='center'>
      SELL
    </TableCell>
    <TableCell className={clsx(props.classes.textCell, props.classes.spacerCell)} align='center'>
      {' '}
    </TableCell>
  </>
);
export const MatchGrid = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [sections, setSettles] = React.useState<MatchSections>({});

  React.useEffect(() => {
    dispatch(fetchJSON<MatchSections>()('futuresGrid_json'))
      .unwrap()
      .then(response => {
        response.months.content?.sort((a, b) => (moment(a.start, 'YYYY-MM-DD').isBefore(b.start) ? -1 : 1));
        response.years.content?.sort((a, b) => (moment(a.start, 'YYYY-MM-DD').isBefore(b.start) ? -1 : 1));
        response.quarters.content?.sort((a, b) => (moment(a.start, 'YYYY-MM-DD').isBefore(b.start) ? -1 : 1));

        setSettles(response);
      })
      .catch(e => console.error(e));
  }, []);

  return (
    <div className={classes.cont}>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow style={{ height: '18px' }}>
              <TableCell className={clsx(classes.rowHeader)} style={{ border: '0px solid black', background: 'unset' }} align='center' colSpan={2}>
                {' '}
              </TableCell>

              <HeaderSection classes={classes}>OTA</HeaderSection>
              <HeaderSection classes={classes}>BEN</HeaderSection>
              <HeaderSection classes={classes}>BASIS</HeaderSection>
            </TableRow>
            <TableRow style={{ height: '18px' }}>
              <TableCell className={clsx(classes.rowHeader)} style={{ border: '0px solid black', background: 'unset' }} align='center' colSpan={2}>
                {' '}
              </TableCell>
              {[0, 1, 2].map(i => (
                <HeaderRowGHroup classes={classes} key={`header${i}`} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <ContractSection {...sections.months} />
            <ContractSection {...sections.quarters} />
            <ContractSection {...sections.years} />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
