import { InputLabel, Select, TextField } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useAppSelector } from 'redux/hooks';
import { getPortalFieldItems } from '../selectElement';
import { TicketFormControl } from '../styles';
import { TicketRow } from './TicketRow';

export const ProductInput = ({
  controllerClassName,
  productID,
  venueID,
  lockProduct = false,
  lockVenue = false,
}: {
  controllerClassName?: string;
  productID?: number;
  venueID?: number;
  lockProduct?: boolean;
  lockVenue?: boolean;
}): JSX.Element => {
  const { control } = useFormContext();
  const products = useAppSelector(state => getPortalFieldItems<'product'>(state, 'product', { productID }));
  const venues = useAppSelector(state => getPortalFieldItems<'venue'>(state, 'venue', { products: productID, venueID }));

  return (
    <TicketRow>
      <Controller
        name='TradeTime'
        control={control}
        render={({ field }) => (
          <TextField
            id='tradeTime'
            size={'small'}
            label='Trade Time & Date'
            type='datetime-local'
            variant={'outlined'}
            className={controllerClassName}
            InputLabelProps={{
              shrink: true,
            }}
            {...field}
          />
        )}
      />
      <Controller
        name='ProductID'
        control={control}
        render={({ field }) => (
          <TicketFormControl>
            <InputLabel id='product-label'>Product Category</InputLabel>
            <Select labelId='product-label' id='product' label='Product Category' inputProps={{ readOnly: lockProduct }} {...field}>
              {products}
            </Select>
          </TicketFormControl>
        )}
      />
      <Controller
        name='VenueID'
        control={control}
        render={({ field }) => (
          <TicketFormControl>
            <InputLabel id='venue-label'>Trade Venue</InputLabel>
            <Select labelId='venue-label' id='venue' label='Trade Venue' inputProps={{ readOnly: lockVenue }} {...field}>
              {venues}
            </Select>
          </TicketFormControl>
        )}
      />
    </TicketRow>
  );
};
