import { colors } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

const spacingSetting = 0.5;

const gridStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainRoot: {
      // backgroundColor: theme.palette.grey[50],
      '& .MuiDataGrid-columnHeaderWrapper': {
        backgroundColor: theme.palette.grey[50],
      },
      '& .MuiDataGrid-columnHeader': {
        padding: `0px ${theme.spacing(spacingSetting)}px 0px ${theme.spacing(spacingSetting)}px`,
      },
      '& .MuiDataGrid-cell': {
        padding: `0px ${theme.spacing(spacingSetting)}px 0px ${theme.spacing(spacingSetting)}px`,
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: `0px ${theme.spacing(spacingSetting)}px 0px ${theme.spacing(spacingSetting)}px`,
      },
      '& .MuiDataGrid-booleanCell[data-value="true"]': {
        color: colors.green[500],
      },
      '& .MuiDataGrid-booleanCell[data-value="false"]': {
        color: colors.red[500],
      },
      '& .Mui-even': {
        backgroundColor: theme.palette.background.paper,
      },
      '& .Mui-odd': {
        backgroundColor: theme.palette.grey[200],
      },
      '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: colors.orange[50],
        '&:hover': {
          backgroundColor: colors.orange[100],
        },
      },
      '& .MuiDataGrid-row': {
        '&:hover': {
          backgroundColor: colors.amber[50],
        },
      },
    },
    modalRoot: {
      margin: '0px 2px 2px 2px',
      border: '0px',
      borderRadius: `0px 0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
      backgroundColor: theme.palette.grey[50],
      '& .MuiDataGrid-columnHeader': {
        padding: `0px ${theme.spacing(spacingSetting)}px 0px ${theme.spacing(spacingSetting)}px`,
      },
      '& .MuiDataGrid-cell': {
        padding: `0px ${theme.spacing(spacingSetting)}px 0px ${theme.spacing(spacingSetting)}px`,
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: `0px ${theme.spacing(spacingSetting)}px 0px ${theme.spacing(spacingSetting)}px`,
      },
      '& .MuiDataGrid-booleanCell[data-value="true"]': {
        color: colors.green[500],
      },
      '& .MuiDataGrid-booleanCell[data-value="false"]': {
        color: colors.red[500],
      },
      '& .Mui-even': {
        backgroundColor: theme.palette.background.paper,
      },
      '& .Mui-odd': {
        backgroundColor: theme.palette.grey[200],
      },
      '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: colors.orange[50],
        '&:hover': {
          backgroundColor: colors.orange[100],
        },
      },
      '& .MuiDataGrid-row': {
        '&:hover': {
          backgroundColor: colors.amber[50],
        },
      },
    },
  }),
);

export { gridStyles };
