import { IconButton } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { StyledTooltip } from 'components/styled/StyledTooltip';

// eslint-disable-next-line @typescript-eslint/ban-types
export const DownloadCSV = ({ module, id, tooltip, children }: PropsWithChildren<{ module: string; tooltip?: string; id: string | number }>): JSX.Element => (
  <a href={`/pantheon/csv?module=${module}&id=${id}`} rel='noreferrer' download target='_blank'>
    <StyledTooltip title={tooltip || 'Download as CSV'}>
      <IconButton edge='end' aria-label='download'>
        {children}
      </IconButton>
    </StyledTooltip>
  </a>
);
