import { makeStyles, Theme, createStyles, Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@material-ui/core';
import React from 'react';
import { getUniqueNames } from './context/util';
import { FuturesDurationType, MatchContractVolumeState, MatchDefinitionsByNode, MatchVolumeState } from 'types/match.types';
import { matchContractsStateSelector, matchDefinitionsSelector, matchContractDefinition } from 'redux/slice/match/selectors';
import { useAppSelector } from 'redux/hooks';
import CheckIcon from '@material-ui/icons/Check';
// import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import { lightGreen, green } from '@material-ui/core/colors';

export type TableContentCell = string | number;

export type TableContentRow = TableContentCell[];

// const useOutputCell = makeStyles<Theme>((theme: Theme) =>
//   createStyles({
//     evenRow: {
//       backgroundColor: theme.palette.background.paper,
//     },
//   }),
// );

const useRowStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    verticalLabel: {
      writingMode: 'vertical-lr',
      transform: 'rotate(180deg)',
      textAlign: 'center',
      // padding: '0px !important',
      fontSize: '16pt',
      fontWeight: 700,
      border: '1px solid black',
    },
    outputCell: {
      padding: '0px 8px 0px 8px',
      textAlign: 'center',
      border: '1px solid black',
    },
    numberCell: {
      padding: '0px 8px 0px 8px',
      border: '1px solid black',
    },
    textCell: {
      padding: '0px 8px 0px 8px',
      border: '1px solid black',
    },
    evenRow: {
      backgroundColor: theme.palette.background.paper,
    },
    oddRow: {
      backgroundColor: theme.palette.grey[300],
    },
  }),
);

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    rowHeader: {
      border: '1px solid black',
      padding: '0px 16px',
      fontWeight: 'bold',
    },

    tableContainer: {
      paddingBottom: 10,

      overflow: 'hidden',
    },
    table: {
      tableLayout: 'fixed',
      '& .MuiTableCell-root': {
        fontFamily: '"Segoe UI", sans-serif',
      },
    },
  }),
);

const MatchInterst = ({ state, className }: { state: MatchVolumeState; className: string }) => {
  return (
    <TableCell className={className} align='center' style={{ backgroundColor: state ? (state === 'small' ? lightGreen[100] : green[100]) : '' }}>
      {state && (state === 'small' ? '< 3MW' : <CheckIcon fontSize={'small'} style={{ color: green[700], alignSelf: 'center', verticalAlign: 'middle' }} />)}
    </TableCell>
  );
};

const ContractSettleCell = ({ state, className }: { state: MatchContractVolumeState; className: string }) => (
  <TableCell className={className} align='right'>
    {state.settle.price.toFixed(2)}
    {state.settle.isImplied && '*'}
  </TableCell>
);

const MatchRow = ({ verticalLabel, sectionRows, dateFormat, otaId, benId }: { verticalLabel: string | false; sectionRows: number; dateFormat?: string; otaId: number; benId: number }) => {
  const classes = useRowStyles();
  const otaState = useAppSelector(state => matchContractsStateSelector(state, otaId));
  const benState = useAppSelector(state => matchContractsStateSelector(state, benId));
  const otaDef = useAppSelector(state => matchContractDefinition(state, otaId));

  return (
    <TableRow style={{ height: 24 }}>
      {verticalLabel && (
        <TableCell className={classes.verticalLabel} align='left' rowSpan={sectionRows}>
          {`${verticalLabel}S`}
        </TableCell>
      )}
      <TableCell className={classes.textCell} align='left'>
        {otaDef &&
          (otaDef.displayName
            ? otaDef.displayName.toUpperCase()
            : moment(otaDef.expiry)
                .format(dateFormat || 'YYYY-MM-DD')
                .toUpperCase())}
      </TableCell>
      {otaState && <ContractSettleCell state={otaState} className={classes.numberCell} />}
      {benState && <ContractSettleCell state={benState} className={classes.numberCell} />}

      <TableCell className={classes.numberCell} align='right'>
        {(otaState?.settle.price - benState?.settle.price).toFixed(2)}
        {(otaState?.settle.isImplied || benState?.settle.isImplied) && '*'}
      </TableCell>
      {otaState && <MatchInterst state={otaState.bid} className={classes.outputCell} />}
      {benState && <MatchInterst state={benState.bid} className={classes.outputCell} />}
      <TableCell className={classes.outputCell} align='right'>
        ??
      </TableCell>

      {otaState && <MatchInterst state={otaState.ask} className={classes.outputCell} />}
      {benState && <MatchInterst state={benState.ask} className={classes.outputCell} />}
      <TableCell className={classes.outputCell} align='right'>
        ??
      </TableCell>
    </TableRow>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MemoContent = React.memo(({ duration, dateFormat, data }: { duration: FuturesDurationType; dateFormat: string; data: MatchDefinitionsByNode }) => {
  const names = getUniqueNames([...data.ben[duration], ...data.ota[duration]]);
  return (
    <>
      {names.map((name, index) => {
        const ben = data.ben[duration].find(contract => contract.displayName === name);
        const ota = data.ota[duration].find(contract => contract.displayName === name);
        if (!ben || !ota) return null;

        return <MatchRow key={`${duration}-${index}`} verticalLabel={index === 0 && duration.toUpperCase()} sectionRows={names.length} otaId={ota.ID} benId={ben.ID} />;
      })}
    </>
  );
});

// const makeSyntheticTableContent = (duration: FuturesDurationType, data: MatchDefinitionsByNode): (JSX.Element | null)[] => {
//   const names = getUniqueNames([...data.ben[duration], ...data.ota[duration]]);

//   return names.map((name, index) => {
//     const ben = data.ben[duration].find(contract => contract.displayName === name);
//     const ota = data.ota[duration].find(contract => contract.displayName === name);
//     if (!ben || !ota) return null;
//     return <MatchRow key={`${duration}-${index}`} verticalLabel={index === 0 && duration.toUpperCase()} sectionRows={names.length} otaId={ota.ID} benId={ben.ID} />;
//   });
// };

export const MatchGrid = (): JSX.Element => {
  const classes = useStyles();
  const { definitions } = useAppSelector(state => matchDefinitionsSelector(state));

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow style={{ height: '24px' }}>
            <TableCell className={classes.rowHeader} align='center' colSpan={2}></TableCell>
            <TableCell className={classes.rowHeader} align='center' colSpan={3}>
              SETTLE
            </TableCell>
            <TableCell className={classes.rowHeader} align='center' colSpan={3}>
              BUY
            </TableCell>
            <TableCell className={classes.rowHeader} align='center' colSpan={3}>
              SELL
            </TableCell>
          </TableRow>
          <TableRow style={{ height: '24px' }}>
            <TableCell className={classes.rowHeader} align='center'>
              {' '}
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              Contract
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              OTA
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              BEN
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              BASIS
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              OTA
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              BEN
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              BASIS
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              OTA
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              BEN
            </TableCell>
            <TableCell className={classes.rowHeader} align='center'>
              BASIS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <MemoContent duration='month' dateFormat='MMM-YY' data={definitions} />
          <MemoContent duration='quarter' dateFormat='[Q]Q-YY' data={definitions} />
          <MemoContent duration='year' dateFormat='[CAL]-YY' data={definitions} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};
