import { createStyles, FormControlLabel, makeStyles, Switch, TextField, Theme } from '@material-ui/core';
import { withCommas } from 'lib/helpers';
import { useRPC } from 'modules/websocket/hooks';
import moment from 'moment';
import React, { ChangeEvent } from 'react';
import { Control, Controller, Path, useFormContext } from 'react-hook-form';
import { TicketTypes, TicketInterfaceMap } from 'types/Ticket.types';
import { SQLFunctionPayload } from '../../../../../types/pcp.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      flex: '1 1 0px',
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
      minWidth: '10%',
      textAlign: 'left',
    },
    formControlLabel: {
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  }),
);

export const DateBlockInput = <T extends TicketInterfaceMap[TicketTypes]>({ control, hours = false, days = false }: { control: Control<T, object>; days?: boolean; hours?: boolean }): JSX.Element => {
  const classes = useStyles();
  const [customDates, setCustomDates] = React.useState(false);
  const emit = useRPC();

  const toggleCustomDates = () => setCustomDates(!customDates);
  const { watch, setValue } = useFormContext();
  const volume = watch('Volume') as number;
  const price = watch('Price') as number;
  const startDate = watch('StartDate') as string;
  const endDate = watch('EndDate') as string;

  const instrument = watch('reference_instrumentID') as number;
  const schedule = watch('electricity_scheduleID') as number;

  const calculateHours = (parameters: [number, number, string, string]) =>
    new Promise((resolve: (hours: number) => void) =>
      emit<SQLFunctionPayload, Record<string, unknown>>(
        {
          type: 'RPC_REQUEST',
          method: 'execute',
          procedure: 'callFunctionSQL',
          parameters: {
            function: 'CALCULATE_HOURS_SA',
            parameters,
          },
        },
        response => {
          const data = response?.data;
          if (Array.isArray(data) && data.length > 0) {
            if (typeof response === 'object' && response !== null) {
              const hours = Object.values(data[0])[0];
              if (typeof hours === 'number') {
                return resolve(hours);
              }
            }
          }
          return resolve(0);
        },
      ),
    );

  const calculateDays = () => moment(endDate, 'YYYY-MM-DD').diff(moment(startDate, 'YYYY-MM-DD'), 'days') + 1;

  const refreshCalcs = () => {
    if (days) {
      const days = calculateDays();
      setValue('Duration', days);
      setValue('totalVolume', days * volume);
      setValue('Notional', withCommas(days * volume * price));
    }

    if (hours && typeof instrument === 'number' && typeof schedule === 'number') {
      calculateHours([schedule, instrument, startDate, endDate])
        .then(hours => {
          setValue('Hours', hours);
          setValue('totalVolume', hours * volume);
          setValue('Notional', withCommas(hours * volume * price));
        })
        .catch(e => console.error(e));
    }
  };

  React.useEffect(() => {
    refreshCalcs();
  }, []);

  React.useEffect(() => {
    refreshCalcs();
  }, [startDate, endDate, schedule, instrument, volume, price]);

  const [startMonth, setStartMonth] = React.useState(moment().add(1, 'month').format('YYYY-MM'));
  const [endMonth, setEndMonth] = React.useState(moment().add(1, 'month').format('YYYY-MM'));

  const handleChange = (which: 'start' | 'end') => (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    if (which === 'start') {
      setValue('StartDate', `${e.target.value}-01`);
      setStartMonth(e.target.value as string);
    } else {
      setValue(
        'EndDate',
        `${moment(e.target.value as string, 'YYYY-MM')
          .endOf('month')
          .format('YYYY-MM-DD')}`,
      );
      setEndMonth(e.target.value as string);
    }
  };

  return (
    <>
      <FormControlLabel labelPlacement={'top'} className={classes.formControlLabel} control={<Switch checked={customDates} color='primary' />} label='Custom dates' onChange={toggleCustomDates} />
      {customDates ? (
        <>
          <Controller
            name={'StartDate' as Path<T>}
            control={control}
            render={({ field }) => (
              <TextField
                id='StartDate'
                size={'small'}
                label='Start Date'
                type='date'
                variant={'outlined'}
                className={classes.formControl}
                InputLabelProps={{
                  shrink: true,
                }}
                {...field}
              />
            )}
          />
          <Controller
            name={'EndDate' as Path<T>}
            control={control}
            render={({ field }) => (
              <TextField
                id='EndDate'
                size={'small'}
                label='End Date'
                type='date'
                variant={'outlined'}
                className={classes.formControl}
                InputLabelProps={{
                  shrink: true,
                }}
                {...field}
              />
            )}
          />
        </>
      ) : (
        <>
          <TextField
            id='StartDate'
            size={'small'}
            label='Start Date'
            type='month'
            variant={'outlined'}
            className={classes.formControl}
            InputLabelProps={{
              shrink: true,
            }}
            value={startMonth}
            onChange={handleChange('start')}
          />
          <TextField
            id='EndDate'
            size={'small'}
            label='End Date'
            type='month'
            variant={'outlined'}
            className={classes.formControl}
            InputLabelProps={{
              shrink: true,
            }}
            value={endMonth}
            onChange={handleChange('end')}
          />
        </>
      )}
      {days && (
        <Controller
          name={'Duration' as Path<T>}
          control={control}
          render={({ field }) => (
            <TextField size={'small'} id={`duration`} label='Days' type={'number'} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true, type: 'numeric' }} variant='outlined' className={classes.formControl} {...field} />
          )}
        />
      )}
      {hours && (
        <Controller
          name={'Hours' as Path<T>}
          control={control}
          render={({ field }) => (
            <TextField size={'small'} id={`hours`} label='Hours' type={'number'} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true, type: 'numeric' }} variant='outlined' className={classes.formControl} {...field} />
          )}
        />
      )}
    </>
  );
};
