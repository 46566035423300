import React from 'react';

import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { IMsalContext } from '@azure/msal-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      gridGap: theme.spacing(3),
      padding: theme.spacing(3),
      paddingTop: '10%',
      background: 'rgb(36, 76, 90)',
      border: 0,
      borderRadius: 0,
      height: '-webkit-fill-available',
      textAlign: 'center',
      justifyContent: 'center',
    },
    item: {
      overflow: 'clip',
      height: '50%',
    },
    button: {
      '& svg': {
        margin: theme.spacing(1.5),
      },
      backgroundColor: 'rgb(233, 104, 82)',
      width: '9.6rem',
      height: '2.4rem',
      borderRadius: '6px',
      '&:hover': {
        transition: '0.5s ease',
        backgroundColor: lighten('rgb(233, 104, 82)', 0.4),
      },
    },
  }),
);

export function MsalLoading(props: IMsalContext) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', alignSelf: 'center' }}>
        <div className={classes.item} style={{ display: 'flex', alignSelf: 'center' }}>
          <img src={'/AE_Cloud3.png'} style={{ maxHeight: '100%' }} />
        </div>
        <div className={classes.item}>
          <Typography variant='h6' color={'textPrimary'} style={{ whiteSpace: 'pre-wrap' }}>
            Authorization in progress... Current state is {props.inProgress}
          </Typography>
        </div>
      </div>
    </div>
  );
}
