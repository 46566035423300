import { createReducer, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { sbActions } from 'redux/middleware/servicebus';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ServiceBusStore {
  clientIdentifier?: string;
  sessionsIds: string[];
}

const initialState: ServiceBusStore = {
  sessionsIds: [],
};

export const serviceBusReducer = createReducer(initialState, builder => {
  builder
    .addCase(sbActions.SB_STARTED, (state, action) => {
      state.clientIdentifier = action.payload;
      return;
    })
    .addCase(sbActions.SB_SESSION_STARTED, (state, action) => {
      state.sessionsIds.push(action.payload);
      return;
    });
});

export type ServiceBusActions = PayloadAction<typeof sbActions[keyof typeof sbActions]>;
export const serviceBus: Reducer<ServiceBusStore, ServiceBusActions> = serviceBusReducer;
