import { msalInstance } from 'index';
import { BrowserCredential } from './browserCredential';
import { ServiceBusClient, ServiceBusSessionReceiver, ServiceBusReceivedMessage } from '@azure/service-bus';

export const initialiseClient = async () => {
  const credential = new BrowserCredential(msalInstance, ['https://servicebus.azure.net//user_impersonation']);
  await credential.prepare();
  return new ServiceBusClient('aotearoa-market.servicebus.windows.net', credential);
};
const createSession = async (topic: string, subscription: string, client: ServiceBusClient) => client.acceptSession(topic, subscription);

const subscribeSession = (session: ServiceBusSessionReceiver, processMessage: (message: ServiceBusReceivedMessage) => Promise<void>) => {
  return session.subscribe({
    processMessage,
    processError: async err => {
      console.error('Error: ', JSON.stringify(err));
      return Promise.resolve();
    },
  });
};

export const initBusTopic = async (client: ServiceBusClient, topic: string, subscription: string, processMessage: (message: ServiceBusReceivedMessage) => Promise<void>) => {
  const session = await createSession(topic, subscription, client);
  const subscribe = subscribeSession(session, processMessage);
  const sender = client.createSender(topic);

  return {
    session,
    subscribe,
    sender,
  };
};
