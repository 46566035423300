import { makeStyles, Theme, createStyles } from '@material-ui/core';
import React from 'react';
import { PropsWithChildren } from 'react';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      height: '100%',
      transition: 'height 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      overflow: 'hidden',
      placeItems: 'center',
    },
  }),
);
export const TicketRow = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const classes = useStyles();

  return <div className={classes.row}>{children}</div>;
};
