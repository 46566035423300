import React from 'react';
import { createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { useAppDispatch } from 'redux/hooks';
import { EODGrid } from 'pages/ae/alpha/eod/AlphaEOD';
import './new.css';
import { StyledFlexContainer } from 'components/styled';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      height: '100%',
      borderRadius: 16,

      placeContent: 'center',
    },
    subItem: {
      height: '100%',
      display: 'unset',
      overflow: 'hidden',
    },
    headerPaper: {
      height: '2.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contentPaper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    title: {
      fontWeight: 'bolder',
    },
  }),
);

export function AlphaEOD(): JSX.Element {
  const classes = useStyles();

  return (
    <StyledFlexContainer>
      <Paper className={classes.headerPaper}>
        <Typography variant={'h6'} color={'primary'} className={classes.title}>
          ASX FUTURES EOD SNAPSHOT
        </Typography>
      </Paper>
      <Paper className={classes.contentPaper} elevation={2}>
        <EODGrid />
      </Paper>
    </StyledFlexContainer>
  );
}
