import { Modal, Backdrop, createStyles, makeStyles, Theme, Grid, colors, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { PaperItem } from 'components/styled/Grid';
import { EpicModalProps } from './ModalProvider';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { UberIconButton } from 'components/UberIconButton';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      width: '80vw',
      height: '80vh',
      maxHeight: '95vh',
      maxWidth: '95vw',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #000',
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    headerBar: {
      width: '-webkit-fill-available',
      borderWidth: `0px 0px 1px 0px`,
      borderStyle: `solid`,
      borderColor: theme.palette.divider,
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
      backgroundColor: colors.indigo[200],
      color: theme.palette.getContrastText(colors.indigo[200]),
      boxShadow: theme.shadows[1],
    },
  }),
);

export type EpicModalContainerProps = EpicModalProps & {
  closeModal: () => void;
  open: boolean;
  title?: string;
};

const ModalContainer = ({ closeModal, title, open, children, paperItemProps, ...modalProps }: EpicModalContainerProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      open={open}
      {...modalProps}
    >
      {/* Need the div as PaperItem doesn't accept a ref */}
      <div>
        <PaperItem {...paperItemProps} className={clsx(classes.paper, paperItemProps?.className)}>
          <Grid container alignItems='center' className={classes.headerBar}>
            <Typography component={'div'} variant={'h6'} style={{ marginLeft: 12, marginRight: 'auto', fontWeight: 'bold' }}>
              {title}
            </Typography>
            <Divider orientation='vertical' flexItem />
            <UberIconButton onClick={closeModal} tooltip={'Close window'} Icon={CloseIcon} />
          </Grid>
          {children}
        </PaperItem>
      </div>
    </Modal>
  );
};

export { ModalContainer };
