import React from 'react';
import { useLocation } from 'react-router-dom';
import { TicketLocationState } from 'types/Ticket.types';
import { ElectricityTicket, GasTicket, CarbonTicket, ElectricityOTCTicket, FTRTicket } from './product';
import { Product } from './ticket.types';

function Ticket(): JSX.Element {
  const { state } = useLocation<TicketLocationState>();
  switch (state?.dealpad) {
    case 'electricity_futures':
      return <ElectricityTicket />;
    case 'electricity_otc':
      return <ElectricityOTCTicket />;

    case 'electricity_ftr':
      return <FTRTicket />;
    case 'gas':
      return <GasTicket />;
    case 'carbon':
      return <CarbonTicket product={Product.CARBON} />;
    default:
      return <GasTicket />;
  }
}

export { Ticket };
