import moment from 'moment';
import { Moment } from 'moment';
import { MatchContractDefinition, FuturesDurationType, DefinitionGet, FuturesNode, MatchDefinitionsByDuration } from 'types/match.types';

// get all unique expiry from matchDefinitions
export const getUniqueExpiries = (data: MatchContractDefinition<string>[]): Moment[] => {
  return data.reduce<Moment[]>((prev, curr) => {
    if (prev.map(expiry => expiry.unix()).includes(moment(curr.expiry).unix())) return prev;
    return [...prev, moment(curr.expiry)];
  }, []);
};
export const getUniqueNames = (data: MatchContractDefinition<string>[]): string[] => {
  return data.reduce<string[]>((prev, curr) => {
    if (typeof curr.displayName !== 'string' || prev.includes(curr.displayName)) return prev;
    return [...prev, curr.displayName];
  }, []);
};
export const getContractsByNode: DefinitionGet<FuturesNode> = (node, data) => {
  return data.filter(contract => contract.node === node.toUpperCase());
};

export const getContractByDuration: DefinitionGet<FuturesDurationType> = (duration, data) => {
  return data.filter(def => def.specification.duration === duration);
};

export const getContractsByExpiry: DefinitionGet<string> = (expiry, data) => {
  return data.filter(def => moment(def.expiry).diff(expiry) === 0);
};

export const makeDurationObject: (node: FuturesNode, data: MatchContractDefinition<string>[]) => MatchDefinitionsByDuration = (node, data) => {
  const contracts = getContractsByNode(node, data);
  return Object.entries(
    contracts.reduce<MatchDefinitionsByDuration>(
      (prev, curr) => {
        if (!prev[curr.specification.duration]) prev = { ...prev, [curr.specification.duration]: [] };

        prev[curr.specification.duration].push(curr);
        return prev;
      },
      { month: [], quarter: [], year: [] },
    ),
  ).reduce<MatchDefinitionsByDuration>((prev, curr) => ({ ...prev, [curr[0]]: curr[1].sort((a, b) => moment(a.expiry).diff(moment(b.expiry))) }), { month: [], quarter: [], year: [] });
};
