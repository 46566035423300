import { makeStyles, FormControl } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { Moment } from 'moment';

import React from 'react';

const styles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: 200,
    textAlign: 'left',
  },
}));

export const StackDatePicker = ({ label, initial, onChange }: { label: string; initial: moment.Moment; onChange: (date: moment.Moment) => void }) => {
  const classes = styles();
  const [date, setDate] = React.useState<moment.Moment>(moment(initial));

  const handleChange = (date: Moment | null) => {
    if (date) {
      setDate(date);
      onChange(date);
    }
  };

  return (
    <FormControl variant='outlined' className={classes.formControl}>
      <KeyboardDatePicker
        autoOk
        disableFuture
        maxDate={moment().subtract('1', 'day')}
        format='DD-MM-YYYY'
        variant='inline'
        inputVariant='outlined'
        id='date-picker-second'
        label={label}
        value={date}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={handleChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </FormControl>
  );
};
