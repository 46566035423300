import React, { DetailedHTMLProps } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { AccordionProvider } from './AccordionContext';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
  }),
);

type AccordionRootProps = DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

function AccordionRoot({ children, ...props }: AccordionRootProps): JSX.Element {
  const classes = useStyles();

  return (
    <AccordionProvider>
      <div className={classes.root} {...props}>
        {children}
      </div>
    </AccordionProvider>
  );
}

export { AccordionRoot };
