import { AsyncThunk, createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import { wsAsyncActions } from 'redux/middleware/socket/actions';
import { API } from 'types/pantheon/PantheonSocket';
import { RootState, SocketDispatch } from 'redux/store';
import { SQLElectricityNode, SQLFuturesContract, SQLFuturesMonth, SQLFuturesPriceType, SQLFuturesSpecification, SQLFuturesClient } from 'types/sql/broker.tables.types';

// Define a type for the slice state
export interface FuturesStore {
  ready: boolean;
  tables: {
    electricityNode: SQLElectricityNode[];
    futuresClient: SQLFuturesClient[];
    futuresContract: SQLFuturesContract[];
    futuresMonth: SQLFuturesMonth[];
    futuresPriceType: SQLFuturesPriceType[];
    futuresSpecification: SQLFuturesSpecification[];
  };
}

// Define the initial state using that type
const initialState: FuturesStore = { ready: false, tables: { electricityNode: [], futuresClient: [], futuresContract: [], futuresMonth: [], futuresPriceType: [], futuresSpecification: [] } };

type ThunkAPIConfig = {
  state: RootState;
  dispatch: SocketDispatch;
};

// First, create the thunk
export const fetchFuturesTable = createAsyncThunk<API.Response<unknown>, string, ThunkAPIConfig>('futures/fetchTable', async (table, thunkAPI) => {
  // const parsedData = await Promise.all(
  const request: API.Request = {
    type: 'API_REQUEST',
    method: 'get',
    schema: 'broker',
    table,
  };

  return thunkAPI.dispatch<API.Response<unknown>>(wsAsyncActions.WS_API(request));
});

// First, create the thunk
export const fetchJSON = <T,>(): AsyncThunk<T, string, ThunkAPIConfig> =>
  createAsyncThunk<T, string, ThunkAPIConfig>('futures/fetchJSON', async (table, thunkAPI) => {
    // const parsedData = await Promise.all(
    const request: API.Request = {
      type: 'API_REQUEST',
      method: 'get',
      schema: 'broker',
      table,
    };

    return thunkAPI.dispatch<API.Response<[{ jsonData: T }]>>(wsAsyncActions.WS_API(request)).then(response => response.message[0].jsonData);
  });

export const futuresSlice = createSlice({
  name: 'futures',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setReady: state => {
      state.ready = true;
    },
  },
  // extraReducers: builder => {
  // Add reducers for additional action types here, and handle loading state as needed
  // builder.addCase(fetchFuturesTable.fulfilled, (state, action) => {
  //   console.log(action);
  //   // Add user to the state array
  // });
  // },
});

export type FuturesActions = ReturnType<typeof futuresSlice.actions[keyof typeof futuresSlice.actions]>;

export const { actions } = futuresSlice;
export const futures: Reducer<FuturesStore, FuturesActions> = futuresSlice.reducer;
