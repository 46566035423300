import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Page, Section } from 'components/Wrapper';
import { MatchGrid } from '../match/MatchGrid';
import { useSocketInstance } from 'modules/websocket/context';
import { MatchPacketActions, MatchPacketTypes } from 'types/match.types';
import { MatchSubmitter } from '../match/MatchSubmitter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {},
    inputRow: {
      display: 'flex',
      flexDirection: 'row',
      padding: 4,
      gap: '8px',
    },
    inputColumn: {
      display: 'flex',
      placeContent: 'space-evenly',
      width: 200,
      flexDirection: 'column',
      padding: 4,
      gap: '8px',
    },
    formControl: {
      margin: theme.spacing(1),
      textAlign: 'left',
    },
  }),
);

export function AlphaMonitor(): JSX.Element {
  const classes = useStyles();
  const socket = useSocketInstance();

  const debugPrices = () => {
    socket.socket.emit('match.request', { action: MatchPacketActions.GET, type: MatchPacketTypes.PRICES, payload: { futuresContractID: 20 } });
  };
  return (
    <Page title={'AE ALPHA MONITOR '} style={{ gridTemplateColumns: '100%' }} rows={'5rem 1fr'} columnCount={1}>
      <div style={{ display: 'grid', gap: '16px', gridTemplateColumns: '2fr 1fr' }}>
        <Section childClassName={classes.section}>
          <MatchGrid />
        </Section>
        <Section childClassName={classes.section}>
          <MatchSubmitter />
        </Section>
      </div>
    </Page>
  );
}
