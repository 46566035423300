import React from 'react';

import { Header } from 'components/Header';
import { ElectricityOTCTicketSide } from '../ticketSides';
import { ElectricityOTCTicketDetails } from '../ticketDetails';
import { TicketFooter } from '../TicketFooter';
import { useLocation } from 'react-router-dom';
import { useSocketEmit } from 'modules/websocket/hooks';

import { IElectricityOTCTicket, IElectricityOTCTicketSide, TicketLocationState } from 'types/Ticket.types';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import moment from 'moment';
import { StyledContainer } from 'components/styled';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { UberIconButton } from 'components/UberIconButton';
import { createStyles } from '@material-ui/styles';
import { darken, makeStyles, Theme } from '@material-ui/core';
import { API } from 'types/pantheon/PantheonSocket';
import { Product } from 'pages/ae/broking/ticket/ticket.types';
import { useAccount } from '@azure/msal-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    swapButton: {
      '& svg': {
        margin: theme.spacing(1.5),
      },

      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      width: '2.4rem',
      height: '2.4rem',
      borderRadius: '4px',
      '&:hover': {
        transition: '0.5s ease',
        color: darken(theme.palette.primary.main, 0.25),
        backgroundColor: '#dadada',
      },
    },
  }),
);

function ElectricityOTCTicket(): JSX.Element {
  const classes = useStyles();

  const account = useAccount();

  const { state } = useLocation<TicketLocationState>();

  const [emit] = useSocketEmit('pantheon.api');

  const promptMonth = {
    start: moment().add(1, 'month').startOf('month'),
    end: moment().add(1, 'month').endOf('month'),
  };

  const methods = useForm<IElectricityOTCTicket>({
    defaultValues: {
      ID: '' as unknown as number,
      TradeTime: moment().format('YYYY-MM-DDTHH:mm'),
      StartDate: promptMonth.start.format('YYYY-MM-DD'),
      EndDate: promptMonth.end.format('YYYY-MM-DD'),
      Duration: promptMonth.end.diff(promptMonth.start, 'days') + 1,
      totalVolume: 0,
      ProductID: 4,
      VenueID: 4,
      Volume: '' as unknown as number,
      Price: '' as unknown as number,
      hasBuyer: true,
      hasSeller: true,
      isOption: false,
      OptionExerciseTypeID: '' as unknown as number,
      OptionTypeID: '' as unknown as number,
      OptionStrike: '',
      OptionIV: '',
      OptionUnderlyingPrice: '',
      ContractTypeID: 1,
      TradeUnitID: 6,
      reference_instrumentID: '' as unknown as number,
      electricity_scheduleID: '' as unknown as number,
      seller: {
        ID: '' as unknown as number,
        EntityID: '' as unknown as number,
        CounterpartyID: '' as unknown as number,
        TraderID: '' as unknown as number,
        brokerage_rate: '' as unknown as number,
        brokerage: 0,
        broTypeID: 12,
        SubmitterID: (account?.idTokenClaims?.['extn.aeID'] as number[])[0] || ('' as unknown as number),
        BrokerID: (account?.idTokenClaims?.['extn.aeID'] as number[])[0] || ('' as unknown as number),
      },
      buyer: {
        ID: '' as unknown as number,
        EntityID: '' as unknown as number,
        CounterpartyID: '' as unknown as number,
        TraderID: '' as unknown as number,
        brokerage_rate: '' as unknown as number,
        brokerage: 0,
        broTypeID: 12,
        SubmitterID: (account?.idTokenClaims?.['extn.aeID'] as number[])[0] || ('' as unknown as number),
        BrokerID: (account?.idTokenClaims?.['extn.aeID'] as number[])[0] || ('' as unknown as number),
      },
    },
  });

  const { control, handleSubmit, getValues, setValue } = methods;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit: SubmitHandler<IElectricityOTCTicket> = data => {
    // console.log(data);
  };

  React.useEffect(() => {
    if (state?.ticketId) {
      const { ticketId } = state;
      emit<API.Request, API.GetResponse<{ ID: number; Ticket: IElectricityOTCTicket }>>(
        {
          type: 'API_REQUEST',
          method: 'get',
          schema: 'ae',
          table: 'portal.ticket.electricity_otc',
          id: ticketId,
        },
        (response?: API.GetResponse<{ ID: number; Ticket: IElectricityOTCTicket }>) => {
          const ticketData = response && response.message && response.message[0]?.Ticket;
          if (!ticketData) return;
          // TODO bit gross...
          const { seller, buyer, ...ticket } = ticketData;
          for (const key in seller) setValue(`seller.${key as keyof IElectricityOTCTicketSide}`, seller[key as keyof IElectricityOTCTicketSide] || ('' as unknown as number));
          for (const key in buyer) setValue(`buyer.${key as keyof IElectricityOTCTicketSide}`, buyer[key as keyof IElectricityOTCTicketSide] || ('' as unknown as number));

          // TODO worst bit...
          for (const key in ticket) {
            if (key === 'TradeTime') setValue('TradeTime', moment(ticket.TradeTime).format('YYYY-MM-DDTHH:mm'));
            else if (key.substring(0, 2) === 'is' || key.substring(0, 3) === 'has') {
              setValue(`${key as keyof IElectricityOTCTicket}`, Boolean(ticket[key as keyof Omit<IElectricityOTCTicket, 'buyer' | 'seller'>]));
            } else {
              setValue(`${key as keyof IElectricityOTCTicket}`, ticket[key as keyof Omit<IElectricityOTCTicket, 'buyer' | 'seller'>] || ('' as unknown as number));
            }
          }

          seller && setValue('seller.brokerage', seller['brokerage']);
          buyer && setValue('buyer.brokerage', buyer['brokerage']);
        },
      );
    }
  }, [state]);

  const swapSides = () => {
    const { seller, buyer, hasSeller, hasBuyer } = { ...getValues() };

    if (hasSeller) {
      setValue('hasBuyer', true);
      setValue('buyer', seller);
    } else {
      setValue('hasBuyer', false);
      setValue('buyer', null);
    }

    if (hasBuyer) {
      setValue('hasSeller', true);
      setValue('seller', buyer);
    } else {
      setValue('hasSeller', false);
      setValue('buyer', null);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={() => void handleSubmit(onSubmit)}>
        <StyledContainer columns={'1fr 1fr min-content 1fr 1fr'} rows={'2.5rem 1fr 1fr 5rem'}>
          <Header text={`Electricity OTC Deal Ticket ${state?.ticketId || ''}`} max columnCount={5} />

          <ElectricityOTCTicketDetails control={control} />
          <ElectricityOTCTicketSide side={'seller'} control={control} setValue={setValue} productID={Product.ELECTRICITY} />
          <div style={{ alignSelf: 'center' }}>
            <UberIconButton autoMargin={'left'} classOverride={classes.swapButton} onClick={swapSides} tooltip={'Swap sides'} Icon={SwapHorizIcon} />
          </div>
          <ElectricityOTCTicketSide side={'buyer'} control={control} setValue={setValue} productID={Product.ELECTRICITY} />
          <TicketFooter getValues={getValues} />
        </StyledContainer>
      </form>
    </FormProvider>
  );
}

export { ElectricityOTCTicket };
