import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { ContractStatePayload, MatchContractDefinition, MatchContractState } from 'types/match.types';

// Define a type for the slice state
export interface MatchStore {
  status: { ready: boolean; connected: boolean };
  contractState: Record<number, MatchContractState>;
  contractDefinitions: MatchContractDefinition<string>[];
  contractGroups: string[];
}

// Define the initial state using that type
const initialState: MatchStore = { status: { ready: true, connected: false }, contractState: {}, contractDefinitions: [], contractGroups: [] };

export const matchSlice = createSlice({
  name: 'matchPricingSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setReady: state => void (state.status.ready = true),
    connect: state => void (state.status.connected = true),
    disconnect: state => void (state.status.connected = false),

    updateDefinitions: (state, action: PayloadAction<MatchContractDefinition<string>[]>) => {
      state.contractDefinitions = action.payload;
    },
    updateGroups: (state, action: PayloadAction<string[]>) => {
      state.contractGroups = action.payload;
    },
    updateState: (state, action: PayloadAction<ContractStatePayload>) => {
      state.contractState = { ...state.contractState, [action.payload.contractId]: action.payload.state };
    },
  },
});

export type MatchActions = PayloadAction<typeof matchSlice.actions[keyof typeof matchSlice.actions]>;
export const { actions } = matchSlice;
export const match: Reducer<MatchStore, MatchActions> = matchSlice.reducer;
