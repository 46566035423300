import React from 'react';
import { makeStyles, Slide } from '@material-ui/core';

import * as Pages from 'pages';
import { MiniDrawer } from './components/Drawer/Drawer';
import { useNotifier } from './redux/useNotifier';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Route, Switch } from 'react-router';
import { Login } from 'router/Login';
import { RouteGuard } from 'router/RouteGuard';
import { MessagePage } from './router/MessagePage';
import { BrokingRoutes } from 'router/BrokingRoutes';
import { EventMessage, EventType } from '@azure/msal-browser';
import { useSocketDispatch } from './redux/hooks';
import { wsActions } from 'redux/middleware/socket';
import { loginRequest } from 'modules/msal/authConfig';

const useStyles = makeStyles(() => ({
  main: { height: 'calc(100vh - 64px)', position: 'relative', display: 'flex' },
  content: { height: '100%' },
}));

export default function Content(): JSX.Element {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const dispatch = useSocketDispatch();

  React.useEffect(() => {
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      process.env.NODE_ENV === 'development' && console.log(event);
      if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS || event.eventType === EventType.LOGIN_SUCCESS) {
        dispatch(wsActions.WS_CONNECT());
      }

      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE || event.eventType === EventType.SSO_SILENT_FAILURE || event.eventType === EventType.LOGIN_FAILURE) {
        void instance.acquireTokenRedirect(loginRequest);
      }
    });

    return () => {
      callbackId && instance.removeEventCallback(callbackId);
    };
  }, []);

  const classes = useStyles();
  useNotifier();

  return (
    <div className={classes.main}>
      <Slide direction='right' in={isAuthenticated} mountOnEnter unmountOnExit timeout={{ appear: 1000, enter: 1000, exit: 1000 }}>
        <MiniDrawer />
      </Slide>
      <div className={classes.content} style={{ overflow: `${isAuthenticated ? 'auto' : 'hidden'}`, width: `calc(100vw - ${isAuthenticated ? '57' : '0'}px)` }}>
        <Switch>
          <Route path='/login' exact component={Login} />
          <RouteGuard path='/ae/broking' exact={false} component={BrokingRoutes} />

          <RouteGuard path='/dashboard/ae/revenue' exact={true} component={Pages.ae.dashboard.Revenue} />

          <RouteGuard path='/ae/match' exact={true} component={Pages.ae.alpha.AlphaMatch} />
          <RouteGuard path='/ae/alpha/match' exact={true} component={Pages.ae.alpha.AlphaMatch} />
          <RouteGuard path='/ae/alpha/eod' exact={true} component={Pages.ae.alpha.AlphaEOD} />
          <RouteGuard path='/ae/alpha/monitor' exact={true} component={Pages.ae.alpha.AlphaMonitor} />

          <RouteGuard path='/modules/electricity/stacks' exact={true} component={Pages.modules.electricity.stacks.OfferStack} />
          <RouteGuard path='/modules/electricity/ftrcalc' exact={true} component={Pages.modules.electricity.ftrcalc.Calculator} />
          <RouteGuard path='/modules/electricity/dash' exact={true} component={Pages.modules.electricity.dashboard.Home} />
          <RouteGuard path='/modules/electricity/blend' exact={true} component={Pages.modules.electricity.spot.Home} />

          <RouteGuard path='/mercuria/electricity/stacks' exact={true} component={Pages.modules.electricity.stacks.OfferStack} />
          <RouteGuard path='/mercuria/electricity/ftrcalc' exact={true} component={Pages.modules.electricity.ftrcalc.Calculator} />
          <RouteGuard path='/mercuria/electricity/dash' exact={true} component={Pages.modules.electricity.dashboard.Home} />

          <RouteGuard path='/modules/alerts/gas' exact={true} component={Pages.modules.alerts.Gas} />

          <RouteGuard path='/datacore' exact={true} component={Pages.modules.datacore.Home} />
          <RouteGuard path='/datacore/extractor' exact={true} component={Pages.modules.datacore.Extractor} />

          <RouteGuard path='/' exact={true} component={Pages.Home} />
          <Route component={() => <MessagePage message={`Uh oh ... That page doesnt exist!`} showCloud />} />
        </Switch>
      </div>
    </div>
  );
}
