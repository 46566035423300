import { Input } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useStyles } from './useStyles';
import { brokingActions } from 'redux/slice';
import { isPending, makeIsPendingSelector } from 'redux/slice/broking/selectors';

export const BidOfferVolume = ({ contractCode, bid, ask }: { contractCode: string } & ({ bid: true; ask?: never } | { ask: true; bid?: never })) => {
  const side = bid ? 'bid' : 'ask';
  const memoSelector = React.useMemo(makeIsPendingSelector, []);
  const { matchVolumeStep } = useAppSelector(state => state.broking);
  const pending = useAppSelector(state => memoSelector(state, contractCode, side));
  console.log(pending);
  const dispatch = useAppDispatch();

  const [volume, setVolume] = React.useState<string>('');
  const [pricePending, setPricePending] = React.useState<boolean>(false);

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = Number.parseFloat(event.target.value);

    console.log(`${contractCode} ${side} changed`);
    setVolume(newVolume <= 0 ? '' : event.target.value);
    // const isPending = (matchVolume[contractCode]?.[side] || 0) !== Math.max(newVolume, 0);
    dispatch(brokingActions.setMatchVolume({ contract: contractCode, side, volume: newVolume }));
    // setPricePending(isPending);
  };
  return (
    <td className={clsx(`${side}Volume`, classes.inputBox, { ['bgBlink']: pricePending })}>
      <input type='hidden' value={contractCode} readOnly />
      <Input classes={{ input: classes.input }} inputProps={{ step: matchVolumeStep }} className={clsx(classes.inputBox, { ['blink']: pricePending })} type={'number'} value={volume} onChange={handleChange} />
    </td>
  );
};
