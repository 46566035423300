import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() =>
  createStyles({
    inputCell: {
      border: '1px solid black',
      padding: '0px !important',
    },
    input: {
      width: '65%',
      padding: 0,
      color: 'white',
      fontSize: '12px',
      fontWeight: 900,
      textAlign: 'center',
      paddingLeft: '30%',
    },
    section: {
      color: 'white',
    },
    wideSpacer: {
      width: '5rem !important',
      backgroundColor: 'white !important',
      borderTop: '0px  !important',
      borderBottom: '0px  !important',
    },
    wideSettle: {
      width: '5rem !important',
    },
    inputBox: {
      '&.MuiInput-underline:before': {
        content: 'none',
      },
      '&::before': {
        borderBottom: '0px',
      },
      '&::after': {
        borderBottom: '0px',
      },
    },
    stepPreset: {
      padding: '4px 12px',
      minWidth: '0px',
    },
    matchButton: {
      height: 'min-content',
      alignSelf: 'center',
    },
    clock: {
      fontSize: 20,
      fontWeight: 600,
      fontFamily: 'ui-monospace',
    },
  }),
);
